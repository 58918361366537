<template>
  <div>
    <!-- Quiz Modal -->
    <b-modal
      :no-close-on-backdrop="true"
      id="modal-1"
      ref="quiz-modal-ref"
      hide-footer
      @hidden="resetQuizModal"
      size="lg"
    >
      <div v-if="question">
        <!-- Quiz Question -->
        <p class="mb-1 font-weight-bold">
          {{ questionIndex + 1 }}. {{ question.ftq_text }}
        </p>
        <div>
          <!-- Quiz Answers -->
          <div v-for="answer in question.answers" :key="answer.fta_id">
            <label class="rad-label">
              <input
                type="radio"
                class="rad-input"
                :value="answer.fta_id"
                v-model="selectedAnswer"
              />
              <div class="rad-design"></div>

              <div class="red-text ps-2" style="padding-left: 16px">
                {{ answer.fta_text }}
              </div>
            </label>
          </div>
        </div>
        <div class="mt-2">
          <button
            @click="nextQuestion"
            v-if="questionIndex !== currentQuestions.length - 1"
            class="btn btn-primary"
          >
            Next
          </button>
          <button v-else @click="finishQuiz" class="btn btn-success">
            Submit
          </button>
        </div>
      </div>
    </b-modal>

    <!-- Quiz Response Modal -->
    <b-modal
      :no-close-on-backdrop="true"
      id="modal-2"
      ref="response-modal-ref"
      hide-footer
      size="lg"
      title="Quiz Results"
    >
      <div>
        <div class="w-100 my-2 mb-2">
          <v-select
            class="w-100"
            v-model="currentQuizAttempt"
            :options="getAttemptOptions()"
            @input="getCurrentResponse"
            label="label"
            :clearable="false"
          >
            <template #option="data">
              <div class="d-flex justify-content-between" style="width: 700px">
                <p>Quiz Attempt {{ data.label }}</p>
                <p>
                  {{
                    moment(
                      currentQuizResponses[data.label - 1][0].created_at
                    ).format("DD MMM, YYYY")
                  }}
                </p>
              </div>
            </template>
            <template #selected-option="data">
              <div class="d-flex justify-content-between" style="width: 700px">
                <p>Quiz Attempt {{ data.label }}</p>
                <b-badge variant="warning">
                  {{
                    moment(
                      currentQuizResponses[data.label - 1][0].created_at
                    ).format("DD MMM, YYYY")
                  }}
                </b-badge>
              </div>
            </template>
          </v-select>
        </div>
        <div class="my-2">
          <h3>
            Score:
            <b-badge variant="primary"
              >{{ getCurrentResponsesScore().percentage }}%</b-badge
            >
          </h3>
          <h5>
            You answered
            {{ getCurrentResponsesScore().correct_answers_count }} out of
            {{ getCurrentResponsesScore().total_count }} questions correctly.
          </h5>
        </div>
        <div>
          <b-table hover :items="currentResponses">
            <template #cell(result)="data">
              <div style="width: max-content">
                <b-badge
                  class="rounded-pill"
                  :variant="
                    data.item.result === 'Correct'
                      ? 'light-success'
                      : 'light-danger'
                  "
                  >{{ data.item.result }}</b-badge
                >
              </div>
            </template>
          </b-table>
        </div>
        <div class="mt-5 d-flex justify-content-center">
          <button @click="closeResponseModal()" class="btn btn-success">
            Close
          </button>
        </div>
      </div>
    </b-modal>

    <!-- Collapsible Training Content -->
    <div class="container-fluid">
      <b-card>
        <b-form-input
          placeholder="Search..."
          v-model="search"
          @input="onSearch"
        />
      </b-card>
      <div class="p-2 card border shadow-none">
        <!-- Training Title and Description -->
        <div class="p-2 mb-5" style="background-color: #f7f4ff">
          <div class="my-2">
            <h1 class="my-2 text-black">{{ training.ft_name }}</h1>
            <div class="my-2">
              <p style="font-size: 18px">{{ training.ft_text }}</p>
            </div>
          </div>
        </div>
        <!-- Training Videos -->
        <div v-if="allVideos && allVideos.length">
          <div class="video-grid">
            <h3
              :class="
                video.ftv_file &&
                'cursor-pointer d-flex align-items-center justify-content-between'
              "
              v-for="(video, t_index) in allVideos"
              :key="'video_index_' + t_index"
            >
              {{ video.ftv_name }}
              <span @click="handleDownload(video.ftv_file)" variant="danger">
                <feather-icon icon="ExternalLinkIcon" size="24" />
              </span>
            </h3>
          </div>
          <div class="video-grid-videos">
            <div
              v-for="(video, t_index) in allVideos"
              :key="'video_index_' + t_index"
              class="text-center"
            >
              <section class="video-container my-2" v-html="video.ftv_link" />

              <div
                class="
                  d-flex
                  justify-content-center
                  text-center
                  align-items-center
                  flex-wrap
                "
              >
                <!-- <button
                    v-if="video.ftv_file"
                    class="btn btn-outline-primary"
                    @click="handleDownload(video.ftv_file)"
                  >
                    Download File
                  </button> -->

                <!-- Quiz Button -->

                <button
                  v-if="
                    quizExists(video.ftv_id) &&
                    (user_type === 'franchise' || user_type == 'agent')
                  "
                  class="btn btn-primary ml-1"
                  @click="showQuizModal(video.ftv_id)"
                >
                  Take the Quiz
                </button>

                <button
                  v-if="responseForQuizExists(video.ftv_id)"
                  class="btn btn-success ml-1"
                  @click="showResponseModal(video.ftv_id)"
                >
                  Show Quiz Result
                </button>

                <!-- <button
                  :class="t_index === 0 ? 'mt-1':'mt-0'"
                    class="btn btn-danger ml-1"
                    @click="handleDownload(video.ftv_file)"
                  >
                    PDF
                  </button> -->
              </div>

              <hr class="w-full mt-3" />
            </div>
          </div>
        </div>
        <div v-else-if="presentations && presentations.length">
          <div class="video-grid">
            <h3
              :class="
                video.ftp_url &&
                'cursor-pointer d-flex align-items-center justify-content-between'
              "
              v-for="(video, t_index) in presentations"
              :key="'video_index_' + t_index"
            >
              {{ video.ftp_name }}

              <div @click="goToWebsite(video.ftp_id)">
                <feather-icon icon="ExternalLinkIcon" size="24" />
              </div>
            </h3>
          </div>
          <div
            class="video-grid-videos"
            width="100%"
            style="max-width: 100%; height: fit-content"
          >
            <div
              v-for="(pres, t_index) in presentations"
              :key="'pres' + t_index"
              class="text-center"
            >
              <embed
                :src="pres.ftp_url"
                width="100%"
                height="100%"
                style="max-width: 100%; aspect-ratio: 4/3"
              />

              <hr class="w-full mt-3" />
            </div>
          </div>
        </div>
        <div v-else-if="!presentations.length">
          <h3>No Presentations Available</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BBadge,
  BCardTitle,
  BCardText,
  BAvatar,
  BButton,
  BTable,
  BInputGroup,
  BFormInput,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { FILESURL } from "@/config";
import CommonServices from "@/apiServices/CommonServices";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import AgentServices from "@/apiServices/AgentServices";
import vSelect from "vue-select";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import store from "@/store";

export default {
  components: {
    BCard,
    BBadge,
    BButton,
    BAvatar,
    BCardTitle,
    BCardText,
    BTable,
    vSelect,
    BInputGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      search: "",
      ft_id: "",
      training: {},
      FILESURL,
      quizVideoId: null,
      selectedAnswer: null,
      trainingIndex: null,

      questionIndex: null,

      currentQuestions: [],
      currentResponses: [],
      currentResponsesWithDate: [],

      allQuizResponses: [],
      currentQuizResponses: [],
      currentQuizAttempt: null,
      attempt_id: null,
      allVideos: [],
      presentations: [],
    };
  },
  computed: {
    question() {
      if (this.quizVideoId != null && this.questionIndex !== null) {
        return this.currentQuestions.filter(
          (question) => question.ftv_id == this.quizVideoId
        )[this.questionIndex];
      }
      return null;
    },
  },
  methods: {
    onSearch() {
      if (this.search.trim() === "") {
        return this.allVideos;
      }
      const query = this.search.toLowerCase();
      this.allVideos = this.training.videos.filter((video) =>
        video.ftv_name.toLowerCase().includes(query)
      );
    },
    handleDownload(url) {
      // const website_url = `https://aliff.in/presentation-doc/${url}`
      window.open(url, "_blank");
    },
    goToWebsite(url) {
      const website_url = `http://aliff.in/presentation-doc/${url}`;
      window.open(website_url, "_blank");
    },
    quizExists(video_id) {
      const questions = this.training.questions.filter(
        (question) => question.ftv_id === video_id
      );

      if (questions.length <= 0) return false;
      return true;
    },
    moment,
    responseForQuizExists(video_id) {
      const responses = this.allQuizResponses.filter(
        (response) => response[0].ftv_id === video_id
      );

      if (responses.length <= 0) return false;
      return true;
    },

    getQuizResponseForVideo(video_id) {
      const responses = this.allQuizResponses.filter(
        (response) => response[0].ftv_id === video_id
      );

      this.currentQuizAttempt = responses.length;
      this.currentQuizResponses = responses;
      this.getCurrentResponse();
    },

    getCurrentResponse() {
      this.currentResponses = this.currentQuizResponses[
        this.currentQuizAttempt - 1
      ].map((response) => ({
        question: response.ftq_text,
        selected_answer: response.fta_text,
        result: response.is_correct === "Y" ? "Correct" : "Incorrect",
      }));

      this.currentResponsesWithDate = this.currentQuizResponses[
        this.currentQuizAttempt - 1
      ].map((response) => ({
        question: response.ftq_text,
        selected_answer: response.fta_text,
        result: response.is_correct === "Y" ? "Correct" : "Incorrect",
        date: response.created_at,
      }));
    },

    getCurrentResponsesScore() {
      let total_count = this.currentResponses.length;
      let correct_answers_count = this.currentResponses.filter(
        (response) => response.result === "Correct"
      ).length;
      let percentage = (correct_answers_count / total_count) * 100;
      percentage = percentage.toFixed(2);

      return {
        total_count,
        correct_answers_count,
        percentage,
      };
    },

    async getQuizResponses() {
      try {
        const res = await AgentServices.getQuizResponses();
        if (res.data.status) {
          this.allQuizResponses = res.data.data;

          let groupedByAttemptId = [];
          let prevAttemptId = null;

          for (const obj of this.allQuizResponses) {
            const attemptId = obj.attempt_id;
            if (!attemptId) continue;

            if (attemptId !== prevAttemptId) {
              // If attemptId is different from the previous one, start a new array
              groupedByAttemptId.push([]);
              prevAttemptId = attemptId;
            }

            // Push the object to the last array (the one just created or the last one modified)
            groupedByAttemptId[groupedByAttemptId.length - 1].push(obj);
          }

          this.allQuizResponses = groupedByAttemptId;
        }
      } catch (error) {
        console.error("Error in getQuizResponses ", error);
      }
    },

    async getTraining() {
      try {
        const res = await CommonServices.getFranchiseTraining({
          ft_id: this.ft_id,
        });
        if (res.data.status) {
          this.training = res.data.data;
          this.allVideos = res.data.data.videos;
          this.presentations = res.data.data.presentations;
        }
      } catch (error) {
        console.error("Error in getTraining ", error);
      }
    },
    async nextQuestion() {
      if (!this.selectedAnswer) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Please choose an option",
            icon: "EditIcon",
            variant: "secondary",
          },
        });
        return;
      }

      await CommonServices.setQuestionResponse({
        fta_id: this.selectedAnswer,
        attempt_id: this.attempt_id,
      });

      this.questionIndex++;
      this.selectedAnswer = null;
    },

    getAttemptOptions(video_id) {
      return (
        this.currentQuizResponses
          // .filter((response) => response[0].ftv_id === video_id)
          .map((response, index) => index + 1)
      );
    },

    async finishQuiz() {
      if (!this.selectedAnswer) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Please choose an option",
            icon: "EditIcon",
            variant: "secondary",
          },
        });
        return;
      }

      await CommonServices.setQuestionResponse({
        fta_id: this.selectedAnswer,
        attempt_id: this.attempt_id,
      });

      // window.location.reload()

      this.$refs["quiz-modal-ref"].hide();
      this.resetQuizModal();

      this.attempt_id = null;

      this.getTraining();
      this.getQuizResponses();
    },
    resetQuizModal() {
      this.trainingIndex = null;
      this.questionIndex = null;
      this.selectedAnswer = null;
    },
    showQuizModal(video_id) {
      this.questionIndex = 0;
      this.quizVideoId = video_id;
      this.attempt_id = uuidv4();

      this.currentQuestions = this.training.questions.filter(
        (question) => question.ftv_id === this.quizVideoId
      );

      this.$refs["quiz-modal-ref"].show();
    },

    showResponseModal(video_id) {
      this.quizVideoId = video_id;
      this.getQuizResponseForVideo(video_id);
      this.$refs["response-modal-ref"].show();
    },

    closeResponseModal() {
      this.$refs["response-modal-ref"].hide();
      this.resetResponseModal();
    },
  },
  computed: {
    user_type() {
      if (store.getters["user/getUserDetails"]?.user_type) {
        return store.getters["user/getUserDetails"].user_type;
      }
    },
  },
  beforeMount() {
    const { ft_id } = this.$route.params;
    if (ft_id) {
      this.ft_id = ft_id;
    }
    this.getTraining();
    if (this.user_type === "agent" || this.user_type === "franchise") {
      this.getQuizResponses();
    }
  },
};
</script>
<style>
.video-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 28px;
  width: 100%;
}

.video-grid-videos {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  width: 100%;
}

.video-container {
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  overflow: hidden;
  box-sizing: border-box;
  text-align: center;
  height: 320px;
}

.training-title {
  text-decoration: underline;
}

/* .video-container {
  height: auto;
  width: 70%;
  border-radius: 10px;
} */

.dashboard__heading {
  color: #002884;
  font-weight: bold;
  margin-bottom: 1.2rem;
}

.student-dashboard .card-title {
  color: #000000;
  font-weight: 600;
}

.primary-color {
  color: #00d09c;
}

.min-height {
  min-height: 60vh;
}

.container {
  display: flex;
  height: 50vh;
  overflow: hidden;
}

.scrollable {
  height: 100%;
  overflow-y: auto;
}

.map-column {
  width: 60%;
}

.pdf-link {
  transition: all 0.3s;
}

.pdf-link:hover {
  color: #00d09c;
}

iframe {
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column-reverse;
    height: auto;
  }

  .scrollable {
    height: auto;
    overflow-y: visible;
  }

  .map-column {
    width: 100%;
  }
}
</style>
