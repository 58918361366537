var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-table',{attrs:{"hover":"","items":_vm.services,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(show)",fn:function(row){return [(!row.detailsShowing && row.item.features && row.item.features.length > 1)?_c('feather-icon',{attrs:{"icon":"PlusCircleIcon","size":"20"},on:{"click":row.toggleDetails}}):_vm._e(),(row.detailsShowing)?_c('feather-icon',{attrs:{"icon":"MinusCircleIcon","size":"20"},on:{"click":row.toggleDetails}}):_vm._e()]}},{key:"cell(final_delivery)",fn:function(row){return [(row.item.final_delivery)?_c('span',[_vm._v(" "+_vm._s(_vm.moment(row.item.final_delivery).format("DD MMM, YYYY HH:mm:ss"))+" ")]):(['services'].includes(_vm.user_type))?_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"Select Date","config":{
      dateFormat: 'Y-m-d H:i',
      enableTime: true,
      time_24hr: true,
      altInput: true,
      altFormat: 'd-m-Y H:i',
      allowInput: true,
      noCalendar: false,
    }},on:{"input":function($event){return _vm.onSearch(row.item.plan_id)}},model:{value:(row.item.final_delivery),callback:function ($$v) {_vm.$set(row.item, "final_delivery", $$v)},expression:"row.item.final_delivery"}}):_c('span',[_vm._v(" - ")])]}},{key:"row-details",fn:function(row){return [(row.item.plan_id === 6 && row.item.content.length)?_c('div',{staticClass:"pt-3 pb-5"},[_c('h4',{staticClass:"my-1 ml-1 p-0",staticStyle:{"min-width":"500px"}},[_vm._v("Content")]),_vm._l((row.item.content),function(c,index){return _c('div',{key:'c_' + index},[_c('div',{staticStyle:{"width":"270px"}},[_c('embed',{attrs:{"src":_vm.generatePdfBlobUrl(c.sat_report_link),"width":"100%","height":"200px","type":"application/pdf"}}),_c('br'),_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',[_c('a',{attrs:{"target":"_blank","href":_vm.generatePdfBlobUrl(c.sat_report_link)}},[_vm._v("open")])]),_c('span',[_c('a',{attrs:{"download":"sat_report","href":_vm.generatePdfBlobUrl(c.sat_report_link)}},[_vm._v("download")])])])])])})],2):_vm._e(),_c('div',{staticClass:"shadow-xl rounded-lg border",staticStyle:{"overflow":"hidden"}},[_c('div',{staticClass:"d-flex border-bottom bg-white"},[_c('h4',{staticClass:"my-1 ml-1 p-0",staticStyle:{"min-width":"500px"}},[_vm._v("Features")]),_c('h4',{staticClass:"my-1",staticStyle:{"min-width":"200px"}},[_vm._v("Progress")]),_c('h4',{staticClass:"mt-1",staticStyle:{"min-width":"200px"}},[_vm._v("Completion Date")])]),_c('div',{staticStyle:{"background-color":"#fafafa"}},_vm._l((row.item.features),function(feature,index){return _c('div',{key:'feature_' + index,staticClass:"d-flex mt-1 border-bottom pb-1 ml-1"},[_c('span',{staticClass:"font-weight-bold",staticStyle:{"min-width":"500px"}},[_vm._v(_vm._s(feature.feature_name))]),_c('b-form-checkbox',{attrs:{"disabled":!feature.user_type_key || feature.user_type_key !== _vm.user_type,"checked":_vm.isFeatureCompleted(feature.feature_id)},on:{"change":function (val) {
                  if (val) {
                    _vm.markFeatureCompleted(
                      row.item.service_id,
                      row.item.plan_id,
                      feature.feature_id
                    );
                  } else {
                    _vm.markFeatureCompleted(
                      row.item.service_id,
                      row.item.plan_id,
                      feature.feature_id,
                      true
                    );
                  }
                }}}),_c('div',{staticStyle:{"min-width":"200px"}},[_c('b-badge',{staticClass:"ml-1 mr-5",attrs:{"variant":_vm.isFeatureCompleted(feature.feature_id)
                  ? 'success'
                  : 'warning'}},[_vm._v(_vm._s(_vm.isFeatureCompleted(feature.feature_id) ? "Complete" : "Pending"))])],1),(_vm.getCompletedFeatureDate(feature.feature_id))?_c('div',{staticClass:"rounded-lg shadow-sm",staticStyle:{"padding":"2px 8px"}},[_vm._v(" "+_vm._s(_vm.moment(_vm.getCompletedFeatureDate(feature.feature_id)).format( "DD MMM, YYYY HH:mm:ss" ))+" ")]):_vm._e()],1)}),0)])]}},{key:"cell(buy_date)",fn:function(row){return [_vm._v(" "+_vm._s(_vm.moment(row.item.transaction_datetime).format("DD MMM, YYYY - HH:mm"))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }