<template>
  <div>
    <send-email-modal :show-modal="emailDetails.emailModalOpen" :to="emailDetails.email"
      :ok="(data) => sendStudentMail(data)" :close="closeEmailModal" />

    <send-email-modal :show-modal="bulkEmailDetails.emailModalOpen" :to="bulkEmailDetails.email"
      :ok="(data) => sendBulkMail(data)" :close="closeEmailModal" />
    <b-card>
      <b-card class="mb-0">
        <div class="d-flex justify-content-between mb-1">
          <h3>Filters</h3>
          <div class="d-flex" style="gap: 10px" @click="resetFilters">
            <div style="height: 30px" class="
                border
                d-flex
                justify-content-center
                align-items-center
                cursor-pointer
                px-2
                rounded-sm
                shadow-sm
                bg-primary
                text-white
              ">
              Reset Filters
            </div>
          </div>
        </div>

        <b-row style="gap: 10px 0">
          <b-col cols="12">
            <b-form-input v-model="rec_filters.search" placeholder="Search" @input="onFilterChange" />
          </b-col>
          <b-col cols="3">
            <v-select v-model="rec_filters.university_id" placeholder="University" :options="universities" clearable
              label="university_name" :reduce="(item) => item.university_id" @input="onFilterChange" />
          </b-col>
          <b-col cols="3">
            <v-select v-model="rec_filters.status_id" placeholder="Application Status" :options="application_status"
              clearable label="status_name" :reduce="(item) => item.status_id" @input="onFilterChange" />
          </b-col>
          <b-col cols="2">
            <v-select v-model="rec_filters.country_id" placeholder="Country" :options="countries" clearable
              label="country_name" :reduce="(item) => item.country_id" @input="onFilterChange" />
          </b-col>
          <!-- <b-col cols="3">
            <v-select
              v-model="rec_filters.counselor_id"
              placeholder="Counselor"
              clearable
              :options="
                counselors.map((counselor) => ({
                  value: counselor.user_id,
                  label: ${counselor.user_firstname} ${counselor.user_lastname},
                }))
              "
              :reduce="(item) => item.value"
              @input="onFilterChange"
            />
          </b-col> -->
          <b-col cols="2">
            <v-select v-model="rec_filters.course_level_id" placeholder="Level" :options="levels" clearable
              label="course_level_name" :reduce="(item) => item.course_level_id" @input="onFilterChange" />
          </b-col>
          <b-col cols="2">
            <v-select v-model="rec_filters.course_category_id" placeholder="Discipline" :options="disciplines" clearable
              label="course_category_name" :reduce="(item) => item.course_category_id" @input="onFilterChange" />
          </b-col>
        </b-row>
      </b-card>
      <div v-if="show_bulk_actions" class="w-100">
        <div class="
            border-top border-bottom
            d-flex
            align-items-center
            justify-content-center
            py-1
            mb-1
          ">
          <b-button variant="primary" style="width: fit-content" @click="showBulkEmailModal">
            Send Bulk Mail
          </b-button>
        </div>
      </div>
      <b-card>
        <div>
          <!-- Sorting By: <b>{{ sortBy }}</b
          >, Sort Direction:
          <b>{{ sortDesc ? "Descending" : "Ascending" }}</b> -->
        </div>
        <b-table style="max-height: 60vh" :fields="tableColumns" :items="recommendations" responsive sticky-header
          show-empty empty-text="" :busy="isLoading" class="custom-table" sort-icon-left>
          <template #head(recommended_count)="row">
            <div class="d-flex align-items-center" style="gap: 20px">
              <feather-icon :icon="sortDesc ? 'ChevronDownIcon' : 'ChevronUpIcon'" size="18"
                @click="changesortby('recommended_count', !sortDesc)" />

              Recommendations
            </div>
          </template>
          <template #head(favourites_count)="row">
            <div class="d-flex align-items-center" style="gap: 20px">
              <feather-icon :icon="sortDesc ? 'ChevronDownIcon' : 'ChevronUpIcon'" size="18"
                @click="changesortby('favourites_count', !sortDesc)" />

              Favourites
            </div>
          </template>
          <template #head(applications_count)="row">
            <div class="d-flex align-items-center" style="gap: 20px">
              <feather-icon :icon="sortDesc ? 'ChevronDownIcon' : 'ChevronUpIcon'" size="18"
                @click="changesortby('applications_count', !sortDesc)" />

              Applications
            </div>
          </template>
          <template #cell(recommended_count)="row">
            <div class="d-flex">
              <b-form-checkbox :disabled="(activeColumn && activeColumn !== 'recommended_count') ||
                (activeRow !== null && activeRow !== row.index)" @change="(e) => {
                  row.detailsShowing = !row.detailsShowing;
                  row.toggleDetails(e);
                  franchiseDetails(row.item, row.field.key);
                  if (e) {
                    activeColumn = 'recommended_count';
                    activeRow = row.index;
                  } else {
                    activeColumn = null;
                    activeRow = null;
                  }
                }" />
              {{ row.item.recommended_count }}
            </div>
          </template>
          <template #cell(favourites_count)="row">
            <div class="d-flex">
              <b-form-checkbox :disabled="(activeColumn && activeColumn !== 'favourites_count') ||
                (activeRow !== null && activeRow !== row.index)" @change="(e) => {
                  row.detailsShowing = !row.detailsShowing;
                  row.toggleDetails(e);
                  franchiseDetails(row.item, row.field.key);
                  if (e) {
                    activeColumn = 'favourites_count';
                    activeRow = row.index;
                  } else {
                    activeColumn = null;
                    activeRow = null;
                  }
                }" />

              {{ row.item.favourites_count }}
            </div>
          </template>
          <template #cell(applications_count)="row">
            <div class="d-flex">
              <b-form-checkbox :disabled="(activeColumn && activeColumn !== 'applications_count') ||
                (activeRow !== null && activeRow !== row.index)" @change="(e) => {
                  row.detailsShowing = !row.detailsShowing;
                  row.toggleDetails(e);
                  franchiseDetails(row.item, row.field.key);
                  if (e) {
                    activeColumn = 'applications_count';
                    activeRow = row.index;
                  } else {
                    activeColumn = null;
                    activeRow = null;
                  }
                }" />

              {{ row.item.applications_count }}
            </div>
          </template>

          <template #row-details="row">
           
            <div v-if="type === 'recommended_count'">
              <!-- <ol>
                <li v-for="(user, index) in innerData.favourite_user_list">
                  {{ user.fullName }}
                </li>
              </ol> -->
              <b-table :fields="childTableColumns" :items="innerData.recommend_user_list" responsive sticky-header
                head-variant="light" bordered>
                <template #head(user_id)="row">
                  <!-- {{ studentsList }} -->
                  <div class="d-flex align-items-center" style="gap: 20px">
                    <div style="width: 20px">
                      <b-form-checkbox v-model="select_all" @change="
                        selectOrDeselectAll__(innerData.recommend_user_list)
                        " />
                    </div>
                    ID
                  </div>
                </template>
                <template #cell(user_id)="row">
                  <div class="d-flex align-items-center" style="gap: 20px">
                    <div style="width: 20px; margin-right: 5px">
                      <b-form-checkbox v-model="row.item.rowSelected"
                        @change="() => selectOrDeselectSingle(row.item)" />
                    </div>
                    {{ row.item.user_id }}
                  </div>
                </template>
                <template #cell(fullName)="row">
                  {{ row.item.fullName }}
                </template>
                <template #cell(email)="row">
                  {{ row.item.user_email }}
                </template>
                <template #cell(phone)="row">
                  {{ row.item.user_mobile }}
                  <a target="_blank" :href="`https://wa.me/${getWhatsappFormattedMobile(
                    row.item.user_mobile
                  )}`">
                    <img src="/whatsapp.webp" alt="" style="width: 25px; height: 25px" />
                  </a>
                </template>
              </b-table>
            </div>
            <div v-if="type === 'favourites_count'">
              <!-- <ol>
                <li v-for="(user, index) in innerData.favourite_user_list">
                  {{ user.fullName }}
                </li>
              </ol> -->
              <b-table :fields="childTableColumns" :items="innerData.favourite_user_list" responsive sticky-header
                head-variant="light" bordered>
                <template #head(user_id)="row">
                  <!-- {{ studentsList }} -->
                  <div class="d-flex align-items-center" style="gap: 20px">
                    <div style="width: 20px">
                      <b-form-checkbox v-model="select_all" @change="
                        selectOrDeselectAll__(innerData.favourite_user_list)
                        " />
                    </div>
                    ID
                  </div>
                </template>
                <template #cell(user_id)="row">
                  <div class="d-flex align-items-center" style="gap: 20px">
                    <div style="width: 20px; margin-right: 5px">
                      <b-form-checkbox v-model="row.item.rowSelected"
                        @change="() => selectOrDeselectSingle(row.item)" />
                    </div>
                    {{ row.item.user_id }}
                  </div>
                </template>
                <template #cell(fullName)="row">
                  {{ row.item.fullName }}
                </template>
                <template #cell(email)="row">
                  {{ row.item.user_email }}
                </template>
                <template #cell(phone)="row">
                  {{ row.item.user_mobile }}
                  <a target="_blank" :href="`https://wa.me/${getWhatsappFormattedMobile(
                    row.item.user_mobile
                  )}`">
                    <img src="/whatsapp.webp" alt="" style="width: 25px; height: 25px" />
                  </a>
                </template>
              </b-table>
            </div>
            <div v-if="type === 'applications_count'">
              <!-- <ol>
                <li v-for="(user, index) in innerData.application_user_list">
                  {{ user.fullName }}
                </li>
              </ol> -->
              <b-table :fields="childTableColumns" :items="innerData.application_user_list" responsive sticky-header
                head-variant="light" bordered>
                <template #head(user_id)="row">
                  <!-- {{ studentsList }} -->
                  <div class="d-flex align-items-center" style="gap: 20px">
                    <div style="width: 20px">
                      <b-form-checkbox v-model="select_all" @change="
                        selectOrDeselectAll__(innerData.application_user_list)
                        " />
                    </div>
                    ID
                  </div>
                </template>
                <template #cell(user_id)="row">
                  <div class="d-flex align-items-center" style="gap: 20px">
                    <div style="width: 20px; margin-right: 5px">
                      <b-form-checkbox v-model="row.item.rowSelected"
                        @change="() => selectOrDeselectSingle(row.item)" />
                    </div>
                    {{ row.item.user_id }}
                  </div>
                </template>
                <template #cell(fullName)="row">
                  {{ row.item.fullName }}
                </template>
                <template #cell(email)="row">
                  {{ row.item.user_email }}
                </template>
                <template #cell(phone)="row">
                  {{ row.item.user_mobile }}
                  <a target="_blank" :href="`https://wa.me/${getWhatsappFormattedMobile(
                    row.item.user_mobile
                  )}`">
                    <img src="/whatsapp.webp" alt="" style="width: 25px; height: 25px" />
                  </a>
                </template>
              </b-table>
            </div>
          </template>
        </b-table>
      </b-card>
      <div class="d-flex justify-content-end mt-2">
        <b-pagination aria-controls="StudentsListTableId" v-model="pagination.currentPage"
          :total-rows="totalRecommendations" :per-page="pagination.perPage" first-number last-number
          class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @change="onChange">
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>

      <div class="loader" v-if="isLoading">
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import CommonServices from "@/apiServices/CommonServices";
import HomeServices from "@/apiServices/HomeServices";
import SendEmailModal from "@/views/components/MyLeadComponents/SendEmailModal.vue";

import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BCollapse,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  BPagination,
  BRow,
  BSpinner,
  BTable,
  BTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  name: "Recommendation",
  components: {
    BBadge,
    BButton,
    BCard,
    BCol,
    BCollapse,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormCheckbox,
    BFormInput,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    BModal,
    BPagination,
    BRow,
    BSpinner,
    BTable,
    BTooltip,
    vSelect,
    SendEmailModal,
  },
  data() {
    return {
      sortBy: "recommended_count",
      activeColumn: null,
      activeRow: null,
      sortDesc: true,
      isLoading: false,
      showFiltersMembers: false,
      showFilters: false,
      totalRecommendations: 0,
      recommendations: [],
      universities: [],
      levels: [],
      disciplines: [],
      counselors: [],
      debouce: null,
      select_all: false,
      selected_lead_ids: [],
      selectable_lead_ids: [],
      studentsList: [],
      selected_lead_emails: [],
      application_status: [],
      countries: [],
      tableColumns: [
        { key: "university_name", label: "University", sortable: false },
        {
          key: "recommended_count",
          label: "Recommendations",
          sortable: false,
        },
        { key: "favourites_count", label: "Favourites", sortable: false },
        { key: "applications_count", label: "Applications", sortable: false },
      ],
      childTableColumns: [
        { key: "user_id", label: "ID", sortable: false },
        { key: "fullName", label: "Name", sortable: false },
        { key: "email", label: "Email", sortable: false },
        { key: "phone", label: "Phone", sortable: false },
      ],

      rec_filters: {
        search: "",
        university_id: null,
        counselor_id: null,
        franchise_id: null,
        course_level_id: null,
        course_category_id: null,
        status_id: null,
        country_id: null,
      },
      pagination: {
        currentPage: 1,
        perPage: 10,
        perPageOptions: [5, 10, 20, 30],
      },
      expandedRows: [], // Store the expanded state of each row
      expandedModal: null, // Used for modal expansion
      innerData: null,
      type: null,
      emailDetails: {
        emailModalOpen: false,
        email: "",
      },

      bulkEmailDetails: {
        emailModalOpen: false,
        email: "",
      },
    };
  },
  methods: {
    onChange() {
      this.$nextTick(() => {
        this.getRecommmendations();
      });
    },
    getWhatsappFormattedMobile(mobile) {
      let finalNumber = [];
      mobile = mobile.split("");
      mobile.forEach((number) => {
        let n = parseInt(number);
        if (!isNaN(n)) {
          finalNumber.push(n);
        }
      });

      return finalNumber.join("");
    },
    async changesortby(sortBy, sortDesc) {
      this.sortBy = sortBy;
      this.sortDesc = sortDesc;
      this.getRecommmendations();
    },
    async franchiseDetails(row, type) {
      this.type = type;
      this.innerData = row;
      if (!row.detailsShowing) {
        this.select_all = false;
        this.selected_lead_ids = [];
        this.selected_lead_emails = [];
        this.studentsList = [];
      }    
    },
    selectOrDeselectAll__(studentsList) {
      this.studentsList = studentsList;
      this.selectOrDeselectAll();
    },

    selectOrDeselectAll() {
      this.studentsList.forEach((student) => {
        student.rowSelected = this.select_all; // Sync the checkbox state
      });

      if (this.select_all) {
        this.selected_lead_ids = this.studentsList.map(
          (student) => student.user_id
        );
        this.selected_lead_emails = this.studentsList.map(
          (student) => student.user_email
        );
      } else {
        this.selected_lead_ids = [];
        this.selected_lead_emails = [];
      }
    },

    selectOrDeselectSingle(lead) {
      if (lead.rowSelected) {
        this.selected_lead_ids.push(lead.user_id);
        this.selected_lead_emails.push(lead.user_email);
      } else {
        this.selected_lead_ids = this.selected_lead_ids.filter(
          (id) => id !== lead.user_id
        );
        this.selected_lead_emails = this.selected_lead_emails.filter(
          (email) => email !== lead.user_email
        );
      }

      // Check if all rows are selected
      this.select_all = this.studentsList.every(
        (student) => student.rowSelected
      );
    },
    async showBulkEmailModal() {
      this.bulkEmailDetails.email = this.selected_lead_emails;
      console.log(this.selected_lead_emails);
      this.bulkEmailDetails.emailModalOpen = true;
    },
    async sendBulkMail(data) {
      try {
        const res = await CommonServices.sendBulkMail({
          ...data,
        });
        if (res.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Emails Sent Successfully",
              icon: "Check",
              variant: "success",
            },
          });
          return;
        }
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Failed to send Emails",
            icon: "X",
            variant: "failure",
          },
        });
      } catch (error) {
        console.log({ error });
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Failed to send Emails",
            icon: "X",
            variant: "failure",
          },
        });
      }
    },
    showEmailModal(email) {
      this.emailDetails = {};
      this.emailDetails.email = email;
      this.emailDetails.emailModalOpen = true;
    },

    checkEmail(email) {
      const emailRegex = /\S+@\S+\.\S+/;
      if (emailRegex.test(email)) {
        return true;
      }
      return false;
    },

    closeEmailModal() {
      this.emailDetails.emailModalOpen = false;
      this.bulkEmailDetails.emailModalOpen = false;
      this.$forceUpdate();
    },
    toggleDetails(index) {
      if (this.expandedRows.includes(index)) {
        this.expandedRows = this.expandedRows.filter((i) => i !== index);
      } else {
        this.expandedRows.push(index);
      }
    },
    async getRecommmendations() {
      try {
        this.isLoading = true;
        const res = await CommonServices.recommendation({
          ...this.rec_filters,
          perPage: this.pagination.perPage,
          currentPage: this.pagination.currentPage,
          sortBy: this.sortBy,
          sortOrder: this.sortDesc ? "desc" : "asc",
        });
        if (res.data.status) {
          this.recommendations = res.data.data.data.map((item) => ({
            ...item,
            expandedRecommendation: false,
            expandedFavourite: false,
          }));

          this.totalRecommendations = res.data.data.count;
          this.isLoading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getCourseLevels() {
      try {
        const res = await HomeServices.getCourseLevels();
        if (res.data.status) {
          this.levels = res.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getDiscipline() {
      try {
        const res = await HomeServices.getCourseCategories();
        if (res.data.status) {
          this.disciplines = res.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getCounselors() {
      try {
        const res = await CommonServices.getAllCounselorsCommon();
        if (res.data.status) {
          this.counselors = res.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    counselorLabel(item) {
      if (!item) return "";
      return `${item.user_firstname} (${item.user_lastname})`;
    },
    async getUniversities() {
      try {
        const res = await CommonServices.getAllUniversities();
        if (res.data.status) {
          this.universities = res.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getAllApplicationStatuses() {
      try {
        const res = await CommonServices.getAllApplicationStatuses();
        if (res.data.status) {
          this.application_status = res.data.data.filter(
            (e) => e.status_id <= 4
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getAllCountries() {
      try {
        const res = await HomeServices.getAllCountries();
        if (res.data.status) {
          this.countries = res.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    resetFilters() {
      this.rec_filters = {
        search: "",
        university_id: null,
        counselor_id: null,
        franchise_id: null,
        course_level_id: null,
        status_id: null,
        country_id: null,
        course_category_id: null,
      };
    },
    onFilterChange() {
      this.getRecommmendations();
    },
    viewDetails(student_user_ids) {
      this.$router.push({ name: "Student List" });
      localStorage.setItem(
        "student_user_ids",
        JSON.stringify(student_user_ids)
      );
    },
    toggleRow(item, type) {
      if (type === "recommendation") {
        this.$set(item, "expandedRecommendation", !item.expandedRecommendation);
      } else if (type === "favourite") {
        this.$set(item, "expandedFavourite", !item.expandedFavourite);
      }
    },
  },
  watch: {
    "pagination.currentPage": "getRecommmendations",
  },
  computed: {
    show_bulk_actions() {
      return this.selected_lead_ids.length > 0;
    },
  },
  beforeMount() {
    this.getRecommmendations();
    this.getAllApplicationStatuses();
    this.getAllCountries();
    this.getUniversities();
    this.getCourseLevels();
    this.getDiscipline();
    this.getCounselors();
  },
};
</script>