<template>
  <div>
    <b-card>
      <b-form-input
        placeholder="Search..."
        v-model="filters.search"
        @input="onSearch"
      />
    </b-card>
    <b-card class="bg-white p-2">
      <div class="row row-cols-3">
        <div
          v-for="(training, t_index) in resources"
          :key="'training_' + t_index"
          class="mt-1 col w-100"
          style="cursor: pointer"
          @click="
            training.videos && training.videos.length
              ? goToTraining(training)
              : goToWebsite(training.presentations[0].ftp_id)
          "
        >
          <div class="h-100">
            <div class="col h-100 p-1">
              <article class="postcard dark red h-100">
                <a class="postcard__img_link overflow-hidden" href="#">
                  <div class="overflow-hidden w-100">
                    <img
                      class="postcard__img img-fluid"
                      :src="FILESURL + training.ft_image"
                      alt="Image Title"
                    />
                  </div>
                </a>
                <div class="postcard__text w-100">
                  <div v-if="training.videos && training.videos.length">
                    <h1 class="postcard__title red">
                      <span class="text-dark">{{ training.ft_name }}</span>
                    </h1>
                  </div>
                  <div
                    v-else
                    class="d-flex align-items-start justify-content-between"
                  >
                    <div>
                      <h1 class="postcard__title red">
                        <span class="text-dark">{{ training.ft_name }}</span>
                      </h1>
                    </div>
                    <div class="" v-if="training.videos.length === 0">
                      <feather-icon icon="ExternalLinkIcon" size="24" />
                    </div>
                  </div>
                  <ul
                    class="postcard__tagbox justify-content-start mb-0"
                    v-if="training.videos && training.videos.length"
                  >
                    <li class="tag__item bg-success">
                      <i class="fas fa-clock mr-2"></i
                      >{{ training.ft_duration }}
                    </li>
                  </ul>
                  <div class="postcard__bar"></div>
                  <div class="postcard__preview-txt text-dark"></div>
                  <h5
                    class="mt-1"
                    v-if="training.videos && training.videos.length"
                  >
                    Videos in this course
                  </h5>
                  <div class="d-flex">
                    <div
                      v-for="(video, t_index) in training.videos"
                      :key="'video_index_' + t_index"
                    >
                      <ul class="postcard__tagbox justify-content-start">
                        <li class="tag__item">
                          {{ video.ftv_name }} -
                          {{ video.ftv_duration }}
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div
                    class="videos-in-course d-flex align-items-center"
                    style="gap: 10px"
                  >
                    <div></div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BBadge,
  BCardTitle,
  BCardText,
  BAvatar,
  BButton,
  BInputGroup,
  BFormInput,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { FILESURL, WEBSITE_URL } from "@/config";
import CommonServices from "@/apiServices/CommonServices";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BBadge,
    BButton,
    BAvatar,
    BCardTitle,
    BCardText,
    BInputGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      search: "",
      resources: [],
      FILESURL,
      WEBSITE_URL,
      selectedAnswer: null,
      trainingIndex: null,
      questionIndex: null,
      user_type: null,
      debounce: null,
      filters: {
        search: "",
        fa_id: null,
      },
      presentations: [],
    };
  },
  computed: {},
  methods: {
    async goToTraining(training) {
      this.$router.push({
        name: "Franchise Training Video Details",
        params: { ft_id: training.ft_id },
      });
    },
    onSearch() {
      if (this.debounce) {
        this.debounce = null;
      }
      this.debounce = setTimeout(() => {
        this.onFilterChange();
      }, 1000);
    },
    onFilterChange() {
      this.$nextTick(() => {
        this.getDetails();
      });
    },
    async getDetails(fa_id) {
      try {
        const payload = {
          fa_id: fa_id || this.filters.fa_id,
          search: this.filters.search,
        };
        const res = await CommonServices.getAcademyDetails(payload);
        this.resources = res.data.data;
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    },
    goToWebsite(url) {
      const website_url = `${WEBSITE_URL}/presentation-doc/${url}`;
      window.open(website_url, "_blank");
    },
  },
  beforeMount() {
    const { fa_id } = this.$route.params;
    if (!isNaN(fa_id)) {
      this.filters.fa_id = fa_id;
      this.getDetails(fa_id);
    }
  },
};
</script>
<style>
.video-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  width: 100%;
}

.video-container {
  /* margin: 10px;  */
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  height: 320px;
}

.training-title {
  text-decoration: underline;
}

/* .video-container {
    height: auto;
    width: 70%;
    border-radius: 10px;
  } */

.dashboard__heading {
  color: #002884;
  font-weight: bold;
  margin-bottom: 1.2rem;
}

.student-dashboard .card-title {
  color: #000000;
  font-weight: 600;
}

.primary-color {
  color: #00d09c;
}

.min-height {
  min-height: 60vh;
}

.container {
  display: flex;
  height: 50vh;
  overflow: hidden;
}

.scrollable {
  height: 100%;
  overflow-y: auto;
}

.map-column {
  width: 60%;
}

iframe {
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column-reverse;
    height: auto;
  }

  .scrollable {
    height: auto;
    overflow-y: visible;
  }

  .map-column {
    width: 100%;
  }
}
</style>
