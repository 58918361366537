<template>
  <div class="mt-1">
    <b-card-body class="pt-0 ">
      <div class="service">
        <div class="inner_stats">
          <div v-for="(service, index) in earningsChart.series" :key="index" class="stats">
            <h5 class="font-small-2 pro_text">
              {{ service.name }}
            </h5>
            <h6 @click="goToService(service.id)" class="cursor-pointer">
              ₹{{ service.value.toLocaleString("en-IN") }}
            </h6>
          </div>
        </div>
        <div>
          <!-- chart -->
          <vue-apex-charts
            type="donut"
            v-if="isChartDataReady"
            height="300"
            :options="earningsChart.chartOptions"
            :series="earningsChart.series.map((s) => s.value)"
          />
        </div>
      </div>
    </b-card-body>
  </div>
</template>

<script>
import AgentServices from "@/apiServices/AgentServices";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import {
  BCard,
  BRow,
  BCol,
  BCardTitle,
  BCardText,
  BCardBody,
  BCardHeader,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
import CommonServices from "@/apiServices/CommonServices";
import store from "@/store";
import CounselorServices from "@/apiServices/CounselorServices";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    BCardBody,
    BCardHeader,
    VueApexCharts,
  },

  data() {
    return {
      isChartDataReady: false,
      earningsChart: {
        series: [],
        chartOptions: {
          chart: {
            type: "donut",
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: true,
          },
          legend: {
            show: true,
            position: "right",
            horizontalAlign: "center",
            fontSize: "12px",
          },
          labels: [], // Dynamically populated from services
          stroke: { width: 0 },
          colors: ["#92C5F9", "#4394E5", "#0066CC", "#004D99", "#003366"],
        },
        responsive: [
            {
              breakpoint: 2000,
              options: {
                chart: {
                  height: 300,
                },
              },
            },
            {
              breakpoint: 1700,
              options: {
                chart: {
                  height: 250,
                },
              },
            },
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 250,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 250,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 200,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 200,
                },
              },
            },
            {
              breakpoint: 768,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 80,
                },
              },
            },
          ],
      },
    };
  },
  computed: {
    user_type() {
      if (store.getters["user/getUserDetails"]?.user_type) {
        return store.getters["user/getUserDetails"].user_type;
      }
    },
  },

  methods: {
    async getServiceRevenue() {
      try {
        let res;
        if (this.user_type === "franchise") {
          res = await AgentServices.getServiceRevenue();
        }

        if (this.user_type === "counselor") {
          res = await CounselorServices.getServiceRevenue({
            franchise_id: this.franchise_id,
          });
        }
        if (res.data.status) {
          const servicesData = res.data.data;

          this.earningsChart.series = servicesData.map((service) => ({
            name: service.service_name,
            value: service.commission,
            id: service.service_id,
          }));

          this.earningsChart.chartOptions.labels = servicesData.map(
            (service) => service.service_name
          );

          this.isChartDataReady = true;
        }
      } catch (error) {
        console.error("Error fetching service revenue:", error);
      }
    },
    goToService(service_id) {
      this.$emit("goToService", service_id);
    },
  },
  props: {
    franchise_id: {
      type: Number,
      default: null,
    },
    date: {
      type: String,
      default: null,
    },
  },
  watch: {
    date() {},
  },
  beforeMount() {
    this.isChartDataReady = false; // Set to false before data fetch
    this.getServiceRevenue();
  },
};
</script>

<style scoped>
.filter_text {
  font-size: small;
  font-weight: 700;
  opacity: 0.7;
  margin-left: 5px;
  padding-bottom: 2px;
}
.pro_text {
  color: #92c5f9;
  font-weight: 600;
}

.service{
  display: grid;
  grid-template-columns: 1fr 2fr;

}
@media screen and (max-width: 1300px) {
  .service {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }

  .inner_stats {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    height: 100%;
  }
  .stats{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    margin-bottom: 10px;
  }
}

</style>
