<template>
  <b-card no-body class="card-developer-meetup">
    <div
      class="bg-light-primary rounded-top text-center cursor-pointer"
      @click="goToTraining(item.tr_id)"
    >
      <b-img
        :src="require('@/assets/images/illustration/email.svg')"
        alt="Meeting Pic"
        height="170"
      />
    </div>
    <b-card-body>
      <!-- metting header -->
      <div class="meetup-header d-flex align-items-center">
        <div class="meetup-day">
          <h6 class="mb-0">
            {{
              new Date(item.tr_date)
                .toLocaleDateString("en-US", {
                  weekday: "short",
                })
                .toUpperCase()
            }}
          </h6>
          <h3 class="mb-0">
            {{
              new Date(item.tr_date).toLocaleDateString("en-US", {
                day: "numeric",
                month: "short",
              })
            }}
          </h3>
        </div>
        <div class="my-auto">
          <b-card-title class="mb-25"> {{ item.tr_heading }} </b-card-title>
          <b-card-text class="mb-0" v-html="item.tr_description"></b-card-text>
        </div>
      </div>
      <!--/ metting header -->

      <!-- media -->
      <b-media no-body>
        <b-media-aside class="mr-1">
          <b-avatar rounded variant="light-primary" size="34">
            <feather-icon icon="CalendarIcon" size="18" />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">
            {{
              new Date(item.tr_date).toLocaleDateString("en-US", {
                day: "numeric",
                month: "short",
                weekday: "short",
              })
            }}
          </h6>
          <small>10:AM to 6:PM</small>
        </b-media-body>
      </b-media>
      <b-media no-body>
        <b-media-aside class="mr-1">
          <b-avatar rounded variant="light-primary" size="34">
            <feather-icon icon="MapPinIcon" size="18" />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <div class="d-flex align-items-center">
            <h6 class="mb-0 mr-1">Virtual</h6>
            <b-button
              size="sm"
              variant="outline-primary"
              class="btn-tour-skip"
              @click="openLink(item.tr_link)"
            >
              <span class="mr-25 align-middle">Join Now</span>
            </b-button>
          </div>
        </b-media-body>
      </b-media>

      <div style="height: 130px" class="mt-2 postion-relative">
        <!-- Confirmed Users -->
        <div class="d-flex align-items-center">
          <feather-icon icon="CheckCircleIcon" size="25" class="text-success" v-b-tooltip.hover.bottom="'Confirmed Registrations'" />
          <div class="ml-1 d-flex mr-1">
            <b-avatar
              v-if="item.confirmed_users && item.confirmed_users.length"
              v-for="student in item.confirmed_users"
              :key="student.user_id"
              v-b-tooltip.hover.bottom="student.user_fullname"
              :src="
                student.user_image ||
                require('@/assets/images/portrait/small/avatar-s-9.jpg')
              "
              class="pull-up"
            />

            <h6
              class="align-self-center cursor-pointer mb-0 ml-1"
              v-if="item.confirmed_users && item.confirmed_users.length"
              @click="openListSideBar(item.confirmed_users)"
            >
              {{ item.confirmed_users && item.confirmed_users.length }}
            </h6>
            <h6
              class="align-self-center cursor-pointer mb-0"
              v-if="!item.confirmed_users || !item.confirmed_users.length"
            >
              No Registrations
            </h6>
          </div>
        </div>
        <!-- Maybe Users -->
        <div class="d-flex align-items-center mt-2">
          <feather-icon icon="HelpCircleIcon" size="25" class="text-success"  v-b-tooltip.hover.bottom="'Unconfirmed Registrations'"/>
          <div class="ml-1 d-flex mr-1">
            <b-avatar
              v-if="item.maybe_users && item.maybe_users.length"
              v-for="student in item.maybe_users"
              :key="student.user_id"
              v-b-tooltip.hover.bottom="student.user_fullname"
              :src="
                student.user_image ||
                require('@/assets/images/portrait/small/avatar-s-9.jpg')
              "
              class="pull-up"
            />

            <h6
              class="align-self-center cursor-pointer mb-0 ml-1"
              v-if="item.maybe_users && item.maybe_users.length"
              @click="openListSideBar(item.maybe_users)"
            >
              {{ item.maybe_users && item.maybe_users.length }}
            </h6>
            <h6
              class="align-self-center cursor-pointer mb-0"
              v-if="!item.maybe_users || !item.maybe_users.length"
            >
              No Registrations
            </h6>
          </div>
        </div>

        <div class="register_btn_div">
          <div
            class="pt-50"
            v-if="user_type === 'franchise' && !checkIfPresent"
          >
            <b-button
              size="md"
              variant="outline-primary"
              @click="handleregister(item.tr_id, 'Y')"
            >
              Yes
            </b-button>
            <b-button
              size="md"
              variant="outline-primary"
              class="ml-1"
              @click="handleregister(item.tr_id, 'M')"
            >
              Maybe
            </b-button>
            <!-- <b-button
              size="md"
              variant="outline-primary"
              class="ml-1"
              @click="handleregister(item.tr_id, 'N')"
            >
              No
            </b-button> -->
          </div>
          <div v-if="user_type === 'franchise' && checkIfPresent">
            <b-button size="md" variant="success"> Registered </b-button>
          </div>
          <div
            class="pt-50"
            v-if="user_type === 'counselor' && type === 'aliff'"
          >
            <feather-icon
              icon="EditIcon"
              class="cursor-pointer"
              size="21"
              @click="openSidebar(item.tr_id)"
            />
          </div>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>
<script>
import {
  BCard,
  BImg,
  BCardBody,
  BCardText,
  BCardTitle,
  BMedia,
  BMediaAside,
  BAvatar,
  BAvatarGroup,
  VBTooltip,
  BMediaBody,
  BButton,
} from "bootstrap-vue";
import { FILESURL } from "@/config";
/* eslint-disable global-require */
export default {
  components: {
    BCard,
    BImg,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    BMediaBody,
    BButton,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      user: null,
    };
  },
  methods: {
    isRegistered(registeredList) {
      if (!registeredList || !Array.isArray(registeredList)) {
        return false;
      }
      return registeredList.some((item) => item.user_id === this.user_id);
    },
    openSidebar(trainingId) {
      this.$emit("open-sidebar", trainingId);
    },
    openListSideBar(regList) {
      this.$emit("open-list-sidebar", regList);
    },
    handleregister(trainingId, option) {
      this.$emit("handle-open-register-modal", trainingId, option);
    },
    goToTraining(training_id) {
      this.$router.push({
        path: `/training_details`,
        query: {
          id: training_id,
        },
      });
    },
    openLink(link) {
      window.open(link, "_blank");
    },
  },
  computed: {
    user_type() {
      return this.$store.getters["user/getUserDetails"].user_type;
    },
    user_id() {
      return this.$store.getters["user/getUserDetails"].user_id;
    },
    checkIfPresent() {
      return (
        (
          this.item.confirmed_users &&
          this.item.confirmed_users.filter(
            (user) => user.user_id === this.user_id
          )
        ).length > 0 ||
        (
          this.item.maybe_users &&
          this.item.maybe_users.filter((user) => user.user_id === this.user_id)
        ).length > 0
      );
    },
  },
  beforeMount() {
    // this.$emit("refresh");
  },
};
</script>
<style scoped>
.register_btn_div {
  position: absolute;
  bottom: 15px;
  right: 15px;
}
.card-developer-meetup {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures content fits within the height */
  overflow: hidden; /* Prevents content from spilling out */
}

.meetup-header .mb-0 {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limits text to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
</style>
