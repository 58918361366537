<template>
  <div class="d-flex justify-content-end mb-2">
    <b-sidebar id="list-sidebar" bg-variant="white" right backdrop shadow>
      <div class="p-1">
        <div class="mb-3 mt-2">
          <h4>Total Registered: {{ regList.length }}</h4>
        </div>
        <div>
          <div v-for="item in regList" :key="item.id">
            <div class="d-flex w-100 align-items-center mb-1">
              <b-avatar
                :src="
                  item.user_image ||
                  require('@/assets/images/portrait/small/avatar-s-9.jpg')
                "
                class="mr-1"
              />
              <div>{{ item.user_fullname }}</div>
            </div>
          </div>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import { BAvatar, BListGroup, BListGroupItem } from "bootstrap-vue";

import {
  BButton,
  BSidebar,
  VBToggle,
  BCardText,
  BForm,
  BFormInput,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCardCode,
    BButton,
    BSidebar,
    BListGroup,
    BListGroupItem,
    BCardText,
    BFormInput,
    BAvatar,
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  data() {
    return {
      regList: [],
    };
  },
  computed: {
    user_type() {
      return this.$store.getters["user/getUserDetails"].user_type;
    },
  },
  methods: {
    openListSidebar(regList) {
      this.$root.$emit("bv::toggle::collapse", "list-sidebar");
      this.regList = regList;
    },
  },
};
</script>
