var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-row',[(_vm.user_type === 'counselor')?_c('b-col',[_c('b-card',{staticClass:"card-app-design assigned-staff-card"},[_c('h4',[_vm._v("Student Registration Link")]),_c('b-button',{staticClass:"btn btn-sm btn-outline-primary mr-1",on:{"click":function($event){return _vm.openStudentLink()}}},[_c('span',{staticClass:"mr-25 align-middle"},[_vm._v("Click Here")])])],1)],1):_vm._e(),(_vm.user_type === 'operations_manager')?_c('b-col',[_c('ManagerTable')],1):_vm._e(),(_vm.user_type === 'counselor')?_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{attrs:{"title":"Filters"}},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Lead Type"}},[_c('v-select',{attrs:{"placeholder":"Lead Type","options":_vm.leadTypeOptions,"clearable":true,"item-text":"label","reduce":function (item) { return item.value; }},on:{"input":function () {
                      _vm.toggleShowManagers();
                      _vm.handleLeadSourceOptions();
                      _vm.getHomeData();
                    }},model:{value:(_vm.leadType),callback:function ($$v) {_vm.leadType=$$v},expression:"leadType"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Lead Source"}},[_c('v-select',{attrs:{"placeholder":"Lead Source","disabled":_vm.leadType ? false : true,"options":_vm.leadSourceOptions,"clearable":true,"item-text":"label","reduce":function (item) { return item.value; }},on:{"input":_vm.onSelectLeadSource},model:{value:(_vm.leadSource),callback:function ($$v) {_vm.leadSource=$$v},expression:"leadSource"}})],1)],1)],1)],1)],1):_vm._e(),(_vm.ss_id || _vm.area_f_id || _vm.micro_f_id || _vm.master_f_id)?_c('b-col',{attrs:{"cols":"12"}},[_c('agent-students',{attrs:{"show_all_level_data":_vm.showAllLevelData,"lead":_vm.getLeadData,"agent_user_id":_vm.ss_id
              ? _vm.ss_id
              : _vm.micro_f_id
              ? _vm.micro_f_id
              : _vm.area_f_id
              ? _vm.area_f_id
              : _vm.master_f_id}})],1):_vm._e(),(_vm.user_type === 'counselor' && _vm.quiz_responses && _vm.leadSource)?_c('b-col',{attrs:{"cols":"12"}},[_c('quiz-responses',{attrs:{"quiz_responses":_vm.quiz_responses,"franchise_id":_vm.leadSource}})],1):_vm._e()],1),_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"5"}},[_c('donut-chart',{attrs:{"series":_vm.applicationsStatus,"labels":_vm.applicationsStatusLabels}})],1),_c('b-col',{attrs:{"cols":"7"}},[_c('applications-statuses',{attrs:{"status_id":_vm.selectedStatusId,"data":_vm.applicationStatusCount,"status":_vm.selectedStatus},on:{"statusSelected":_vm.applicationStatusSelected,"applicationStatusDate":_vm.getApplicationStatusDate}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('applications-wise-students',{attrs:{"lead":_vm.getLeadData,"agent_user_id":_vm.micro_f_id ? _vm.micro_f_id : _vm.area_f_id,"status_id":_vm.selectedStatusId,"status":_vm.selectedStatus,"applicationStatusDate":_vm.applicationStatusDate}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }