<template>
  <div>
    <b-card-body class="pt-0">
      <b-row class="d-flex flex-column flex-md-row align-items-center">
        <div v-if="earningsChart.series.length == 0" class="d-flex justify-content-center align-items-center ">
         
          <h4> No Data Available</h4>
        </div>
        <b-col cols="12" md="4" class="mb-2 mb-md-0">
          <div v-for="(service, index) in earningsChart.series" :key="index">
            <h5 class="font-small-2 pro_text">{{ service.name }}</h5>
            <h6 @click="goToService(service.id)" class="cursor-pointer">
              ₹{{ service.value.toLocaleString("en-IN") }}
            </h6>
          </div>
        </b-col>
        <b-col cols="12" md="8">
          <!-- chart -->
          <vue-apex-charts
            type="donut"
            v-if="showChart"
            height="300"
            :options="earningsChart.chartOptions"
            :series="earningsChart.series.map((s) => s.value)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </div>
</template>

<script>
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import {
  BCard,
  BRow,
  BCol,
  BCardTitle,
  BCardText,
  BCardBody,
  BCardHeader,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import CommonServices from "@/apiServices/CommonServices";
import store from "@/store";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    BCardBody,
    BCardHeader,
    VueApexCharts,
  },

  data() {
    return {
      showChart: false,
      earningsChart: {
        series: [],
        chartOptions: {
          chart: {
            type: "donut",
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: true,
          },
          legend: {
            show: true,
            position: "right",
            horizontalAlign: "center",
            fontSize: "12px",
          },
          labels: [], // Dynamically populated from services
          stroke: { width: 0 },
          colors: ["#92C5F9", "#4394E5", "#0066CC", "#004D99", "#003366"],
        },
      },
    };
  },
  computed: {
    franchise_id() {
      const franchise_id = store.getters["user/getUserDetails"].user_id;
      if (franchise_id) {
        this.getRevenueById(franchise_id, this.service_id);
        return franchise_id;
      
    }
    },
  },
  watch: {},

  methods: {
    async getRevenueById(franchise_id, service_id) {
      try {
        this.showChart = false;
        const payload = {
          franchise_id: franchise_id,
          service_id: service_id,
        };
        const res = await CommonServices.getServiceRevenueById(payload);
        if (res.data.status) {
          
          const serviceData = res.data.data.service_data || [];
          // const serviceData = [
          //   {
          //     plan_name: "Aliff Basic",
          //     plan_id: 50,
          //     commission: 25000,
          //     package: "Aliff Basic",
          //   },
          //   {
          //     plan_name: "Aliff Pro",
          //     plan_id: 47,
          //     commission: 95000,
          //     package: "Aliff Pro",
          //   },
          // ];
          const servicePlans = res.data.data.service_plans || [];
          this.earningsChart.series = serviceData.map((service) => ({
            value: service.commission || 0,
            name: service.plan_name,
            id: service.plan_id,
          }));
          this.earningsChart.chartOptions.labels = serviceData.map(
            (service) => service.plan_name
          );
          this.totalEarnings = serviceData.reduce(
            (total, service) => total + (service.commission || 0),
            0
          );
          this.earningsChart = { ...this.earningsChart };
          this.showChart = true;
        }
      } catch (error) {
        console.error("Error fetching service revenue:", error);
      }
    },
    handleChartError(e) {
      console.error("Chart error:", e);
    },
    goToService(service_id) {
      this.getRevenueById(this.franchise_id, service_id);
    },
  },
  props: {
    service_id: {
      type: Number,
      default: null,
    },
    date: {
      type: String,
      default: null,
    },
  },
  watch: {
    date() {},
    service_id() {
      this.getRevenueById(this.franchise_id, this.service_id);
    },
  },
  beforeMount() {
  },
};
</script>

<style>
.filter_text {
  font-size: small;
  font-weight: 700;
  opacity: 0.7;
  margin-left: 5px;
  padding-bottom: 2px;
}
.pro_text {
  color: #92c5f9;
  font-weight: 600;
}
</style>
