export const emailVariables = [
  {
    label: "Lead Full Name",
    key: "full_name",
  },
  {
    label: "Lead Email",
    key: "email",
  },
  {
    label: "Lead Mobile",
    key: "mobile",
  },
  {
    label: "Lead City",
    key: "city_name",
  },
  {
    label: "Lead State",
    key: "state_name",
  },
  {
    label: "Lead Registration Date",
    key: "created_at",
  },
  {
    label: "Assignee Full Name",
    key: "assignee_fullname",
  },
  {
    label: "Assignee Email",
    key: "assignee_email",
  },
  {
    label: "Assignee Mobile",
    key: "assignee_mobile",
  },
];
