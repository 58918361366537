<template>
  <div>
    here
    <b-modal :no-close-on-backdrop="true" id="add-commission-modal" ref="add-commission-modal" centered hide-footer v-model="showModal">
      <validation-observer ref="addStudentForm">
        <b-form class="auth-register-form mt-2">
          <div>
            <b-form-group>
              <b-row class="mb-2 align-items-center">
                <b-col cols="4"><label>Market Commission</label></b-col>
                <b-col> <b-form-input v-model="form.cr_market.crm_percent" /></b-col>
              </b-row>

            </b-form-group>
          </div>
          <div class="text-right">
            <b-button @click="closeModal" variant="outline-danger mr-2">
              Close
            </b-button>
            <b-button variant="outline-primary" type="submit" @click.prevent="addCommission">
              Submit
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-card no-body class="p-2">
      <h5 class="mb-1">Filters</h5>
      <!-- Table Filters -->
      <b-row cols="12">
        <b-col md="9">
          <b-form-input v-model="filters.search" class="d-inline-block mr-1" placeholder="Search..."
            @input="onSearch" />
        </b-col>
        <b-col style="gap: 20px;" class="
            d-flex
            align-items-end
            justify-content-center
            mb-1 mb-md-0
            pr-2
            w-100
          ">
          <button class="btn btn-primary w-100" style="width: max-content; " @click="showModal = true">
            Add Commission
          </button>

        </b-col>

        <!-- Per Page -->
      </b-row>
    </b-card>
    <b-card class="mb-0">
      {{ fullCommissions }}
      <!-- Student List -->
      <b-table id="CommissionsTable" ref="refStudentsListTable" class="position-relative" :items="fullCommissions"
        responsive :fields="tableColumns" primary-key="uc_id" show-empty empty-text="No matching records found"
        :per-page="pagination.perPage" :current-page="pagination.currentPage" selectable>
        <template #cell(cr_id)="data">
          <span @click="getSingleCommissionRate(data.item.cr_id)">{{ data.item.cr_id }}</span>
        </template>
        <template #cell(uc_document_url)="data">
          <a :href="data.item.uc_document_url" target="_blank">View</a>
        </template>
        <template #cell(created_by)="data">
          {{ formatDate(data.item.created_by) }}
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            ">
            <span class="text-muted">
              {{ commissions.length }} entries
            </span>
          </b-col>
          <b-col cols="12" sm="6" class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            ">
            <b-pagination aria-controls="CommissionsTable" v-model="pagination.currentPage"
              :total-rows="commissions.length" :per-page="pagination.perPage" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import AccountsServices from "@/apiServices/AccountsServices";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BFormTextarea,
  BFormSelectOption,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import vSelect from "vue-select";
import currencyCodes from "currency-codes";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BForm,
    vSelect,
    BFormCheckbox,
    BFormGroup,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BFormSelectOption,
    flatPickr,
  },
  data() {
    return {
      university_id: null,
      commissions: [],
      fullCommissions: [],
      commission_form: {
        name: "",
        currency: "INR",
        amount: "",
        percent: null,
        document_url: "",
        notes: "",
        university_id: null,
      },
      form: {
        university_id: null,
        cr_id: null,
        cr_market: {
          crm_percent: null,
        }
      },
      filters: {
        search: "",
      },
      tableColumns: [
        { key: "cr_id", label: "Id", sortable: false },
        { key: "uc_name", label: "Name", sortable: false },
        { key: "uc_currency", label: "Currency", sortable: false },
        { key: "uc_amount", label: "Amount", sortable: false },
        { key: "uc_document_url", label: "Document", sortable: false },
        { key: "uc_notes", label: "Notes", sortable: false },
        { key: "created_by", label: "Date", sortable: false },
      ],
      pagination: {
        currentPage: 1,
        perPage: 20,
        perPageOptions: [5, 10, 20, 30],
      },
      currencyCodes,
      showModal: false,
    };
  },
  methods: {
    // async addCommission() {
    //   this.formErrors = {}; // Clear previous errors
    //   const { name, currency, amount } = this.commission_form;
    //   if (!name) this.formErrors.name = "Name is required.";
    //   if (!currency) this.formErrors.currency = "Currency is required.";
    //   if (!amount) this.formErrors.amount = "Amount is required.";

    //   if (Object.keys(this.formErrors).length > 0) {
    //     this.$toast({
    //       component: ToastificationContentVue,
    //       props: {
    //         title: "Validation Error",
    //         icon: "AlertTriangleIcon",
    //         variant: "danger",
    //         text: Object.values(this.formErrors).join(" & "),
    //       },
    //     });
    //     return;
    //   }
    //   const response = await AccountsServices.saveCommission(
    //     this.commission_form
    //   );
    //   if (response.data.status) {
    //     this.$toast({
    //       component: ToastificationContentVue,
    //       props: {
    //         title: "Commission Added",
    //         icon: "Check",
    //         variant: "success",
    //         text: response.data.message,
    //       },
    //     });
    //     setTimeout(() => {
    //       this.fullCommissions = [...this.fullCommissions, response.data.data]
    //       this.closeModal();
    //     }, 2000);
    //   }
    // },
    async getCommissions() {
      const response = await AccountsServices.getCommissionRates({
        university_id: this.university_id
      }
      );
      this.fullCommissions = response.data.data
      this.commissions = response.data.data
    },
    async getSingleCommissionRate(cr_id){
      try {
        const res = await AccountsServices.getSingleCommissionRate(cr_id);
        if(res.data.status){
          this.form = res.data.data;
          this.$refs["add-commission-modal"].show();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async addCommission(){
      try {
        const res = await AccountsServices.saveCommissionRates(this.form);
        if(res.data.status){
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Commission Added",
              icon: "Check",
              variant: "success",
              text: res.data.message,
            },
          });
          this.getCommissions();
          this.closeModal();
        }
      } catch (error) {
        console.log(error);
      }
      },
    onSearch() {
      this.fullCommissions = this.commissions.filter((uni, index) => {
        let uc_name = uni.uc_name;
        let text = this.filters.search;
        let text_search = true;

        if (text) {
          text_search = uc_name
            .toLowerCase()
            .includes(text.toLowerCase());
        }

        return text_search;
      });
    },
    closeModal() {
      this.$refs["add-commission-modal"].hide();
      this.commission_form = {
        name: "",
        currency: "INR",
        amount: "",
        document_url: "",
        notes: "",
        university_id: null,
        percent: null
      };
      this.formErrors = {};
    },
    resetFilters() {
      this.filters = {
        search: "",
      };

      this.getUniList();
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
  },
  beforeMount() {
    const { university_id } = this.$route.params;
    this.commission_form.university_id = university_id
    this.form.university_id = university_id
    this.university_id = university_id;
    this.getCommissions();
    this.$store.dispatch("home/onGetCountries");
  },
};
</script>
