<template>
  <div class="bg-white p-1">
    <b-tabs nav-class="justify-content-center">
      <div>
        <ListSideBar ref="list_sidebar" />
      </div>
      <SideBar ref="sidebar" :type="activeTab" @refresh="handleRefresh"/>
      <div class="d-flex justify-content-between align-items-center">
        <div class="mb-1 w-100">
          <b-form-input
            placeholder="Search..."
            v-model="search"
            @input="onSearch"
          ></b-form-input>
          
        </div>
      </div>
      <b-tab title="By Aliff" class="mt-3"active @click="onTabChange('aliff')">
        <div class="card_container">
          <div v-for="item in trainings" :key="item.tr_id" class="">
            <Card
              :item="item"
              type="aliff"
              @open-sidebar="handleOpenSidebar"
              @open-list-sidebar="handleOpenListSidebar"
              @handle-open-register-modal="handleOpenRegisterModal"
              @refresh="handleRefresh"
            />
          </div>

        </div>
        <div class="w-100 mt-5 ">
          <div v-if="!trainings.length" class="text-center w-100">
            <h4 class="">No Trainings Available</h4>
          </div>
        </div>
      </b-tab>
      <b-tab title="By University"  @click="onTabChange('university')">
        <div class="card_container">
          <div v-for="item in trainings" :key="item.tr_id" class="">
            <Card
              :item="item"
              type="university"
              @open-sidebar="handleOpenSidebar"
              @open-list-sidebar="handleOpenListSidebar"
              @handle-open-register-modal="handleOpenRegisterModal"
              @refresh="handleRefresh"
            />
          </div>
        </div>
        <div class="w-100 mt-5 ">
          <div v-if="!trainings.length" class="text-center w-100">
            <h4 class="">No Trainings Available</h4>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import MultiStateSwitch from "@/views/components/MultiStateSwitch.vue";
import Card from "./Card.vue";
import {
  BCard,
  BFormInput,
  BTab,
  BTabs,
  BButton,
  BModal,
  BFormCheckboxGroup,
  BFormRadioGroup,
} from "bootstrap-vue";
import CommonServices from "@/apiServices/CommonServices";
import SideBar from "./SideBar.vue";
import ListSideBar from "./ListSideBar.vue";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    MultiStateSwitch,
    BTab,
    BTabs,
    Card,
    BCard,
    SideBar,
    BFormInput,
    ListSideBar,
    BFormCheckboxGroup,
    BFormRadioGroup,
    BButton,
    BModal,
  },

  data() {
    return {
      trainings: [],
      training_id: null,
      search: "",
      activeTab: "aliff",
      debounceTimeout: null,
      tr_all_users: [],
      selected: "",
      options: [
        { text: "Yes", value: "Y" },
        { text: "No", value: "N" },
        { text: "Maybe", value: "M" },
      ],
    };
  },

  methods: {
    async getTrainings() {
      try {
        const payload = { tr_type: this.activeTab, search: this.search };
        const res = await CommonServices.getAliffTrainings(payload);
        if (res.data.status) {
          this.trainings = res.data.data;
        }
      } catch (error) {
        console.error("Error in getTrainings", error);
      }
    },
    onTabChange(type) {
      this.activeTab = type;
      this.getTrainings();
    },
    onSearch() {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
      this.debounceTimeout = setTimeout(() => {
        this.getTrainings();
      }, 1000);
    },
    async handleOpenSidebar(trainingId) {
      try {
        const res = await CommonServices.getTrainingById({ tr_id: trainingId });
        if (res.data.status) {
          const trainingData = res.data.data;
          this.$refs.sidebar.openSidebar(trainingData);
        }
      } catch (error) {
        console.error("Error in handleOpenSidebar ", error);
      }
    },
    async handleOpenListSidebar(regList) {
      this.$refs.list_sidebar.openListSidebar(regList);
    },
    handleRefresh() {
      this.getTrainings();
    },
    handleOpenRegisterModal(training_id, option) {
      this.training_id = training_id;
      this.register(option);
    },

    async register(option) {
      try {
        const res = await CommonServices.registerForEvent({
          tr_id: this.training_id,
          confirm: option,
        });
        if (res.data.status) {
          // console.log(res);
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Registered for Training Successfully",
              icon: "Check",
              variant: "success",
            },
          });
          // this.$emit("refresh");
          this.getTrainings();
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Already Registered for Training",
            icon: "Check",
            variant: "danger",
          },
        });
        console.error(error);
      }
    },
  },

  beforeMount() {
    this.getTrainings();
  },
};
</script>

<style scoped>
.card_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 28px;
  width: 100%;
}
.tab_bg {
  background-color: #937aee !important;
}
</style>
