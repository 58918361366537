<template>
  <div>
    <!-- Table Modal -->
    <b-modal
      id="product-modal"
      :title="product.sp_name + ' Details'"
      v-if="product"
      hide-footer
      @hide="resetProduct"
    >
      <b-table
        :items="product.quantity_amount_map"
        :fields="tableFields"
        responsive="sm"
        @row-clicked="onRowClick"
      >
        <template v-slot:cell(checkbox)="data">
          <b-form-checkbox
            v-model="order_details.spqa_id"
            :value="data.item.spqa_id"
          ></b-form-checkbox>
        </template>
        <template v-slot:cell(quantity)="data">
          {{ data.item.spqa_quantity }} Pieces
        </template>
        <template v-slot:cell(amount)="data">
          {{ getSymbolFromCurrency("INR") }} {{ data.item.spqa_amount }}
        </template>
      </b-table>

      <b-button
        variant="primary"
        class="px-2 mx-auto"
        v-if="order_details.spqa_id"
        @click="addToCart"
      >
        Add To Cart
      </b-button>
    </b-modal>

    <b-card no-body class="mb-1 p-1">
      <div class="d-flex" style="gap: 5px">
        <b-form-input
          placeholder="Find A Product"
          v-model="filters.search"
          @input="onSearch"
        />
        <b-button
          variant="primary"
          style="min-width: fit-content"
          @click="$router.push({ name: 'Shop Orders' })"
        >
          Orders &nbsp;<feather-icon icon="ClockIcon" />
        </b-button>
        <b-button
          variant="success"
          style="min-width: fit-content"
          @click="$router.push({ name: 'Shop Cart' })"
        >
          Cart
          <b-badge v-if="cartCount" variant="dark">{{ cartCount }}</b-badge>
        </b-button>
      </div>
    </b-card>

    <!-- <b-row>
      <b-col
        cols="4"
        v-for="product in products"
        :key="'product_' + product.sp_id"
        class="mb-2"
      >
        <b-card class="product-card">
          <div class="product-img-container">
            <b-carousel id="carousel-fade" fade indicators background="#ababab">
              <b-carousel-slide
                v-for="image in product.images"
                :key="image.spi_id"
              >
                <img
                  :src="FILESURL + image.spi_image"
                  alt=""
                  class="product-img rounded-sm"
                />
              </b-carousel-slide>
            </b-carousel>
          </div>
          <b-row>
            <b-col cols="8">
              <h2 class="mt-2 mb-1 text-truncate">{{ product.sp_name }}</h2>
            </b-col>
            <b-col cols="4">
              <div
                class="
                  mt-2
                  mb-1
                  d-flex
                  justify-content-between
                  w-100
                  align-items-end
                "
              >
                <h3 class="text-center mt-2">
                  {{ getSymbolFromCurrency(product.sp_currency) }}
                  {{ product.sp_amount }}
                </h3>
                <b-button
                  variant="primary"
                  class="px-2"
                  @click="() => openProductModal(product.sp_id)"
                >
                  Add To Cart
                </b-button>
              </div>
            </b-col>
            <b-col cols="12 mt-1">
              <div>
                <small class="small-dots" v-html="product.sp_description" />
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row> -->

    <div class="row">
      <div
        class="col-sm-6 col-12 col-xl-4 mb-4"
        v-for="(product, index) in products"
        :key="'product_' + product.sp_id"
      >
        <div class="product-card p-2">
          <div class="product-img-container">
            <b-carousel id="carousel-fade" fade indicators background="#ababab">
              <b-carousel-slide
                v-for="image in product.images"
                :key="image.spi_id"
                class="slide"
              >
                <div class="image">
                  <img
                    :src="FILESURL + image.spi_image"
                    alt=""
                    class="product-img rounded-sm"
                  />
                </div>
              </b-carousel-slide>
            </b-carousel>
          </div>

          <!-- <div class="row">
            <div class="col-8">
              <h2 class="mt-2 mb-1 text-truncate">{{ product.sp_name }}</h2>
            </div>
            <div class="col-4">
              <div class="mt-2 mb-1 d-flex justify-content-between w-100">
                <h3 class="text-center mt-2">
                   {{ getSymbolFromCurrency(product.sp_currency) }}
                  {{ product.sp_amount }} 
                  $ 1000
                </h3>
                <button
                  class="btn btn-primary"
                  @click="() => openProductModal(product.sp_id)"
                >
                  <p class="addtocart">Add To Cart</p>
                </button>
              </div>
            </div>
            
              <div class="px-2">
                <small class="small-dots" v-html="product.sp_description" style="font-size: 30px; text-align: start;" />
              </div>
          
          </div> -->

          <div>
            <div class="row">
              <div class="col-12">
                <h2 class="mt-2 mb-1 text-truncate">{{ product.sp_name }}</h2>
              </div>
            </div>
            <div class="row">
              <div class="col-12" style="height: 30px; ">
                <small class="small-dots" v-html="product.sp_description" style="font-size: 30px; text-align: start;" />
              </div>
            </div>

            <div class="row mt-2">
                <div class="col-6">
                    <h3 class="text-start mt-2">
                   {{ getSymbolFromCurrency(product.sp_currency) }}
                  {{ product.sp_amount }} 
                </h3>
                </div>
                <div class="col-6 d-flex justify-content-end">
                    <button
                  class="btn btn-primary"
                  @click="() => openProductModal(product.sp_id)"
                >
                  <p class="addtocart">Add To Cart</p>
                </button>
                </div>
            </div>
          </div>



        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BRow,
  BButton,
  BFormInput,
  BModal,
  BBadge,
  BCarousel,
  BCarouselSlide,
  BTable,
  BFormCheckbox,
} from "bootstrap-vue";
import AgentServices from "@/apiServices/AgentServices";
import getSymbolFromCurrency from "currency-symbol-map";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";
import { BASEURL, FILESURL } from "@/config/index";
import CommonServices from "@/apiServices/CommonServices";

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BBadge,
    BButton,
    BFormInput,
    BModal,
    ToastificationContentVue,
    BCarousel,
    BCarouselSlide,
    BTable,
    BFormCheckbox,
  },
  data() {
    return {
      BASEURL,
      FILESURL,
      products: [],
      filters: {
        search: "",
      },
      debounce: null,
      cartCount: null,
      order_details: {
        sp_id: null,
        spqa_id: null,
        so_currency: "INR",
        so_billing_address_id: null,
        so_shipping_address_id: null,
        so_quantity_amount: 0,
        so_total_amount: 0,
      },
      tableFields: [
        { key: "checkbox", label: "Select" },
        { key: "quantity", label: "Quantity" },
        { key: "amount", label: "Amount" },
      ],
      product: null,
      sp_id: null,
    };
  },
  methods: {
    shortenText(text) {
      return text.substring(100) + "...";
    },

    async getAllProducts() {
      try {
        const res = await AgentServices.getAllProducts(this.filters);
        if (res.data.status) {
          this.products = res.data.data;
        } else {
          console.error("Could Not Get All Products");
        }
      } catch (error) {
        console.error(error, "Could Not Get All Products");
      }
    },
    async openProductModal(sp_id) {
      this.sp_id = sp_id;
      await this.getSingleProduct();
      this.$bvModal.show("product-modal");
    },
    async getSingleProduct() {
      try {
        const res = await AgentServices.getSingleProduct(this.sp_id);
        if (res.data.status) {
          this.product = res.data.data;
        }
      } catch (error) {
        console.error("Error in GetSingleProduct", error);
      }
    },
    resetProduct() {
      this.product = null;
      this.sp_id = null;
    },
    async addToCart(product) {
      const payload = {
        sp_id: this.sp_id,
        spqa_id: this.order_details.spqa_id,
      };
      const res = await CommonServices.saveCartItem(payload);
      if (res.data.status) {
        this.getCartItems();
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: res.data.message || "Item added to cart",
            icon: "EditIcon",
            variant: "success",
          },
        });
      }
    },
    async getCartItems() {
      const res = await CommonServices.getCartItems();
      if (res.data.status) {
        this.cartCount = res.data.data.count;
      }
    },
    onChange() {
      this.$nextTick(() => {
        this.getAllProducts();
      });
    },
    onSearch() {
      if (this.debounce) {
        this.debounce = null;
      }
      this.debounce = setTimeout(() => {
        this.onChange();
      }, 1000);
    },
    getSymbolFromCurrency,
  },
  beforeMount() {
    this.getAllProducts();
    this.getCartItems();
  },
};
</script>

<style>
.product-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* height: 100%; */
  overflow: hidden;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* background-color: #7a1212; */
}

.product-img-container {
  /* flex: 1;
  overflow: hidden;
  position: relative; */
  height: auto;
  width: 100%;
}

.product-img {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
  display: block;
}

/* .carousel-inner {
  height: 250px;
} */

.carousel-item {
  height: 300px;
  /* Adjust to your preferred height */
  overflow: hidden;
}

.product-card h2,
.product-card h3 {
  margin: 0;
}

.product-card h2 {
  font-size: 1.25rem;
}

.product-card h3 {
  font-size: 1.5rem;
}

.carousel-caption {
  position: relative;
  right: 0 !important;
  left: 0 !important;
}

.small-dots p {
  /* width: 100%;
    overflow: hidden;
    text-overflow: ellipsis; */
  /* white-space: nowrap; */
  /* height: 40px; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* Limits the text to 2 lines */
  overflow: hidden;
  /* Hides the overflowed text */
  text-overflow: ellipsis;
  /* Adds the ellipsis (...) */
  line-height: 1.5em;
  /* Adjust line height as needed */
  max-height: 3em;
  /* Limit the height to fit exactly 2 lines */
}

.image {
  height: 300px;
}

p {
  font-size: clamp(0.8rem, 1vw, 1rem);
}
</style>
