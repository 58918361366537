<template>
  <b-card class="h-100" style="min-height: 80vh">
    <b-tabs card>
      <b-tab title="Timeline" active>
        <timeline-basic
          :applicationTimeline="formatted_timeline"
          v-if="!isLoading"
        />
        <div v-if="isLoading" class="d-flex justify-content-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </b-tab>
      <b-tab title="Details">
        <div class="d-flex justify-content-end badge">
          <b-badge style="font-size: 1.5rem" variant="primary">{{
            leadHistory.length + 1
          }}</b-badge>
        </div>
        <b-row v-if="!isLoading && lead_details" style="gap: 25px 0">
          <b-col cols="12 mb-2">
            <h1>Lead Id - {{ lead_details.lead_id }}</h1>
          </b-col>
          <b-col cols="4">
            <div>
              <p>Name</p>
              <h4>
                {{ lead_details.full_name }}
              </h4>
            </div>
          </b-col>

          <b-col cols="4">
            <div>
              <p>Email</p>
              <h4>{{ lead_details.email }}</h4>
            </div>
          </b-col>
          <b-col cols="4">
            <div>
              <p>Mobile</p>
              <h4>{{ lead_details.mobile }}</h4>
            </div>
          </b-col>
          <b-col cols="4">
            <div>
              <p>State</p>
              <h4>{{ lead_details.state ? lead_details.state.name : "-" }}</h4>
            </div>
          </b-col>
          <b-col cols="4">
            <div>
              <p>City</p>
              <h4>{{ lead_details.city ? lead_details.city.name : "-" }}</h4>
            </div>
          </b-col>
          <b-col cols="4">
            <div>
              <p>Source</p>
              <h4>
                {{
                  getSlashSepearatedValues(
                    lead_details.source ? lead_details.source.name : null,
                    lead_details.medium ? lead_details.medium.name : null,
                    lead_details.campaign ? lead_details.campaign.name : null
                  )
                }}
              </h4>
            </div>
          </b-col>
          <b-col cols="4">
            <div>
              <p>Stage</p>
              <h4>
                {{ lead_details.stage ? lead_details.stage.name : "Untouched" }}
              </h4>
            </div>
          </b-col>
          <b-col cols="4">
            <div>
              <p>Sub Stage</p>
              <h4>
                {{ lead_details.sub_stage ? lead_details.sub_stage.name : "-" }}
              </h4>
            </div>
          </b-col>
          <b-col cols="4">
            <div>
              <p>Follow Up Date</p>
              <h4 v-if="lead_details.follow_up_date">
                {{ moment(lead_details.follow_up_date).format("DD MMM, YYYY") }}
                at
                {{ moment(lead_details.follow_up_date).format("hh:mm A") }}
              </h4>
              <h4 v-else>-</h4>
            </div>
          </b-col>
          <b-col cols="4">
            <div>
              <p>Remarks</p>
              <h4>{{ lead_details.remark || "-" }}</h4>
            </div>
          </b-col>
          <b-col cols="4">
            <div>
              <p>Registration Date</p>
              <h4>
                {{ moment(lead_details.created_at).format("DD MMM, YYYY") }}
                at
                {{ moment(lead_details.created_at).format("HH:mm A") }}
              </h4>
            </div>
          </b-col>
          <b-col cols="4">
            <div>
              <p>Assignee</p>
              <h4 v-if="lead_details.user">
                {{ lead_details.user.user_first_name }}
                {{ lead_details.user.user_last_name }}
              </h4>
              <h4 v-else>-</h4>
            </div>
          </b-col>

          <b-col cols="4">
            <div>
              <p>Category</p>
              <h4>
                {{ lead_details.category ? lead_details.category.name : "-" }}
              </h4>
            </div>
          </b-col>

          <b-col
            cols="4"
            v-show="category_fields.length > 0 && lead_details.category_id"
            v-for="field in category_fields.filter(
              (lcf) => lcf.lc_id === lead_details.category_id
            )"
            :key="'lcf_' + field.lcf_id"
          >
            <div>
              <p>{{ field.name }}</p>
              <h4>{{ getFieldValue(field) }}</h4>
            </div>
          </b-col>
        </b-row>
        <div v-if="isLoading" class="d-flex justify-content-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
        <div class="mt-2 d-flex justify-content-end">
          <b-button
            variant="primary"
            @click="showHistory = !showHistory"
            v-if="leadHistory && leadHistory.length"
          >
            {{ showHistory ? "Hide History" : "Show History" }}
          </b-button>
        </div>

        <!-- History  -->
        <b-card
          v-for="lead_details in leadHistory"
          :key="lead_details.lh_id"
          v-if="showHistory"
        >
          <b-row style="gap: 25px 0">
            <b-col cols="12 mb-2">
              <h1>Lead Id - {{ lead_details.lead_id }}</h1>
            </b-col>
            <b-col cols="4">
              <div>
                <p>Name</p>
                <h4>
                  {{ lead_details.full_name }}
                </h4>
              </div>
            </b-col>

            <b-col cols="4">
              <div>
                <p>Email</p>
                <h4>{{ lead_details.email }}</h4>
              </div>
            </b-col>
            <b-col cols="4">
              <div>
                <p>Mobile</p>
                <h4>{{ lead_details.mobile }}</h4>
              </div>
            </b-col>
            <b-col cols="4">
              <div>
                <p>State</p>
                <h4>
                  {{ lead_details.state ? lead_details.state.name : "-" }}
                </h4>
              </div>
            </b-col>
            <b-col cols="4">
              <div>
                <p>City</p>
                <h4>{{ lead_details.city ? lead_details.city.name : "-" }}</h4>
              </div>
            </b-col>
            <b-col cols="4">
              <div>
                <p>Source</p>
                <h4>
                  {{
                    getSlashSepearatedValues(
                      lead_details.source ? lead_details.source.name : null,
                      lead_details.medium ? lead_details.medium.name : null,
                      lead_details.campaign ? lead_details.campaign.name : null
                    )
                  }}
                </h4>
              </div>
            </b-col>
            <b-col cols="4">
              <div>
                <p>Stage</p>
                <h4>
                  {{
                    lead_details.stage ? lead_details.stage.name : "Untouched"
                  }}
                </h4>
              </div>
            </b-col>
            <b-col cols="4">
              <div>
                <p>Sub Stage</p>
                <h4>
                  {{
                    lead_details.sub_stage ? lead_details.sub_stage.name : "-"
                  }}
                </h4>
              </div>
            </b-col>
            <b-col cols="4">
              <div>
                <p>Follow Up Date</p>
                <h4 v-if="lead_details.follow_up_date">
                  {{
                    moment(lead_details.follow_up_date).format("DD MMM, YYYY")
                  }}
                  at
                  {{ moment(lead_details.follow_up_date).format("hh:mm A") }}
                </h4>
                <h4 v-else>-</h4>
              </div>
            </b-col>
            <b-col cols="4">
              <div>
                <p>Remarks</p>
                <h4>{{ lead_details.remark || "-" }}</h4>
              </div>
            </b-col>
            <b-col cols="4">
              <div>
                <p>Registration Date</p>
                <h4>
                  {{ moment(lead_details.created_at).format("DD MMM, YYYY") }}
                  at
                  {{ moment(lead_details.created_at).format("HH:mm A") }}
                </h4>
              </div>
            </b-col>
            <b-col cols="4">
              <div>
                <p>Assignee</p>
                <h4 v-if="lead_details.user">
                  {{ lead_details.user.user_first_name }}
                  {{ lead_details.user.user_last_name }}
                </h4>
                <h4 v-else>-</h4>
              </div>
            </b-col>

            <b-col cols="4">
              <div>
                <p>Category</p>
                <h4>
                  {{ lead_details.category ? lead_details.category.name : "-" }}
                </h4>
              </div>
            </b-col>

            <b-col
              cols="4"
              v-show="category_fields.length > 0 && lead_details.category_id"
              v-for="field in category_fields.filter(
                (lcf) => lcf.lc_id === lead_details.category_id
              )"
              :key="'lcf_' + field.lcf_id"
            >
              <div>
                <p>{{ field.name }}</p>
                <h4>{{ getFieldValue(field) }}</h4>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BCard,
  BTabs,
  BTab,
  BCardText,
  BRow,
  BCol,
  BSpinner,
  BButton,
  BBadge,
} from "bootstrap-vue";
import CommonServices from "@/apiServices/CommonServices";
import LmsServices from "@/apiServices/LmsServices";
import moment from "@/utils/moment";
import TimelineBasic from "../StudentApplications/timeline/LeadTimeline.vue";

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    BCardText,
    BRow,
    BCol,
    TimelineBasic,
    BSpinner,
    BButton,
    BBadge,
  },
  data() {
    return {
      lead_id: null,
      access_token: null,
      lms_api: null,

      isLoading: false,

      lead_details: null,
      category_fields: [],
      follow_ups: [],
      assignee_timeline: [],
      formatted_timeline: [],
      leadHistory: [],
      showHistory: false,
    };
  },
  watch: {
    // whenever question changes, this function will run
    lead_details(newLeadDetails, OldLeadDetails) {
      this.getFormattedTimeLineDetails();
    },

    // assignee_timeline(newLeadDetails, OldLeadDetails) {
    //   this.getFormattedTimeLineDetails();
    // },

    // follow_ups(newLeadDetails, OldLeadDetails) {
    //   this.getFormattedTimeLineDetails();
    // },

    field_values(newLeadDetails, OldLeadDetails) {
      this.getFormattedTimeLineDetails();
    },

    category_fields(newLeadDetails, OldLeadDetails) {
      this.getFormattedTimeLineDetails();
    },
  },
  methods: {
    async getLMSAccess() {
      try {
        this.isLoading = true;
        const res = await CommonServices.getLMSAccessToken();
        this.access_token = res.data.data;
        this.lms_api = LmsServices(this.access_token);

        await this.getSingleLead();
        await this.getAllFollowUps();
        await this.getAssigneeTimeline();
        await this.getAllCategoryFields();
        await this.getHistory();

        this.isLoading = false;
      } catch (error) {
        console.log({ error });
      }
    },

    async getSingleLead() {
      try {
        this.isLoading = true;

        const res = await this.lms_api.getSingleLead(this.lead_id);
        this.lead_details = res.data.data;

        this.isLoading = false;
      } catch (error) {
        console.log({ error });
      }
    },

    async getAllFollowUps() {
      try {
        const res = await this.lms_api.getAllFollowUps(this.lead_id);

        console.log({ res: res.data.data });

        this.follow_ups = res.data.data;
      } catch (error) {
        console.log({ error });
      }
    },

    async getAssigneeTimeline() {
      try {
        const res = await this.lms_api.getAssigneeTimeline(this.lead_id);
        console.log({ res: res.data.data });

        this.assignee_timeline = res.data.data;
      } catch (error) {
        console.log({ error });
      }
    },

    async getAllCategoryFields() {
      try {
        const res = await this.lms_api.getAllCategoryFields();
        this.category_fields = res.data.data;
      } catch (error) {
        console.log("Could Not Get Categories");
      }
    },

    // Helpers
    getSlashSepearatedValues(...values) {
      let arr = [];
      for (let value of values) {
        if (value) {
          arr.push(value);
        }
      }

      return arr.join(" / ");
    },

    getFieldValue(field) {
      const field_value = this.lead_details.field_values.find(
        (lcfv) => lcfv.lcf_id === field.lcf_id
      );

      if (field_value) {
        return field_value.value ? field_value.value : "-";
      }

      return "-";
    },

    getFormattedTimeLineDetails() {
      if (!this.lead_details) return;

      let leadCreatedAt = {
        created_at: this.lead_details.created_at || "-",
        status_name: `<h4 class="mb-0 pb-0">Lead Generated From</h4>`,
        description: `${
          this.getSlashSepearatedValues(
            this.lead_details.source?.name || null,
            this.lead_details.medium?.name || null,
            this.lead_details.campaign?.name || null
          ) || "-"
        }`,
      };

      let normalFollowUps = this.follow_ups.map((fu) => ({
        created_at: fu.created_at,
        status_name: `<h4 class="mb-0 pb-0">${fu.user.user_first_name} ${fu.user.user_last_name}</h4>`,
        description: this.getChangesText(fu),
      }));

      let normalAssigneTimeline = this.assignee_timeline.map((at) => ({
        created_at: at.created_at,
        description: `
        <small style='color: #999'>${moment(at.created_at).format(
          "DD MMM, YYYY"
        )} at ${moment(at.created_at).format("hh:mm A")}</small><br />
        <b>${at.by_user.user_first_name} ${
          at.by_user.user_last_name
        }</b> has assigned this lead to <b>${at.to_user.user_first_name} ${
          at.to_user.user_last_name
        }</b>.
        `,
        status_name: `<h4 class="mb-0 pb-0">Lead Reassigned</h4>`,
      }));

      console.log({ normalAssigneTimeline });

      const completeTimeline = [
        ...normalFollowUps,
        ...normalAssigneTimeline,
      ].sort((a, b) => moment(a.created_at).diff(moment(b.created_at)));

      this.formatted_timeline = [leadCreatedAt, ...completeTimeline];
    },

    getChangesText(follow_up) {
      let text = `<small style='color: #999'>${moment(
        follow_up.created_at
      ).format("DD MMM, YYYY")} at ${moment(follow_up.created_at).format(
        "hh:mm A"
      )}</small><br />`;

      if (follow_up.stage) {
        text += `Changed Current Stage to <b>"${follow_up.stage.name}"</b> <br />`;
      }
      if (follow_up.sub_stage) {
        text += `Changed Current Sub Stage to <b>"${follow_up.sub_stage.name}"</b> <br />`;
      }
      if (follow_up.remark) {
        text += `Added Remark <b>"${follow_up.remark}"</b> <br />`;
      }
      if (follow_up.date) {
        text += `Next Follow Up on <b>${moment(follow_up.date).format(
          "DD MMM, YYYY"
        )} at ${moment(follow_up.date).format("hh:mm A")}</b> <br />`;
      }

      return text;
    },

    async getHistory() {
      try {
        const res = await this.lms_api.getLeadHistory(this.lead_id);
        // console.log("History", res);
        this.leadHistory = res.data.data;
      } catch (error) {
        console.error(error);
      }
    },

    moment,
  },
  beforeMount() {
    if (this.$route.params.lead_id) {
      this.lead_id = this.$route.params.lead_id;
      this.getLMSAccess();
    } else {
      console.log("Could Not Get Lead ID");
    }
  },
};
</script>
<style scoped>
.badge {
  position: fixed;
  right: 40px;
}
</style>
