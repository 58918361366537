<template>
    <div>
        <div>
            <h4 class="dashboard__heading">Education Loan</h4>
        </div>
        <b-card>
            <div class="text-center mb-3">
                <b-button 
                    variant="primary" 
                    @click="markAsInterested" 
                    :disabled="enquired"
                >
                    {{ enquired ? 'Enquired' : 'Enquire Now' }}
                </b-button>
            </div>
            <div class="loan-banks-grid">
                <div 
                    v-for="(loan, index) in loanBanks" 
                    :key="loan.el_id" 
                    class="loan-bank-card"
                >
                    <b-form-checkbox 
                    v-if="loan.enquiry_exists"
                    disabled="true"
                        v-model="loan.selected" 
                        :id="'checkbox-' + index"
                        @change="handleCheckboxChange(loan.el_id, loan.selected)"
                    >
                    </b-form-checkbox>
                    <b-form-checkbox 
                    v-else
                        v-model="loan.selected" 
                        :id="'checkbox-' + index"
                        @change="handleCheckboxChange(loan.el_id, loan.selected)"
                    >
                    </b-form-checkbox>
                    <img 
                        :src="FILESURL + loan.el_logo" 
                        alt="Logo" 
                        class="img-fluid loan-logo" 
                    />
                    <h5 class="loan-bank-name">{{ loan.el_name }}</h5>
                </div>
            </div>

            

        </b-card>
    </div>
</template>

<script>
import StudentServices from "@/apiServices/StudentServices";
import { FILESURL } from "@/config/index";
import { BCard, BButton, BFormCheckbox, BCarousel, BCarouselSlide } from "bootstrap-vue";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";

export default {
    components: {
        BCard,
        BButton,
        BFormCheckbox,
       
    },
    name: "DemoTestTabs",
    data() {
        return {
            FILESURL,
            loanBanks: [],
            selectedLoanIds: [], 
        };
    },
    computed: {
        enquired() {
            return this.loanBanks.every(loan => loan.enquiry_exists);
        },
    },
    methods: {
        async getAllLoanBanks() {
            try {
                const res = await StudentServices.getAllLoanBanks({
                    ...this.filters,
                });
                this.loanBanks = res.data.data.data.map((bank) => ({
                    ...bank,
                    selected: false, 
                }));
                this.totalData = res.data.data.total;
            } catch (error) {
                console.error(`Error in getStatusWiseStudents `, error);
            }
        },
        handleCheckboxChange(el_id, selected) {
            if (selected) {
                this.selectedLoanIds.push(el_id);
            } else {
                this.selectedLoanIds = this.selectedLoanIds.filter(id => id !== el_id);
            }
        },
        async markAsInterested() {
            try {
                const res = await StudentServices.studentInterestedForLoan({
                    loanIds: this.selectedLoanIds, 
                });
                if (res.data.status) {
                    this.$toast({
                        component: ToastificationContentVue,
                        props: {
                            title: `Inquiry Sent`,
                            icon: "Check",
                            variant: "success",
                        },
                    });
                    this.getAllLoanBanks();
                }
            } catch (error) {
                console.error(`Error in markAsInterested `, error);
            }
        },
    },
    beforeMount() {
        this.getAllLoanBanks();
    },
};
</script>

<style scoped>
.loan-banks-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 20px;
    padding: 10px;
}

.loan-bank-card {
    text-align: center;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.loan-logo {
    max-width: 100px;
    max-height: 100px;
    margin-bottom: 10px;
}

.loan-bank-name {
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
}

.mb-3 {
    margin-bottom: 1rem;
}

.checkbox_container{
    position: absolute;
    top: 0;
    left: 0;
}

@media screen and (max-width: 768px) {
    .loan-banks-grid {
display: flex;
flex-wrap: nowrap;  
overflow-x: scroll;
}
.loan-bank-name {
    font-size: 11px;
    font-weight: 600;
    margin-top: 10px;
}


.card_content{
    flex-direction: column;
}

}

</style>
