<template>
  <b-card>
    <b-table hover :items="services" :fields="fields">
      <template #cell(show)="row">
        <feather-icon icon="PlusCircleIcon" size="20" v-if="!row.detailsShowing && row.item.features && row.item.features.length > 1" @click="row.toggleDetails" />
        <feather-icon icon="MinusCircleIcon" size="20" v-if="row.detailsShowing" @click="row.toggleDetails" />
      </template>
      <template #cell(final_delivery)="row">
  <span v-if="row.item.final_delivery">
    {{ moment(row.item.final_delivery).format("DD MMM, YYYY HH:mm:ss") }}
  </span>
  <flat-pickr
    v-model="row.item.final_delivery"
    v-else-if="['services'].includes(user_type)"
    class="form-control"
    placeholder="Select Date"
    @input="onSearch(row.item.plan_id)"
    :config="{
      dateFormat: 'Y-m-d H:i',
      enableTime: true,
      time_24hr: true,
      altInput: true,
      altFormat: 'd-m-Y H:i',
      allowInput: true,
      noCalendar: false,
    }"
  />
  <span v-else>
     -
  </span>
</template>



      <template #row-details="row">
        <div v-if="row.item.plan_id === 6 && row.item.content.length" class="pt-3 pb-5">
          <h4 class="my-1 ml-1 p-0" style="min-width: 500px">Content</h4>
          <div v-for="(c, index) in row.item.content" :key="'c_' + index">
            <div style="width: 270px;">
              <embed :src="generatePdfBlobUrl(c.sat_report_link)" width="100%" height="200px" type="application/pdf" />
              <br />
              <div class="d-flex justify-content-between">

                <span>
                  <a target="_blank" :href="generatePdfBlobUrl(c.sat_report_link)">open</a>
                </span>
                <span>
                  <a download="sat_report" :href="generatePdfBlobUrl(c.sat_report_link)">download</a>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="shadow-xl rounded-lg border" style="overflow: hidden">
          <div class="d-flex border-bottom bg-white">

            <h4 class="my-1 ml-1 p-0" style="min-width: 500px">Features</h4>
            <h4 class="my-1" style="min-width: 200px">Progress</h4>
            <!-- <h4 class="my-1 ml-2" style="min-width: 200px">Team</h4> -->
            <h4 class="mt-1" style="min-width: 200px">Completion Date</h4>
          </div>
          <div style="background-color: #fafafa">

            <div class="d-flex mt-1 border-bottom pb-1 ml-1" v-for="(feature, index) in row.item.features"
              :key="'feature_' + index">
              <span class="font-weight-bold" style="min-width: 500px">{{
                feature.feature_name
              }}</span>
              <b-form-checkbox :disabled="!feature.user_type_key || feature.user_type_key !== user_type
                " :checked="isFeatureCompleted(feature.feature_id)" @change="(val) => {
                  if (val) {
                    markFeatureCompleted(
                      row.item.service_id,
                      row.item.plan_id,
                      feature.feature_id
                    );
                  } else {
                    markFeatureCompleted(
                      row.item.service_id,
                      row.item.plan_id,
                      feature.feature_id,
                      true
                    );
                  }
                }
                  " />
              <div style="min-width: 200px">
                <b-badge class="ml-1 mr-5" :variant="isFeatureCompleted(feature.feature_id)
                  ? 'success'
                  : 'warning'
                  ">{{
                    isFeatureCompleted(feature.feature_id)
                      ? "Complete"
                      : "Pending"
                  }}</b-badge>
              </div>
              <div v-if="getCompletedFeatureDate(feature.feature_id)" class="rounded-lg shadow-sm"
                style="padding: 2px 8px">
                {{
                  moment(getCompletedFeatureDate(feature.feature_id)).format(
                    "DD MMM, YYYY HH:mm:ss"
                  )
                }}
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #cell(buy_date)="row">
        {{
          moment(row.item.transaction_datetime).format("DD MMM, YYYY - HH:mm")
        }}
      </template>
    </b-table>
  </b-card>
</template>
<script>
import BCardCode from "@core/components/b-card-code";
import {
  BCarousel,
  BCarouselSlide,
  BButtonGroup,
  BButton,
  BLink,
  BCardText,
  BTabs,
  BTab,
  BCard,
  BCardHeader,
  BCardBody,
  BBadge,
  BCollapse,
  BListGroup,
  BListGroupItem,
  BFormCheckbox,
  BCol,
  BRow,
  BTable,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import CommonServices from "@/apiServices/CommonServices";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";


export default {
  components: {
    BCardCode,
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BCarousel,
    BCarouselSlide,
    BButtonGroup,
    BButton,
    BCardText,
    BTabs,
    BLink,
    BTab,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    BRow,
    BCol,
    BTable,
    BBadge,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  computed: {
    user_type() {
      if (store.getters["user/getUserDetails"]?.user_type) {
        return store.getters["user/getUserDetails"].user_type;
      } else {
        return null;
      }
    },
  },

  data() {
    return {
      services: [],
      student_user_id:null,
      completed_features: [],
      date:'',
      fields: ["show", "service_name", "plan_name", "amount", "buy_date","final_delivery"],
      debounce:null
    };
  },
  methods: {
    generatePdfBlobUrl(binary) {
      const byteCharacters = atob(binary);
      const byteNumbers = Array.from(byteCharacters, (char) => char.charCodeAt(0));
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      return URL.createObjectURL(blob);
    },
    moment,
    isFeatureCompleted(feature_id) {
      let student_user_id = this.student_user_id;

      const check = this.completed_features.find(
        (feature) =>
          feature.student_user_id == student_user_id &&
          feature.feature_id == feature_id
      );

      if (check) return true;
      return false;
    },

    getCompletedFeatureDate(feature_id) {
      let student_user_id = this.student_user_id;

      const feature = this.completed_features.find(
        (feature) =>
          feature.student_user_id == student_user_id &&
          feature.feature_id == feature_id
      );

      if (feature) return feature.created_at;
      return null;
    },
    async getStudentServicesAvailed(student_user_id) {
      try {
        const response = await CommonServices.getStudentServicesAvailed({
          student_user_id,
        });
        if (response.data.status) {
          this.services = response.data.data.map(service => ({
            ...service,
          }));
        }
      } catch (err) {
        console.log("Error in getting syudent availed services ", err);
      }
    },

    onChange() {
      this.$nextTick(() => {
        this.getStudentServicesAvailed();
      });
    },
    onSearch(plan_id) {
  if (this.debounce) {
    clearTimeout(this.debounce);
    this.debounce = null;
  }
  this.debounce = setTimeout(() => {
    const row = this.services.find(service => service.plan_id === plan_id);
    if (row) {
      this.saveServiceFinalDelivery(plan_id, row.final_delivery);
    }
  }, 1000);
},

async saveServiceFinalDelivery(plan_id, finalDelivery) {
  const payload = {
    student_user_id: this.student_user_id,
    plan_id,
    final_delivery: finalDelivery,
  };

      const res = await CommonServices.saveServiceFinalDelivery(payload)
      if(res.data.status){
        this.getStudentServicesAvailed(this.student_user_id)
      }
    },
    
    

    async markFeatureCompleted(service_id, plan_id, feature_id, unmark) {
      try {
        const response = await CommonServices.markFeatureCompleted({
          student_user_id: this.student_user_id,
          service_id,
          plan_id,
          feature_id,
          unmark,
        });
        if (response.data.status) {
          this.getCompletedFeatures(this.student_user_id);
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Failed to update feature",
              icon: "EditIcon",
              variant: "success",
            },
          });
        }
      } catch (err) {
        console.log("Error in getting syudent availed services ", err);
      }
    },

    async getCompletedFeatures(student_user_id) {
      try {
        const response = await CommonServices.getCompletedFeatures({
          student_user_id,
        });
        if (response.data.status) {
          this.completed_features = response.data.data;
        }
      } catch (err) {
        console.log("Error in getCompletedFeatures", err);
      }
    },
  },

  beforeMount() {
    const { student_user_id } = this.$route.params;
    if (!isNaN(student_user_id)) {
      this.getStudentServicesAvailed(student_user_id);
      this.getCompletedFeatures(student_user_id);
      this.student_user_id = student_user_id;
    } else {
      console.log("Student User Id is is not a number ", student_user_id);
    }
  },
};
</script>

<style></style>
