<template>
    <div>
        <b-card no-body class="mb-1 p-1">
            <div class="d-flex" style="gap: 5px;">
                <b-form-input placeholder="Find Your Order" v-model="filters.search" @input="onSearch" />

            </div>
        </b-card>

        <b-card>
            <b-table :items="orders" :fields="fields" responsive>

                <template #cell(show_details)="row">
                    <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
                    <b-form-checkbox v-model="row.detailsShowing" @change="row.toggleDetails">
                    </b-form-checkbox>
                </template>

                <template #row-details="row">
                    <b-card class="w-50 border rounded-lg overflow-hidden" no-body>
                        <b-table :items="row.item.order_items" :fields="orderItemFields" responsive>
                            <template #cell(soi_amount)="data">
                                {{ getSymbolFromCurrency(data.item.soi_currency) }}
                                {{ data.item.soi_amount }}
                            </template>
                        </b-table>

                    </b-card>
                </template>

                <template #cell(so_id)="data">
                    #{{ data.item.so_id }}

                </template>

                <template #cell(so_is_paid)="data">
                    <b-badge :variant="data.item.so_is_paid === 'Y' ? 'success' : 'danger'">
                        {{ data.item.so_is_paid === 'Y' ? 'Paid' : 'Not Paid' }}
                    </b-badge>
                </template>
                <template #cell(so_is_fullfilled)="data">
                    <b-badge :variant="data.item.so_is_fullfilled === 'Y' ? 'success' : 'danger'">
                        {{ data.item.so_is_fullfilled === 'Y' ? 'Fulfilled' : 'Not Fulfilled' }}
                    </b-badge>
                </template>
                <template #cell(invoice)="data">

                    <b-button v-b-tooltip.hover class="bg-transparent" title="Invoice"
                        @click="invoiceClick(data.item.so_id)"><feather-icon icon="FileIcon" size="18" /></b-button>

                </template>
                <template #cell(created_at)="data">
                    <span class="text-nowrap">{{ moment(data.item.created_at).format('DD MMM, YYYY') }} at {{
                        moment(data.item.created_at).format('hh:mm:ss A') }}</span>
                </template>
                <template #cell(franchise_name)="data">
                    <span class="text-nowrap">{{ data.item.franchise_name }} <small>({{ data.item.franchise_level
                            }})</small></span>
                </template>
            </b-table>
        </b-card>

        <div class="mx-2 mb-2">
            <b-row class="align-items-center">
                <b-col cols="6" sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start">
                    <span class="text-muted">
                        <!-- Showing {{ dataMeta.from }} to {{ dataMeta.to }} of -->
                        {{ orderCount }} entries
                    </span>
                </b-col>
                <b-col cols="6" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                    <b-pagination v-model="pagination.currentPage" :total-rows="orderCount" @change="onChange"
                        :per-page="pagination.perPage" first-number last-number class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item" next-class="next-item">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import {
    BCard,
    BButton,
    BFormInput,
    BTable,
    BBadge,
    BPagination,
    BRow,
    BCol,
    BTooltip,
    BFormCheckbox,
} from "bootstrap-vue";
import AgentServices from "@/apiServices/AgentServices";
import moment from "moment";
import getSymbolFromCurrency from "currency-symbol-map";

export default {
    components: {
        BCard,
        BButton,
        BFormInput,
        BTable,
        BBadge,
        BPagination,
        BRow,
        BCol,
        BTooltip,
        BFormCheckbox
    },
    computed: {
        franchise_level() {
            return this.$store.getters["user/getUserDetails"].franchise_level;
        }
    },
    data() {
        return {
            orders: [],
            orderCount: 0,
            filters: {
                search: '',
                dateRange: null,
                product_id: null,
            },
            pagination: {
                currentPage: 1,
                perPage: 10,
                perPageOptions: [5, 10, 20, 30],
            },
            fields: [
                { key: 'show_details', label: "Details" },
                { key: 'so_id', label: 'Order ID' },
                { key: 'franchise_name', label: 'Franchise' },
                { key: 'so_currency', label: 'Currency' },
                // { key: 'so_addition_charges', label: 'Additional Charges' },
                { key: 'so_total_amount', label: 'Total Amount' },
                { key: 'so_is_paid', label: 'Payment Status' },
                { key: 'so_is_fullfilled', label: 'Order Fulfillment' },
                { key: 'created_at', label: 'Date' },
                { key: 'invoice', label: 'Actions' },
            ],
            orderItemFields: [
                { key: 'sp_name', label: "Product" },
                { key: 'soi_quantity', label: "Quantity" },
                { key: 'soi_amount', label: 'Amount' },
            ],
            debounce: null

        }
    },
    directives: {
        BTooltip
    },
    methods: {
        async getAllOrders() {
            try {
                const res = await AgentServices.getAllOrders({
                    currentPage: this.pagination.currentPage,
                    perPage: this.pagination.perPage,
                    ...this.filters
                });
                if (res.data.status) {
                    this.orders = res.data.data.data;
                    this.orderCount = res.data.data.count;
                } else {
                    console.error("Could Not Get All orders");
                }
            } catch (error) {
                console.error("Could Not Get All orders", error);
            }
        },
        addFranchiseTable() {
            if (this.franchise_level !== 'micro') {
                this.fields.splice(1, 0, { key: 'franchise_name', label: 'Franchise' })
            }
        },
        onChange() {
            this.$nextTick(() => {
                this.getAllOrders();
            });
        },
        onSearch() {
            if (this.debounce) {
                this.debounce = null;
            }
            this.debounce = setTimeout(() => {
                this.onChange();
            }, 1000);
        },
        invoiceClick(id) {
            console.log(id)
            this.$router.push({
                name: "Shop Order Details",
                params: { so_id: id },
            });
        },
        moment,
        getSymbolFromCurrency
    },
    beforeMount() {
        this.getAllOrders();
        this.addFranchiseTable()
    },
}
</script>
<style>
.bg-transparent {
    background-color: transparent !important;
    border: none !important;
}

.bg-transparent:hover {
    box-shadow: none !important;
}

.bg-transparent:focus {
    box-shadow: none !important;
    background-color: transparent !important;
    border: none !important;
}
</style>