<template>
  <b-card>
    <b-table hover :items="services" :fields="fields">
      <template #cell(show)="row">
        <feather-icon
          icon="PlusCircleIcon"
          size="20"
          v-if="!row.detailsShowing"
          @click="row.toggleDetails"
        />
        <feather-icon
          icon="MinusCircleIcon"
          size="20"
          v-if="row.detailsShowing"
          @click="row.toggleDetails"
        />
      </template>

      <template #row-details="row">
        <b-tabs v-model="row.item.active_tab_index">
          <b-tab title="Content">
            <b-card>
              <b-row style="gap: 20px 0" v-if="row.item.content && row.item.content.length > 0">
                <b-col v-for="(c, index) in row.item.content" :key="'c_' + index" cols="12" md="3">
                  <span>{{ c.content_name }}</span>
                  <br />
                  <div v-if="c.uploaded_file_link">
                    <embed :src="FILESURL + c.uploaded_file_link" width="100%" height="200px" />
                    <br />
                    <span>
                      <a target="_blank" :href="FILESURL + c.uploaded_file_link">open</a>
                    </span>
                  </div>

                  <div v-if="c.file_link">
                    <embed :src="c.file_link" width="100%" height="200px" />
                    <br />
                    <span>
                      <a target="_blank" :href="c.file_link">open</a>
                    </span>
                  </div>

                  <div v-if="c.youtube_link">
                    <div v-html="c.youtube_link"></div>
                  </div>

                  <div v-if="row.item.plan_id === 6 && c.sat_report_link" style="width: 270px;">
                    <embed
                    v-if="c.sat_report_link"
                      :src="generatePdfBlobUrl(c.sat_report_link)"
                      width="100%"
                      height="200px"
                      type="application/pdf"
                    />
                    <br />
                    <div class="d-flex justify-content-between">

                      <span>
                        <a v-if="c.sat_report_link" target="_blank" :href="generatePdfBlobUrl(c.sat_report_link)">open</a>
                      </span>
                      <span>
                        <a v-if="c.sat_report_link" download="sat_report" :href="generatePdfBlobUrl(c.sat_report_link)">download</a>
                      </span>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <div v-else>No Content Available.</div>
            </b-card>
          </b-tab>
          <b-tab title="Features">
            <div class="shadow-xl rounded-lg border" style="overflow: hidden">
              <div class="d-flex border-bottom bg-white">
                <h4 class="my-1 ml-1 p-0" style="min-width: 500px">Deliverables</h4>
                <h4 class="my-1" style="min-width: 200px">Progress</h4>
                <h4 class="mt-1" style="min-width: 200px">Completion Date</h4>
              </div>
              <div class="bg-fafafa overflow-hidden">
                <div
                  class="d-flex pt-1 border-bottom pb-1 ml-1"
                  v-for="(feature, index) in row.item.features"
                  :key="'feature_' + index"
                >
                  <span class="font-weight-bold" style="min-width: 500px">{{ feature.feature_name }}</span>
                  <div style="min-width: 200px">
                    <b-badge
                      class="ml-1 mr-5"
                      :variant="isFeatureCompleted(feature.feature_id) ? 'success' : 'warning'"
                    >
                      {{ isFeatureCompleted(feature.feature_id) ? "Complete" : "Pending" }}
                    </b-badge>
                  </div>
                  <div v-if="getCompletedFeatureDate(feature.feature_id)" class="rounded-lg shadow-sm" style="padding: 2px 8px">
                    {{
                      moment(getCompletedFeatureDate(feature.feature_id)).format("DD MMM, YYYY HH:mm:ss")
                    }}
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab title="Part Payments" v-if="row.item.partPayments && row.item.partPayments.length > 0">
            <div class="shadow-xl rounded-lg border" style="overflow: hidden">
              <div class="d-flex border-bottom bg-white">
                <h4 class="my-1 ml-1 p-0" style="min-width: 500px">Part</h4>
                <h4 class="my-1" style="min-width: 200px">Price</h4>
                <h4 class="mt-1" style="min-width: 200px">Status</h4>
                <h4 class="mt-1" style="min-width: 200px">Payment Date</h4>
              </div>
              <div class="bg-fafafa overflow-hidden">
                <div
                  class="d-flex pt-1 border-bottom pb-1 ml-1"
                  v-for="(part, index) in row.item.partPayments"
                  :key="'part_' + index"
                >
                  <span class="font-weight-bold" style="min-width: 500px">Part {{ index + 1 }}</span>
                  <div style="min-width: 200px">{{ part.spp_price }} {{ part.spp_currency }}</div>
                  <div style="min-width: 200px">
                    <b-badge :variant="part.is_paid === 'Y' ? 'success' : 'warning'">
                      {{ part.is_paid === 'Y' ? "Paid" : "Pending" }}
                    </b-badge>
                  </div>
                  <div v-if="part.is_paid === 'Y'" style="padding: 2px 8px">
                    {{ moment(part.updated_at).format("DD MMM, YYYY HH:mm:ss") }}
                  </div>
                  <div v-else-if="index === row.item.partPayments.filter(p => p.is_paid === 'Y').length">
                    <b-badge variant="primary">Pay Now</b-badge>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </template>

      <template #cell(buy_date)="row">
        {{ moment(row.item.buy_date).format("DD MMM, YYYY - HH:mm:ss") }}
      </template>

      <template #cell(amount)="row">
        {{
          row.item.partPayments.length > 0
            ? `${row.item.partPayments.filter(p => p.is_paid === 'Y').reduce((acc, p) => acc + p.spp_price, 0)}
            ${row.item.partPayments[0].spp_currency || ""}`
            : row.item.amount
        }}
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCardCode,
  BCard,
  BTable,
  BRow,
  BCol,
  BTab,
  BTabs,
  BBadge,
} from "bootstrap-vue";
import moment from "moment";
import { FILESURL } from "@/config";
import StudentServices from "@/apiServices/StudentServices";

export default {
  components: {
    BCardCode,
    BCard,
    BTable,
    BRow,
    BCol,
    BTab,
    BTabs,
    BBadge,
  },
  data() {
    return {
      services: [],
      completed_features: [],
      fields: ["show", "service_name", "plan_name", "amount", "buy_date"],
      FILESURL,
      sat_availed:false
    };
  },
  methods: {
    generatePdfBlobUrl(binary) {
      const byteCharacters = atob(binary);
      const byteNumbers = Array.from(byteCharacters, (char) => char.charCodeAt(0));
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      return URL.createObjectURL(blob);
    },
    isFeatureCompleted(feature_id) {
      return this.completed_features.some((feature) => feature.feature_id === feature_id);
    },
    getCompletedFeatureDate(feature_id) {
      const feature = this.completed_features.find((f) => f.feature_id === feature_id);
      return feature ? feature.created_at : null;
    },
    moment,
    async getServicesAvailed() {
      try {
        const response = await StudentServices.getServicesAvailed();
        if (response.data.status) {
          this.services = response.data.data.map((s) => ({
            ...s,
            active_tab_index: 0,
          }));
        }
      } catch (err) {
        console.error("Error in getting student availed services:", err);
      }
    },
    async getCompletedFeatures() {
      try {
        const response = await StudentServices.getCompletedFeatures();
        if (response.data.status) {
          this.completed_features = response.data.data;
        }
      } catch (err) {
        console.error("Error in fetching completed features:", err);
      }
    },
  },
  beforeMount() {
 this.getServicesAvailed();
 this.getCompletedFeatures();


    const params = new URLSearchParams(window.location.search);
  const servicesAvailed = params.get('services_availed') === 'true';

  if (servicesAvailed) {
    this.services.forEach((service) => {
      if (service.plan_id === 6) {
        service.detailsShowing = true; // Automatically show details for plan_id 6
      }
    });
  }
  },
};
</script>

<style scoped></style>
