<template>
  <b-tabs align="center" fill v-model="activeTabIndex">
    <b-tab>
      <template #title>
        <feather-icon icon="FileTextIcon" size="20" class="mr-0 mr-sm-50" />
        <span class="d-none d-sm-inline">Profile</span>
      </template>
      <student-profile />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="FolderIcon" size="20" class="mr-0 mr-sm-50" />
        <span class="d-none d-sm-inline">Documents</span>
      </template>
      <student-documents />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon
          icon="CornerDownRightIcon"
          size="20"
          class="mr-0 mr-sm-50"
        />
        <span class="d-none d-sm-inline">Applications</span>
      </template>
      <student-application class="mt-2 pt-75" />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="FolderIcon" size="20" class="mr-0 mr-sm-50" />
        <span class="d-none d-sm-inline">Staff Documents</span>
      </template>
      <student-staff-documents />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="ServerIcon" size="20" class="mr-0 mr-sm-50" />
        <span class="d-none d-sm-inline">Services Availed</span>
      </template>
      <student-services />
    </b-tab>
    <b-tab v-if="['franchise'].includes(user_type)">
      <template #title>
        <feather-icon
          icon="MessageCircleIcon"
          size="20"
          class="mr-0 mr-sm-50"
        />
        <span class="d-none d-sm-inline">Tasks</span>
      </template>
      <student-tasks />
    </b-tab>
    <b-tab v-if="['franchise'].includes(user_type)">
      <template #title>
        <feather-icon
          icon="MessageCircleIcon"
          size="20"
          class="mr-0 mr-sm-50"
        />
        <span class="d-none d-sm-inline">Chat</span>
      </template>

      <StudentCounselorChat />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import StudentApplication from "./StudentApplication.vue";
import StudentCounselorChat from "./StudentCounselorChat.vue";
import StudentProfile from "./StudentProfile.vue";
import StudentDocuments from "./StudentDocuments.vue";
import StudentServices from "./StudentServices.vue";
import StudentStaffDocuments from "./StudentStaffDocuments.vue";
import StudentTasks from "./StudentTasks.vue";
import store from "@/store";
import Chat from "../Chat/Chat.vue";
import ChatLog from "./ChatLog.vue";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    StudentProfile,
    StudentCounselorChat,
    StudentServices,
    StudentDocuments,
    StudentApplication,
    StudentStaffDocuments,
    StudentTasks,
    ChatLog,
  },
  computed: {
    user_type() {
      if (store.getters["user/getUserDetails"].user_type) {
        return store.getters["user/getUserDetails"].user_type;
      } else {
        return null;
      }
    },
  },
  data() {
    return {
      userData: {},
      activeTabIndex: 0,
    };
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const to = urlParams.get("to");
    this.$nextTick(() => {
      if (to === "applications") {
        this.activeTabIndex = 2;
      }
      if (to === "staffDocuments") {
        this.activeTabIndex = 3;
      }
    });
  },
};
</script>

<style scoped></style>
