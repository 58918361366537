<template>
  <div>
    <div>
      <h4 class="dashboard__heading">Free Resources</h4>
    </div>
    <b-card
      v-for="(service, index) in services"
      :key="index"
      :title="service.frs_name"
      class=""
    >
      <div>
        <b-tabs
          class="tabs-scroll"
          nav-class="d-flex flex-nowrap overflow-auto w-100"
        >
          <b-tab
            v-for="(material, index) in service.categories"
            :key="index"
            :title="material.frc_name"
            class=""
          >
            <div
              :class="`${material.frc_name.toLowerCase()}-content`"
              class="w-100"
            >
              <div
                class="d-flex media-box"
                
              >
                <div class="materials">
                  <div
                  v-for="(item, idx) in material.contents"
                  :key="idx"
                  class="material_contents"
                >
                  <div v-if="item.frcc_content" class="mb-2">
                    <div
                      v-if="
                        item.frcc_content.includes('.mp3') ||
                        item.frcc_content.includes('.wav')
                      "
                    >
                      <audio
                        :src="FILESURL + item.frcc_content"
                        controls
                      ></audio>
                    </div>
                    <div v-else>
                      <embed
                        :src="FILESURL + item.frcc_content"
                        width="100%"
                        height="200px"
                      />
                    </div>
                    <a :href="FILESURL + item.frcc_content" target="_blank"
                      >Open</a
                    >
                  </div>
                  <span>{{ item.frcc_name }}</span>
                </div>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BTabs, BTab, BCard } from "bootstrap-vue";
import StudentServices from "@/apiServices/StudentServices";
import { FILESURL } from "@/config/index";

export default {
  components: {
    BTabs,
    BTab,
    BCard,
  },
  name: "DemoTestTabs",
  data() {
    return {
      FILESURL,
      services: [],
    };
  },
  methods: {
    async getServices() {
      const res = await StudentServices.getAllFreeResources();
      this.services = res.data.data;
    },
  },
  beforeMount() {
    this.getServices();
  },
};
</script>
<style>
.materials{
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}
@media only screen and (max-width: 768px) {
  .materials {
    display: flex;
    overflow-x: auto;
    flex-wrap: nowrap;
    width: 100%;
    padding: 10px;
    scrollbar-width: thin;
    scrollbar-color: #5267ff #f5f7fa;
  }
  .materials::-webkit-scrollbar {
    height: 4px;
  }
  .materials::-webkit-scrollbar-track {
    background-color: #f5f7fa;
  }
  .materials::-webkit-scrollbar-thumb {
    background-color: #5267ff;
    border-radius: 10px;
  }
  .material_contents {
    flex: 0 0 auto;
    width: 100%;
  }
}
</style>


