<template>
  <b-card class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="bg-light-primary rounded-top text-center cursor-pointer">
          <b-img :src="require('@/assets/images/illustration/email.svg')" alt="Meeting Pic" height="170" />
        </div>
      </div>
      <div class="col-md-12">
        <b-card-body>
          <!-- meeting header -->
          <div class="d-flex align-items-start justify-content-center">
            <h4 class="mb-0">
              {{
                new Date(training.tr_date).toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "long",
                })
              }}
            </h4>
            <h4 class="mb-0 ml-1">
              {{
                new Date(training.tr_date)
                  .toLocaleDateString("en-US", {
                    weekday: "long",
                  })
                  .toUpperCase()
              }}
            </h4>
          </div>
          <b-card-title class="my-2 text-center">
            {{ training.tr_heading }}
          </b-card-title>
          <div class="meetup-header">
            <b-row>
              <b-col cols="12" md="6">
                <div class="my-auto w-100 ">
                  <b-card-text class="mb-0" v-html="training.tr_description"></b-card-text>
                </div>
              </b-col>
              <b-col cols="12" md="6">
                <div class="ml-5">
                  <b-media no-body class="mt-2">
                    <b-media-aside class="">
                      <b-avatar rounded variant="light-primary" size="40">
                        <feather-icon icon="CalendarIcon" size="30" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h4 class="mb-0">
                        {{
                          new Date(training.tr_date).toLocaleDateString("en-US", {
                            day: "numeric",
                            month: "short",
                            weekday: "short",
                          })
                        }}
                      </h4>
                      <h4>10:AM to 6:PM</h4>
                    </b-media-body>
                  </b-media>
                  <b-media no-body class="mt-2 ">
                    <b-media-aside class="mr-1">
                      <b-avatar rounded variant="light-primary" size="40">
                        <feather-icon icon="MapPinIcon" size="30" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h4 class="mb-0">Virtual</h4>
                      <a :href="training.tr_link" target="_blank">{{
                        training.tr_link
                        }}</a>
                    </b-media-body>
                  </b-media>
                </div>
              </b-col>
            </b-row>

          </div>
          <!--/ meeting header -->

          <!-- media -->


          <b-row class="mt-2">
            <b-col md="6">
              <h4 class="mb-2">Registered Users</h4>
              <div class="d-flex flex-wrap">
                <div v-for="student in training.register_list" :key="student.user_id"
                  class="d-flex align-items-center mr-1 mb-1">
                  <b-avatar :src="student.user_image ||
                    require('@/assets/images/portrait/small/avatar-s-9.jpg')
                    " class="pull-up" />
                  <span class="ml-1">{{ student.user_fullname }}</span>
                </div>
              </div>
            </b-col>
            <!-- <b-col md="6">
              <h4 class="mb-2">Maybe Users</h4>
              <div class="d-flex flex-wrap">
                <b-avatar
                  v-for="student in training.maybe_users"
                  :key="student.user_id"
                  v-b-tooltip.hover.bottom="student.user_fullname"
                  :src="
                    student.user_image ||
                    require('@/assets/images/portrait/small/avatar-s-9.jpg')
                  "
                  class="pull-up mr-1 mb-1"
                />
              </div>
            </b-col> -->
          </b-row>
        </b-card-body>
      </div>
    </div>
  </b-card>
</template>

<script>
import CommonServices from "@/apiServices/CommonServices";
import {
  BCard,
  BImg,
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar,
  BButton,
  BCardText,
  BCardTitle,
  BRow,
  BCol,
} from "bootstrap-vue";

export default {
  name: "TrainingDetails",
  components: {
    BCard,
    BImg,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BButton,
    BCardText,
    BCardTitle,
    BRow,
    BCol,
  },
  data() {
    return {
      training: {},
      user_type: "", // add user_type data property
      type: "", // add type data property if needed
    };
  },
  methods: {
    async getTraining(trainingId) {
      try {
        const res = await CommonServices.getTrainingById({ tr_id: trainingId });
        if (res.data.status) {
          this.training = res.data.data;
        }
      } catch (error) {
        console.error("Error in getTraining", error);
      }
    },
  },
  beforeMount() {
    const trainingId = this.$route.query.id;
    console.log(trainingId);
    this.getTraining(trainingId);
    // Initialize user_type and type here if needed
  },
};
</script>
