<template>
<div>
    <b-card
      style="min-height: 300px"
      title="Bank Accounts"
      v-if="['accounts', 'franchise'].includes(user_type)"
    >
      <div class="d-flex justify-content-end mt-2 add_account_button" v-if="banks && banks.length < 1">
        <b-button variant="primary" size="sm" v-b-modal.modal-center>
          Add Bank Account
        </b-button>
      </div>
      <div class="mt-2">
        <div v-for="(bank, index) in banks" :key="index" class="px-3">
         <b-card  class="bank_account" border-variant="primary" >
          <template #header>
                  <div class="d-flex justify-content-between w-100">
                    <h4>{{ bank.ba_name }}</h4>
                    <b-button variant="success" size="sm" v-if="bank.is_approved == 'Y'">
                      Approved
                    </b-button>
                    <b-button variant="danger" size="sm" v-else-if="bank.is_disapproved == 'Y'" >Disapproved </b-button>
                    <b-button variant="warning" size="sm" v-else>Approval Pending </b-button>
                  </div>
                </template>

            <div class="bank_card_body">
                <b-row>
                  <b-col sm="12">
                    <b-card-text>
                      <b>Beneficiary Name:</b> {{ bank.ba_benificiary_name }}
                    </b-card-text>
                    <b-card-text>
                      <b>Bank Swift/Routing Number:</b> {{ bank.ba_routing_number }}
                    </b-card-text>
                    <b-card-text>
                      <b>Bank Address:</b> {{ bank.ba_address }}
                    </b-card-text>
                    <b-card-text>
                      <b>Bank Name:</b> {{ bank.ba_name }}
                    </b-card-text>
                    <b-card-text>
                      <b>Account Number:</b> {{ bank.ba_number }}
                    </b-card-text>
                  </b-col>
                </b-row>
              <div class="edit">
                <feather-icon
                icon="EditIcon"
                @click="editBank(bank.ba_id)"
                size="25" v-if = "bank.is_approved === 'Y' || bank.is_disapproved === 'Y'"/>
              </div>
              <div class="d-flex justify-content-start mt-2" v-if="bank.remarks && bank.remarks.length > 0">
                <b class="mr-1 text-danger">Remarks: </b>{{ bank.remarks }}
              </div>
            </div>

         </b-card>
        </div>
      </div>
      <div>
        <div v-if="!banks.length" class="text-center">
          <h4>No Bank Accounts Available</h4>
        </div>
      </div>


    </b-card>


     <!-- Add Account Modal  -->
     <b-modal
      id="modal-center"
      centered
      title="Add Bank Account"
      hide-footer
      size="lg"
      ref="add-bank-modal-ref"
    >
      <b-form>
        <b-row>
          <b-col sm="6">
            <b-form-group label="Beneficiary Name" label-for="beneficiary-name">
              <b-form-input
                v-model="bank_data.ba_benificiary_name"
                placeholder="Beneficiary Name"
                name="beneficiary-name"
                id="beneficiary-name"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Bank Swift/Routing Number"
              label-for="bank-swift-routing"
            >
              <b-form-input
                v-model="bank_data.ba_routing_number"
                name="bank-swift-routing"
                id="bank-swift-routing"
                placeholder="Bank Swift/Routing Number"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Bank Name" label-for="bank-name">
              <b-form-input
                v-model="bank_data.ba_name"
                name="bank-name"
                id="bank-name"
                placeholder="Bank Name"
              />
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group label="Account Number" label-for="account-number">
              <b-form-input
                v-model="bank_data.ba_number"
                name="account-number"
                id="account-number"
                placeholder="Account Number"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Bank Address" label-for="bank-address">
              <b-form-textarea
                v-model="bank_data.ba_address"
                name="bank-address"
                id="bank-address"
                placeholder="Bank Address"
              />
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <div class="d-flex justify-content-end mt-2">
              <b-button variant="primary" @click="addBank">Add</b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>

</div>
</template>

<script>
import CommonServices from '@/apiServices/CommonServices';
import { BButton, BCard, BCardText, BCol, BForm, BFormGroup, BFormInput, BFormTextarea, BModal, BRow } from 'bootstrap-vue';
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
import store from '@/store';

export default {
    components: {
     BCard,
     BModal,
     BRow,
     BCol,
     BFormGroup,
     BForm,
     BFormInput,
     BCardText,
     BButton,
     AppCollapse,
     AppCollapseItem,
     BFormTextarea
    },
  data() {
    return {
      banks: [],
      bank_data: {
        ba_benificiary_name: "",
        ba_routing_number: "",
        ba_name: "",
        ba_address: "",
        ba_number: "",
      },
      remarks: "",
    };
  },
  computed: {
    user_type() {
      if (store.getters["user/getUserDetails"]?.user_type) {
        const user_type = store.getters["user/getUserDetails"].user_type;
        if (user_type === "accounts") {
          this.getAllBanks();
        }
        return user_type;
      } else {
        return null;
      }
    },   
  },
  methods: {
    async getAllBanks() {
      try {
        const res = await CommonServices.getAllBanks();
        if (res.data.status) {
          this.banks = res.data.data;
        }
      } catch (error) {
        console.error("Error in getAllBanks ", error);
      }
    },
    async addBank() {
      try {
        const res = await CommonServices.addBank(this.bank_data);
        if (res.data.status) {
          this.$refs["add-bank-modal-ref"].hide();
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: res.data.message || "Bank Added Successfully",
              icon: "Check",
              variant: "success",
            },
          });
          this.getAllBanks();
          this.reset_Bank();         

        }
      } catch (error) {
        console.error("Error in addBank ", error);
      }
    },
    async getBankById(bank_id) {
      try {
        const res = await CommonServices.getBankById(bank_id);
        if (res.data.status) {
          this.bank_data = res.data.data;
        }
      } catch (error) {
        console.error("Error in getBankById ", error);
      }
    },

    editBank(bank_id) {
      this.getBankById(bank_id);
      this.$refs["add-bank-modal-ref"].show();
    },

    reset_Bank() {
      this.bank_data = {
        ba_routing_number: "",
        ba_name: "",
        ba_address: "",
        ba_number: "",
      };
    },
    selectedBank(index) {
      const collapseId = `accordion-${index}`;
      const collapseEl = document.getElementById(collapseId);
      if (collapseEl && collapseEl.classList.contains("show")) {
        // Close if it's open
        this.$root.$emit("bv::toggle::collapse", collapseId);
      } else {
        // Open the target panel
        this.$root.$emit("bv::toggle::collapse", collapseId);
      }
    }, 
    openModal(remarks) {
      this.remarks = remarks;
      this.$bvModal.show('remark-modal');
    },
  },
  beforeMount() {
    this.getAllBanks();
  },
};

</script>
<style>
.bank_card_body {
  position: relative;
}
.edit {
  position: absolute;
  top: 10px;
  right: 20px;
}
.bank_account {
  box-shadow: 10px 5px 8px rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
  border-radius: 10px;
}
.add_account_button {
  position: absolute;
  top: 0;
  right: 20px;
}
</style>