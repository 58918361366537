<template>
  <div>
    <b-modal :no-close-on-backdrop="true" v-model="showPaymentModal" id="payment-status" ref="ref-payment-status"
      centered title="Payment Status" ok-only @ok="reloadPageWithoutParam">
      <section>
        <p v-if="payment_status === 'pending'">pending</p>
        <p v-if="payment_status === 'success'">success</p>
        <p v-if="payment_status === 'failed'">failed</p>
      </section>
    </b-modal>

    <form :action="PAYU_PAYMENT_LINK" target="_blank" method="post" v-show="false" ref="payu-form">
      <input type="hidden" name="key" :value="payUObj.key" />
      <input type="hidden" name="txnid" :value="payUObj.txnid" />
      <input type="hidden" name="productinfo" :value="payUObj.productinfo" />
      <input type="hidden" name="amount" :value="payUObj.amount" />
      <input type="hidden" name="email" :value="payUObj.email" />
      <input type="hidden" name="firstname" :value="payUObj.firstname" />
      <input type="hidden" name="lastname" :value="payUObj.lastname" />
      <input type="hidden" name="surl" :value="payUObj.surl" />
      <input type="hidden" name="furl" :value="payUObj.furl" />
      <input type="hidden" name="phone" :value="payUObj.phone" />
      <input type="hidden" name="hash" :value="payUObj.hash" />
      <input type="submit" value="submit" />
    </form>

    <!-- Buy Service for student -->
    <b-modal :no-close-on-backdrop="true" id="service-payment-modal" ref="ref-service-payment-modal" centered
      title="Service Payment" no-close-on-backdrop @hidden="resetSelectedStudents" hide-footer v-if="plan">
      <label v-if="user_type != 'student'">Select Student</label>
      <v-select :reduce="(student) => student.student_user_id" label="unique_name" v-model="student_user_id"
        :options="assigned_students" :closeOnSelect="true" v-if="user_type != 'student'" placeholder="Select Student">
        <template #option="data">
          <b-avatar :src="FILESURL + data.user_profile_image" size="16" class="align-middle mr-50" />
          <span> {{ data.unique_name }}</span>
        </template>
      </v-select>
      <br />

      <div class="d-flex justify-content-end w-100 mb-2">
        <b-form-checkbox v-model="partPayment" v-if="plan.part_payments.length > 0">Part Payment</b-form-checkbox>
      </div>


      <div v-if="!partPayment">
        <div v-if="
          (user_type != 'student' && student_user_id) || user_type === 'student'
        ">
          <b-row>
            <b-col cols="12" class="">
              <h2>
                <span class="text-primary">{{ plan.plan_name }}</span>
              </h2>
            </b-col>

            <b-col cols="12">
              <hr />
            </b-col>

            <b-col cols="12" class="">
              <div class="d-flex justify-content-between mr-1 align-items-end">
                <h6>Plan Amount</h6>
                <h6>
                  {{
                    getSymbolFromCurrency(
                      plan.pp_currency ? plan.pp_currency : plan.plan_currency
                    )
                  }}
                  <span v-if="
                    plan.pp_currency === 'INR' || plan.plan_currency === 'INR'
                  ">
                    {{
                      addCommas(
                        plan.pp_price
                          ? removeGST(plan.pp_price)
                          : removeGST(plan.plan_price)
                      )
                    }}
                  </span>
                  <span v-else>
                    {{
                      addCommas(plan.pp_price ? plan.pp_price : plan.plan_price)
                    }}
                  </span>
                  <!-- <span v-else> -->
                  <span> </span>
                </h6>
              </div>
              <div class="d-flex justify-content-between mr-1 v" v-if="discount_amount > 0">
                <h6>
                  Discount
                  <span v-if="percentDiscount">({{ percentDiscount }}% OFF)</span>
                </h6>
                <h6>
                  -
                  {{
                    getSymbolFromCurrency(
                      plan.pp_currency ? plan.pp_currency : plan.plan_currency
                    )
                  }}{{ discount_amount.toFixed(2) }}
                </h6>
              </div>
              <div class="d-flex justify-content-between mr-1 align-items-end"
                v-if="plan.pp_currency === 'INR' || plan.plan_currency === 'INR'">
                <h6>GST (18%)</h6>
                <h6>
                  +
                  {{
                    getSymbolFromCurrency(
                      plan.pp_currency ? plan.pp_currency : plan.plan_currency
                    )
                  }}
                  <span v-if="discount_amount > 0">
                    {{ addCommas((amount_after_discount * 0.18).toFixed(2)) }}
                  </span>
                  <span v-else>
                    {{
                      plan.pp_price
                        ? addCommas(
                          (plan.pp_price - removeGST(plan.pp_price)).toFixed(2)
                        )
                        : addCommas(
                          (
                            plan.plan_price - removeGST(plan.plan_price)
                          ).toFixed(2)
                        )
                    }}
                  </span>
                </h6>
              </div>

              <div class="mb-1">
                <hr />
              </div>

              <div class="d-flex justify-content-between mr-1 mt-1 align-items-end">
                <h3 class="text-primary">Total</h3>
                <h3 class="text-primary">
                  {{
                    getSymbolFromCurrency(
                      plan.pp_currency ? plan.pp_currency : plan.plan_currency
                    )
                  }}
                  {{
                    discount_amount > 0 && plan.pp_currency === "INR"
                      ? (
                        amount_after_discount +
                        amount_after_discount * 0.18
                      ).toFixed(0)
                      : amount_after_discount
                  }}
                </h3>
              </div>
            </b-col>

            <b-col cols="12" class="mb-1">
              <hr />
            </b-col>

            <div class="w-100 mx-1">
              <app-collapse>
                <app-collapse-item title="Apply Coupon">
                  <b-row cols="12">
                    <b-col cols="9">
                      <b-form-input v-model="cc_code" placeholder="Enter Coupon Code"></b-form-input>
                    </b-col>
                    <b-col cols="3">
                      <b-button @click="verifyDiscount" variant="outline-secondary" class="w-100">Apply
                      </b-button>
                    </b-col>
                    <b-col cols="12" class="d-flex justify-content-between align-items-end">
                      <p @click="showCoupons = !showCoupons" variant="outline-secondary" class="mt-1 text-primary"
                        style="text-decoration: underline; cursor: pointer">
                        View All Coupons
                      </p>

                      <p style="cursor: pointer" class="text-danger tetx-nowrap" @click="resetPlanAmount" v-if="cc_id">
                        Remove Coupon
                      </p>
                    </b-col>
                    <b-col cols="12" v-if="showCoupons">
                      <div class="w-100 mt-1">
                        <div v-for="(coupon, index) in student_coupons" :key="index" :class="{
                          'd-flex justify-content-between align-items-center mb-1': true,
                          'border-bottom pb-1':
                            student_coupons.length > 1 &&
                            index < student_coupons.length - 1,
                        }">
                          <div class="coupon-box">
                            <h4>{{ coupon.cc_code }}</h4>
                            <!-- <span v-if="coupon.cc_percent && coupon.cc_upto_amount" style="font-size: 12px">
                              {{ coupon.cc_percent }}% discount upto
                              {{
                                getSymbolFromCurrency(
                                  plan.pp_currency
                                    ? plan.pp_currency
                                    : plan.plan_currency
                                )
                              }}{{ coupon.cc_upto_amount }}
                            </span> -->
                            <span v-if="coupon.cc_percent">
                              {{ coupon.cc_percent }}% Discount
                            </span>
                            <span v-if="coupon.cc_flat_amount" style="font-size: 12px">
                              Flat
                              {{
                                getSymbolFromCurrency(
                                  plan.pp_currency
                                    ? plan.pp_currency
                                    : plan.plan_currency
                                )
                              }}{{ coupon.cc_flat_amount }} Discount
                            </span>
                          </div>
                          <div>
                            <b-button @click="useCouponCode(coupon.cc_code)" variant="outline-secondary"
                              class="w-100">Apply
                            </b-button>
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </app-collapse-item>
              </app-collapse>
            </div>

            <b-col class="d-flex justify-content-start py-1" v-if="couponAppliedMessage.message">
              <b-badge :variant="couponAppliedMessage.status ? 'success' : 'danger'">{{ couponAppliedMessage.message
                }}</b-badge>
            </b-col>

            <b-col cols="12" class="d-flex mt-2" style="gap: 5px">
              <b-button v-if="plan.pp_currency !== 'INR'" @click="buyServicePlanStripe(plan)" style="flex: 1"
                variant="primary">Pay Now</b-button>
              <b-button v-if="plan.pp_currency && plan.pp_currency === 'INR'" style="flex: 1" @click="makePayUPayment()"
                variant="primary">Pay Now
              </b-button>
            </b-col>

            <!-- <b-col cols="12 mt-2" v-if="plan.pp_currency && plan.pp_currency === 'INR'">
            <p class="text-right font-weight-bold">
              NOTE: For UPI Payments choose PayU
            </p>
          </b-col> -->
          </b-row>
        </div>
      </div>
      <div v-else>
        <div v-if="
          (user_type != 'student' && student_user_id) || user_type === 'student'
        ">
          <b-row>
            <b-col cols="12" class="">
              <h2>
                <span class="text-primary">{{ plan.plan_name }}</span>
              </h2>
            </b-col>

            <b-col cols="12">
              <hr />
            </b-col>

            <b-col cols="12" class="">
              <div class="d-flex justify-content-between mr-1 align-items-end">
                <h6>Part 1 Amount</h6>
                <h6>
                  {{
                    getSymbolFromCurrency(
                      plan.pp_currency ? plan.pp_currency : plan.plan_currency
                    )
                  }}
                  <span v-if="
                    plan.pp_currency === 'INR' || plan.plan_currency === 'INR'
                  ">
                    {{
                      addCommas(
                        removeGST(plan.part_payments[0].ppp_price)
                      )
                    }}
                  </span>
                  <span v-else>
                    {{
                      addCommas(plan.part_payments[0].ppp_price)
                    }}
                  </span>
                  <!-- <span v-else> -->
                  <span> </span>
                </h6>
              </div>
              <div class="d-flex justify-content-between mr-1 align-items-end"
                v-if="plan.pp_currency === 'INR' || plan.plan_currency === 'INR'">
                <h6>GST (18%)</h6>
                <h6>
                  +
                  {{
                    getSymbolFromCurrency(
                      plan.pp_currency ? plan.pp_currency : plan.plan_currency
                    )
                  }}
                  <span>
                    {{
                      plan.part_payments[0].ppp_price
                        ? addCommas(
                          (plan.part_payments[0].ppp_price - removeGST(plan.part_payments[0].ppp_price)).toFixed(2)
                        )
                        : addCommas(
                          (
                            plan.part_payments[0].ppp_price - removeGST(plan.part_payments[0].ppp_price)
                          ).toFixed(2)
                        )
                    }}
                  </span>
                </h6>
              </div>

              <div class="mb-1">
                <hr />
              </div>

              <div class="d-flex justify-content-between mr-1 mt-1 align-items-end">
                <h3 class="text-primary">Total</h3>
                <h3 class="text-primary">
                  {{
                    getSymbolFromCurrency(
                      plan.pp_currency ? plan.pp_currency : plan.plan_currency
                    )
                  }}
                  {{
                    plan.part_payments[0].ppp_price
                  }}
                </h3>
              </div>
            </b-col>

            <b-col cols="12" class="d-flex mt-2" style="gap: 5px">
              <b-button v-if="plan.pp_currency !== 'INR'" @click="buyServicePlanStripe(plan)" style="flex: 1"
                variant="primary">Pay Now</b-button>
              <b-button v-if="plan.pp_currency && plan.pp_currency === 'INR'" style="flex: 1" @click="makePayUPayment()"
                variant="primary">Pay Now
              </b-button>
            </b-col>

            <div class="border w-100 mx-1 mt-2 p-2 pr-0 rounded-lg" style="background-color: #eee;">
              <h5>NOTE</h5>
              <h6>The Part Payment Option Includes the following payments</h6>
              <h6 v-for="(part, index) of plan.part_payments" :key="'part_' + part.ppp_id">
                Part {{ index + 1 }} :
                {{
                  getSymbolFromCurrency(
                    plan.pp_currency ? plan.pp_currency : plan.plan_currency
                  )
                }}
                {{ removeGST(part.ppp_price) }} ({{ '+ 18% GST' }})
              </h6>
              <h6>Total : {{
                getSymbolFromCurrency(
                  plan.pp_currency ? plan.pp_currency : plan.plan_currency
                )
              }} {{ plan.part_payments.reduce((acc, pp) => acc + parseInt(removeGST(pp.ppp_price)), 0) }}
                ({{ '+ 18% GST' }})
              </h6>
            </div>

            <!-- <b-col cols="12 mt-2" v-if="plan.pp_currency && plan.pp_currency === 'INR'">
            <p class="text-right font-weight-bold">
              NOTE: For UPI Payments choose PayU
            </p>
          </b-col> -->
          </b-row>
        </div>
      </div>
    </b-modal>


    <b-tabs v-model="active_tab_index">
      <b-tab v-for="(service, index) in services" :title="service.service_name" :key="'services_tab_' + index">
        <div
      :class="['pricing-cards-grid', { 'pricing-cards-grid-4': service.service_name === 'IELTS' }]"
    >
          <div v-for="(plan, index) in service.plans" :key="'plan_' + plan.plan_id + index" class=""
            :class="{ 'third-card': index === 2 }">
            <b-card :class="{ 'h-100': index === 2 }">
              <div class="pricing-card p-2 h-100">
                <div class="pricing-plan-name">{{ plan.plan_name }}</div>
                <div class="pricing-cost mb-0">
                  <span v-if="!plan.plan_price && !plan.pp_price">
                    {{
                      getSymbolFromCurrency(
                        plan.pp_currency ? plan.pp_currency : plan.plan_currency
                      )
                    }}0
                  </span>
                  <span v-else>
                    {{
                      getSymbolFromCurrency(
                        plan.pp_currency ? plan.pp_currency : plan.plan_currency
                      )
                    }}
                    <span v-if="user_details.country === 'IN'">
                      {{
                        addCommas(
                          plan.pp_price
                            ? removeGST(plan.pp_price)
                            : removeGST(plan.plan_price)
                        )
                      }}
                    </span>
                    <span v-else>
                      {{
                        addCommas(
                          plan.pp_price ? plan.pp_price : plan.plan_price
                        )
                      }}
                    </span>
                  </span>
                </div>

                <div class="text-center" style="color: #999" v-if="user_details.country === 'IN' && plan.plan_price">
                  + 18% GST
                </div>
                <div v-else>
                  &nbsp;
                </div>
                <div :class="['pricing-content text-center mt-1' , {'card-height': plan.plan_name === 'Level 1'}]" v-if="plan.plan_price !== null">
                  {{ plan.plan_description }}
                </div>
                <div class="pricing-btn">
                  <button v-if="
                    [
                      'agent',
                      'franchise',
                      'student_support',
                      'counselor',
                    ].includes(user_type) &&
                    plan.plan_price !== null &&
                    plan.plan_price !== 0
                  " class="btn btn-pay-now w-100" @click="() => showServicePaymentModal(plan)">
                    Buy For Student
                  </button>
                  <div v-if="
                    user_type === 'student' &&
                    plan.plan_price !== null &&
                    plan.plan_price !== 0
                  ">
                    <button class="btn btn-pay-now w-100" @click="() => showServicePaymentModal(plan)">
                      Buy Now
                    </button>
                  </div>
                  <div v-if="!plan.plan_price && !plan.pp_price && !['services'].includes(user_type)">
                    <button class="btn btn-pay-now w-100 mt-2">Free</button>
                  </div>
                </div>
              </div>
            </b-card>
          </div>
        </div>
        <b-card v-if="service.features && service.features.length">
          <div class="pricing-plan-table animate__animated animate__pulse table-responsive">
            <table class="table">
              <thead class="header">
                <tr>
                  <th class="plan-lg-title pl-4">Compare Plans</th>
                  <th v-for="(plan, index) in service.plans" :key="'plan_compare_' + plan.plan_id + '_' + index">
                    <div class="text-center">
                      <div class="plan-name">{{ plan.plan_name }}</div>
                      <!-- <div class="plan-price">
                        {{
                          getSymbolFromCurrency(
                            plan.pp_currency
                              ? plan.pp_currency
                              : plan.plan_currency
                          )
                        }}
                        <span v-if="user_details.country === 'IN'">
                          {{
                            addCommas(
                              plan.pp_price
                                ? removeGST(plan.pp_price)
                                : removeGST(plan.plan_price)
                            )
                          }}
                        </span>
                        <span v-else>
                          {{
                            addCommas(
                              plan.pp_price ? plan.pp_price : plan.plan_price
                            )
                          }}
                        </span>
                      </div> -->
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody class="alternate-stripped" v-for="(feature, feature_index) in service.features"
                :key="'feature_' + feature.feature_id + '_' + feature_index">
                <tr>
                  <td class="pl-4">{{ feature.feature_name }}</td>
                  <td v-for="(plan, index) in service.plans" :key="index">
                    <span v-if="plan.plan_features[feature_index].extra_text">
                      {{ plan.plan_features[feature_index].extra_text }}
                    </span>
                    <img v-else-if="plan.plan_features[feature_index].spfm_id" src="@/assets/images/svg/blue-tick.svg"
                      class="img-fluid" />
                    <img v-else src="@/assets/images/svg/red-cross.svg" class="img-fluid" />
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <span>Services included are subject to change*</span>
              </tfoot>
            </table>
          </div>
        </b-card>
      </b-tab>

      <b-tab v-if="user_type === 'student'" title="Services Availed">
        <services-availed :servicesAvailed="services_availed" />
      </b-tab>

      <b-tab title="Accommodations">
        <accommodations />
      </b-tab>

      <b-tab title="Available Coupons" v-if="user_type === 'franchise' || user_type === 'counselor'">
        <AvailableCoupons />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import {
  BCarousel,
  BCarouselSlide,
  BButtonGroup,
  BButton,
  BCardText,
  BTabs,
  BTab,
  BCard,
  BCardHeader,
  BCardBody,
  BCollapse,
  BAvatar,
  BRow,
  BCol,
  BFormRadio,
  BFormGroup,
  BFormRadioGroup,
  BFormInput,
  BBadge,
  BFormCheckbox,
} from "bootstrap-vue";

import vSelect from "vue-select";
import ServicesAvailed from "./ServicesAvailed.vue";
import Accommodations from "./Accomodations.vue";
import Ripple from "vue-ripple-directive";
import HomeServices from "@/apiServices/HomeServices";
import StudentServices from "@/apiServices/StudentServices";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import { FILESURL } from "@/config";
import store from "@/store";
import PaymentServices from "@/apiServices/PaymentServices";
import mixin from "@/utils/mixins";
import getSymbolFromCurrency from "currency-symbol-map";
import CommonServices from "@/apiServices/CommonServices";
import { PAYU_PAYMENT_LINK } from "@/config";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import AvailableCoupons from "./AvailableCoupons.vue";
export default {
  components: {
    BCardCode,
    BCard,
    BFormCheckbox,
    BCardHeader,
    BCardBody,
    BCollapse,
    BCarousel,
    BCarouselSlide,
    BButtonGroup,
    BButton,
    BCardText,
    BTabs,
    BTab,
    BAvatar,
    BRow,
    BCol,
    ServicesAvailed,
    Accommodations,
    BFormRadio,
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BBadge,
    AppCollapse,
    AppCollapseItem,
    AvailableCoupons,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [mixin],
  data() {
    return {
      showCoupons: false,
      student_coupons: [],
      PAYU_PAYMENT_LINK,
      selectedCouponId: null,
      selectedPlan: null,

      cc_code: "",
      cc_id: null,
      discount_amount: 0,
      amount_after_discount: 0,

      coupon_details: {},
      couponAppliedMessage: {
        message: "",
        status: false,
      },
      coupons: [],
      active_tab_index: 0,

      student_user_id: null,
      plan: null,
      services: [],
      FILESURL,

      showPaymentModal: false,
      partPayment: false,
      payment_status: "pending",
      // is_paid: false,
      payUObj: {
        key: "",
        surl: "",
        furl: "",
        amount: null,
        productinfo: "",
        firstname: "",
        lastname: "",
        email: "",
        phone: null,
        txnid: "",
        hash: "",
      },
      percentDiscount: null,
      services_availed: false,
    };
  },
  computed: {
    assigned_students() {
      return store.getters["user/getAssignedStudents"].data;
    },
    user_type() {
      if (store.getters["user/getUserDetails"]?.user_type) {
        if (store.getters["user/getUserDetails"].user_type === "student") {
          this.studentCoupons();
        }else{
          this.commonCoupons()
        }

        return store.getters["user/getUserDetails"].user_type;
      } else {
        return null;
      }
    },
  },

  methods: {
    getSymbolFromCurrency,

    checkFree(plan) {
      if (!plan.pp_price) {
        true;
      }
      return false;
    },
    async useCouponCode(code) {
      this.cc_code = code;
      await this.verifyDiscount();
      this.showCoupons = false;
    },
    async studentCoupons() {
      const res = await StudentServices.getAvailableCoupons();
      if (res.data.status) {
        this.student_coupons = res.data.data;
      }
    },
    async commonCoupons() {
      const res = await CommonServices.getAvailableCoupons({
        student_user_id : this.student_user_id});
      if (res.data.status) {
        this.student_coupons = res.data.data.data;
        console.log(res)
      }
    },

    removeGST(total) {
      let taxPercentage = 0.18;
      let withoutTaxAmount = total / (1 + taxPercentage);
      return withoutTaxAmount.toFixed(0);
    },

    watchUserType() {
      try {
        const query = this.$route.query;
        const { transaction_id } = query;
        console.log({ transaction_id });

        if (transaction_id && !isNaN(transaction_id)) {
          this.verifyTransactionStripe(transaction_id);
        }

        this.getServicesAndPlans();
      } catch (err) {
        console.error(`Error in watchUserType `, err);
      }
    },

    resetPlanAmount() {
      this.cc_id = null;
      this.cc_code = "";
      this.amount_after_discount = this.plan.pp_price
        ? this.plan.pp_price
        : this.plan.plan_price;
      this.discount_amount = 0;
      this.couponAppliedMessage = {
        message: "",
        status: false,
      };
    },

    async verifyDiscount() {
      let coupon_code = "";

      try {
        const res = await CommonServices.verifyCouponCode(this.cc_code);

        if (res.data.status) {
          coupon_code = res.data.data;
        }
      } catch (error) {
        console.error({ error });
      }

      if (!coupon_code) {
        this.resetPlanAmount();

        this.couponAppliedMessage = {
          message: "Invalid or Expired Coupon Code",
          status: false,
        };
      } else {
        this.cc_id = coupon_code.cc_id;
        let plan_currency = this.plan.pp_currency
          ? this.plan.pp_currency
          : this.plan.plan_currency;
        let plan_price = this.plan.pp_price
          ? this.plan.pp_price
          : this.plan.plan_price;

        let discount_amount = 0;
        let final_transaction_amount = plan_price;

        if (plan_currency === "INR") {
          final_transaction_amount = this.removeGST(final_transaction_amount);
        }

        const { cc_flat_amount, cc_percent, cc_upto_amount } = coupon_code;

        if (cc_flat_amount) {
          discount_amount = cc_flat_amount;
          final_transaction_amount -= discount_amount;
        }

        if (cc_percent && !cc_upto_amount) {
          this.percentDiscount = cc_percent;
          discount_amount = final_transaction_amount * (cc_percent / 100);
          final_transaction_amount -= discount_amount;
        }

        if (cc_percent && cc_upto_amount) {
          discount_amount = final_transaction_amount * (cc_percent / 100);
          if (discount_amount > cc_upto_amount) {
            discount_amount = cc_upto_amount;
          }
          final_transaction_amount -= discount_amount;
        }

        this.amount_after_discount = final_transaction_amount;
        this.discount_amount = discount_amount;

        this.couponAppliedMessage = {
          message: `${this.cc_code} has been applied (${cc_flat_amount ? "Flat Rs." + cc_flat_amount : ""
            } ${cc_percent ? cc_percent + "% OFF" : ""} ${cc_upto_amount ? "Upto Rs." + cc_upto_amount : ""
            })`,
          status: true,
        };
        // this.cc_code = "";
      }

      // try {
      //   const response = await CouponsServices.verifyCouponCode(this.cc_filter);
      //   if (response.data.status) {
      //     this.coupon_details = response.data.data
      //     this.plan.plan_price = this.plan.plan_price - this.coupon_details.cc_flat_amount
      //     this.couponAppliedMessage = `${this.cc_filter.cc_code} has been applied`;
      //   } else {
      //     this.couponAppliedMessage = 'Invalid or Expired Coupon Code';
      //   }
      // } catch (error) {
      //   console.error('Error:', error);
      // }
    },

    getServicesAndPlans() {
      const user_details = this.$store.getters["user/getUserDetails"];

      const payload = {};
      if (user_details.country) {
        payload.country = user_details.country;
      }

      CommonServices.getServicesAndPlans(payload)
        .then((response) => {
          if (response.data.status) {
            this.services = response.data.data.filter(service => 
    service.service_name !== "IELTS Demo" && service.service_name !== "Other Services"
);

            this.$nextTick(() => {
              if (this.services_availed) {
                this.active_tab_index = 4;
              } else {

                this.active_tab_index = 0;
              }
              this.checkQuery();
            });
          }
        })
        .catch((err) => {
          console.error("Error in getting services and plans", err);
        });
    },

    // async getCoupons() {
    //   try {
    //     const response = await CommonServices.getAllCouponCodes();
    //     if (response.data.status) {
    //       this.coupons = response.data.data;
    //     }
    //   } catch (error) {
    //     console.error("Error in getting coupons", err);
    //   }
    // },

    checkQuery() {
      if (this.services && this.services.length) {
        const { service_name } = this.$route.query;
        const services = this.services;
        for (let i = 0; i < services.length; i++) {
          const s = services[i];
          if (s.service_name === service_name) {
            this.active_tab_index = i;
          }
        }
      }
    },

    showServicePaymentModal(plan) {

      this.plan = plan;
      this.amount_after_discount = this.plan.pp_price
        ? this.plan.pp_price
        : this.plan.plan_price;
      this.$nextTick(() => {
        this.$refs["ref-service-payment-modal"].show();
      });
    },

    // showStudentPaymentModal(plan) {
    //   this.plan = plan;
    //   this.$refs["ref-student-payment-modal"].show();
    //},

    selectCoupon(cc_id) {
      this.selectedCouponId = cc_id;
    },

    closeServicePaymentModal() {
      this.plan = {};
      this.$refs["ref-service-payment-modal"].hide();
    },

    addCommas(amt) {
      return amt.toLocaleString("en-IN");
    },

    resetSelectedStudents() {
      this.partPayment = false;
      this.student_user_id = null;
      this.plan_id = null;
      this.discount_amount = 0;
      this.amount_after_discount = 0;
      this.cc_id = 0;
      this.couponAppliedMessage = {
        message: "",
        status: false,
      };
    },

    async makePayUPayment() {

      let response;
      if (this.user_type === "student") {
        response = await StudentServices.makePayUPayment({
          part_payment: this.partPayment ? 'Y' : 'N',
          plan_id: this.plan.plan_id,
          cc_id: this.cc_id,
        });
      } else if (
        ["agent", "franchise", "student_support", "counselor"].includes(
          this.user_type
        )
      ) {
        response = await CommonServices.makePayUPayment({
          student_user_id: this.student_user_id,
          part_payment: this.partPayment ? 'Y' : 'N',
          plan_id: this.plan.plan_id,
          cc_id: this.cc_id,
        });

      }

      if (response.data.status) {
        this.payUObj = response.data.data;

        this.$nextTick(() => {
          this.$refs["payu-form"].submit();
        });
        // verifyUniversityPayment
        this.verifyPayUPayment();
      } else {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: response.data.message,
            icon: "XIcon",
            variant: "failure",
          },
        });
      }
    },

    async verifyPayUPayment() {
      this.showPaymentModal = true;

      await (new Promise((resolve) => {
        setTimeout(() => {
          return resolve()
        }, 4000)
      }))

      const interval = setInterval(async () => {
        try {
          let res;

          if (this.user_type === "student") {
            res = await StudentServices.verifyPayUPayment({
              transaction_id: this.payUObj.txnid,
            });
          } else {
            res = await CommonServices.verifyPayUPayment({
              transaction_id: this.payUObj.txnid,
            });
          }

          if (res.data.status) {
            this.payment_status =
              res.data.data.payment_status === "failure"
                ? "failed"
                : res.data.data.payment_status;
            if (this.payment_status != "pending") {

              clearInterval(interval);
            }
          }
        } catch (error) {
          console.error({ error });
          clearInterval(interval);
        }
      }, 4000);
    },
    // async saveAssessUser() {
    //   if (this.is_paid) {
    //     const res = await StudentServices.saveAssessProfile({ is_paid: this.is_paid })
    //     if (res.data.status) {
    //       this.$toast({
    //         component: ToastificationContentVue,
    //         props: {
    //           title: res.data.message,
    //           icon: "Check",
    //           variant: "success",
    //         },
    //       });
    //     } else {
    //       this.$toast({
    //         component: ToastificationContentVue,
    //         props: {
    //           title: res.data.message,
    //           icon: "Check",
    //           variant: "success",
    //         },
    //       });
    //     }
    //   }
    // },
    // async saveAssessUserByStaff() {
    //   if(this.is_paid){
    //   const res = await CommonServices.saveAssessProfileByStaff({ is_paid: this.is_paid, student_id: this.student_user_id })
    //   if (res.data.status) {
    //     this.$toast({
    //       component: ToastificationContentVue,
    //       props: {
    //         title: res.data.message,
    //         icon: "Check",
    //         variant: "success",
    //       },
    //     });
    //   } else {
    //     this.$toast({
    //       component: ToastificationContentVue,
    //       props: {
    //         title: res.data.message,
    //         icon: "Check",
    //         variant: "success",
    //       },
    //     });
    //   }
    //   }
    // },

    paymentCompleteRzrPay(response) {
      if (response.status) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Service Purchased",
            icon: "Check",
            variant: "success",
          },
        });
        this.reloadPageWithoutParam();
      } else {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Server Error",
            icon: "X",
            variant: "failure",
          },
        });
      }
    },

    buyServicePlanRzrPay(plan_id) {
      const userProfile = store.getters["user/getUserDetails"];
      if (userProfile.user_type === "student") {
        PaymentServices.displayRazorPay({
          plan_id,
          userProfile,
          razorpayPaymentResponse: this.paymentCompleteRzrPay,
        });
      } else if (
        ["agent", "franchise", "student_support", "counselor"].includes(
          userProfile.user_type
        ) &&
        this.student_user_id
      ) {
        if (!this.student_user_id) {
          return;
        }
        this.closeServicePaymentModal();
        PaymentServices.displayRazorPayForAgent({
          student_user_id: this.student_user_id,
          plan_id,
          userProfile,
          razorpayPaymentResponse: this.paymentCompleteRzrPay,
        });
      }
    },

    async buyServicePlanStripe(plan) {
      try {
        const url = window.location.href;

        if (this.user_type === "student") {
          const response = await StudentServices.makeStripePayment({
            part_payment: this.partPayment ? 'Y' : 'N',
            plan_id: plan.plan_id,
            url,
            cc_id: this.cc_id,
          });

          console.log({ response });


          if (!response.data.status) {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: response.data.message,
                icon: "XIcon",
                variant: "failure",
              },
            });
            return
          }
          window.location.href = response.data.data.session.url;
        } else if (
          ["agent", "franchise", "student_support", "counselor"].includes(
            this.user_type
          )
        ) {
          this.closeServicePaymentModal();
          const response = await CommonServices.makeStripePayment({
            part_payment: this.partPayment ? 'Y' : 'N',
            plan_id: plan.plan_id,
            student_user_id: this.student_user_id,
            url,
            cc_id: this.cc_id,
          });
          if (!response.status) {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: response.data.message,
                icon: "XIcon",
                variant: "failure",
              },
            });
          }
          window.location.href = response.data.data.session.url;
        }
      } catch (error) {
        console.error("Error in buyServicePlanStripe ", error);
      }
    },

    async verifyTransactionStripe(transaction_id) {
      this.showPaymentModal = true;

      try {
        let response;
        if (this.user_type === "student") {
          response = await StudentServices.verifyTransaction({
            transaction_id,
          });
        } else if (
          ["agent", "franchise", "student_support", "counselor"].includes(
            this.user_type
          )
        ) {
          response = await CommonServices.verifyTransaction({ transaction_id });
        }
        if (response?.data?.status) {
          this.payment_status = "success";
        } else {
          this.payment_status = "failed";
        }
      } catch (error) {
        console.error("Error in verifing transaction ", error);
      }
    },

    reloadPageWithoutParam() {
      window.location.replace(
        `${window.location.origin}${window.location.pathname}`
      );
    },
  },
  beforeMount() {
    this.watchUserType();
    const { services_availed } = this.$route.query;
    if (services_availed) {
      this.services_availed = services_availed
    }
  },
};
</script>
<style>
.primary-color {
  color: #00d09c;
}

.pricing-cards-grid {
  display: grid;
  grid-template-columns: repeat(5, 33%);
  grid-template-rows: 1fr;
  grid-column-gap: 14px;
}
.pricing-cards-grid-4{
  grid-template-columns: repeat(5, 24%) !important;
}

.pricing-cards-grid .third-card {
  height: 90%;
}

.pricing-card {
  background: linear-gradient(180deg, #ffffff 0%, #f8f8f8 100%);
  border-radius: 15px;
  /* box-shadow: -4px -4px 4px rgba(255, 255, 255, 0.13),
    4px 4px 11px rgba(0, 0, 0, 0.25); */
  /* font-family: "Montserrat", sans-serif; */
}

.pricing-card .pricing-plan-name {
  color: #00d09c;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  margin-bottom: 12px;
}

.pricing-card .pricing-cost {
  font-size: 25px;
  text-align: center;
  margin-bottom: 15px;
  font-weight: 600;
  /* font-family: "Alice"; */
}

.pricing-card .pricing-content {
  font-size: 14px;
  margin-bottom: 25px;
  text-align: left;
}
.card-height{
  height: 40px;
}

.pricing-card .btn-pay-now {
  background-color: #5367ff;
  color: #fff;
  border-radius: 20px !important;
  font-weight: 500;
}

.pricing-card .plan-route {
  color: #5367ff;
  text-align: center;
  font-size: 14px;
  margin-top: 18px;
  cursor: pointer;
  font-weight: 500;
}

.pricing-section .pricing-slider .slick-list {
  margin: 0 -30px;
}

.pricing-section .pricing-slider .slick-slide {
  margin: 0 30px;
}

/***********PRICING PLAN TABLE************/

.pricing-plan-table {
  /* font-family: "Montserrat", sans-serif; */
}

.pricing-plan-table td {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}

/* .pricing-plan-table .header{
  text-align: center;
} */
.pricing-plan-table thead th {
  border-bottom: 1px solid transparent;
  border-top: 1px solid transparent;
}

.pricing-plan-table .plan-lg-title {
  font-size: 1.5rem;
  /* font-family: "Alice"; */
  font-weight: 600;
}

.pricing-plan-table .plan-name {
  color: #00d09c;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 05px;
}

.pricing-plan-table .plan-price {
  /* font-family: "Alice"; */
  font-size: 22px;
  font-weight: 600;
}

.pricing-plan-table .btn-select-plan {
  background: #e1e1e1;
  border: 1px solid #465cff;
  border-radius: 20px;
  width: 100%;
  font-size: 15px;
  font-weight: 600;
}

.pricing-plan-table .header th {
  position: relative;
}

.pricing-plan-table .header th:after {
  content: "";
  height: 50%;
  width: 1px;
  position: absolute;
  right: 0;
  top: 50%;
  background-color: #00d09c;
}

.pricing-plan-table .header th:last-child:after {
  content: "";
  height: 50%;
  width: 0px;
  position: absolute;
  right: 0;
  top: 50%;
  background-color: #00d09c;
}

.pricing-plan-table .alternate-stripped .plan-detail {
  padding: 1.2rem 0 0.4rem 0;
  font-weight: bold;
  text-transform: uppercase;
}

.pricing-plan-table thead {
  position: sticky;
  top: 0;
  /* position: sticky !important; */
  /* top: 75px; */
  background-color: white;
  box-shadow: 0 0 16px rgb(0 0 0 / 10%);
}

.pricing-plan-table .alternate-stripped td {
  padding: 0.5rem 0;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid #00d09c;
  text-align: center;
}

.pricing-plan-table .alternate-stripped td:last-child {
  border-right: 0px solid transparent;
}

.pricing-plan-table .alternate-stripped td:first-child {
  text-align: left;
}

.pricing-plan-table .alternate-stripped tr:nth-of-type(even) {
  background: rgba(83, 103, 255, 0.04);
}

.pricing-plan-table .table tbody+tbody {
  border-top: 1px solid transparent;
}

@media (max-width: 768px) {
  .pricing-cards-grid {
    grid-template-columns: repeat(3, 100%);
    overflow: auto;
  }
}
</style>
