<template>
  <div class="dashboard-table">
    <!-- Application Chat module -->
    <b-modal :no-close-on-backdrop="true" ref="applicationStatusModal" @hidden="onApplicationStatusModalClose"
      hide-footer size="lg" title="Status Description">
      <div v-if="currentApplicationStatus" class="p-2">
        <h3 class="mb-1">{{ currentApplicationStatus.status_name }}</h3>
        <p>
          {{ currentApplicationStatus.status_description }}
        </p>
      </div>
    </b-modal>
    <!-- Application Chat module -->
    <b-modal :no-close-on-backdrop="true" ref="applicationChatModal" @hidden="onApplicationModalClose" hide-footer>
      <div class="AliifChat-chatbox">
        <div class="chat-header" style="background-color: #5367ff">
          <h5 style="color: #fff" class="m-0">Aliff</h5>
        </div>
        <div class="chat-messages">
          <b-row v-for="(c, index) in applicationChat" :key="'ac_' + index">
            <div class="message ml-2 mb-2">
              <div class="user-info">
                <span class="user-name">
                  {{ `${c.user_firstname} ${c.user_lastname}` }}
                </span>
                <span class="user-type">
                  {{ c.user_type_tag || c.user_type_name }}
                </span>
              </div>
              <div class="message-text">
                <span class="message-content">{{ c.message }}</span>
                <sub class="message-timestamp">{{
                  getDateTimeFormatted(c.created_at)
                  }}</sub>
              </div>
            </div>
          </b-row>
        </div>
        <div class="chat-input">
          <b-row class="mb-3 mx-0">
            <b-col cols="9">
              <b-form-textarea type="textarea" placeholder="Enter Message" class="border-0" cols="5" @keypress="(e) => {
                if (e.key === 'Enter') {
                  sendApplicationMessage();
                  e.preventDefault();
                }
              }
                " maxlength="5000" v-model="chatMessage" />
            </b-col>
            <b-col cols="3">
              <button @click="sendApplicationMessage" class="btn btn-primary">
                SEND
              </button>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>

    <!-- Course Details Modal -->
    <b-modal :no-close-on-backdrop="true" ref="detailModal" @hidden="onDetailModalClose" hide-footer size="xl">
      <div class="custom-modal-content">
        <div class="row">
          <!-- GENERAL ELIGIBILITY -->
          <div class="mb-2 col-12 col-md-4">
            <div class="course-detail__sm-title mb-1">General Eligibility</div>
            <div>
              {{
                popupData && popupData.general_eligibility
                  ? popupData.general_eligibility
                  : "NA"
              }}
            </div>
          </div>
          <!-- LANGUAGE REQUIREMENT -->
          <div class="mb-2 col-12 col-md-4">
            <div class="course-detail__sm-title mb-1">Language Requirement</div>
            <!-- <div>{{ languages_requirement }}</div> -->
            <div>
              {{
                popupData && popupData.languages_requirement
                  ? popupData.languages_requirement
                  : "NA"
              }}
            </div>
          </div>
          <!-- ADDITIONAL INFORMATION -->

          <!-- INTAKES -->
          <div class="mb-2 mb-md-0 col-12 col-md-4">
            <div class="course-detail__sm-title mb-1">Intake</div>
            <div>
              <span class="mr-1">Intake Start:</span>
              <span>{{
                popupData && popupData.intake ? popupData.intake : "NA"
                }}</span>
            </div>
            <div class="my-2">
              <div>
                <span class="mr-1">Intake Start:</span><span>{{
                  popupData && popupData.intake_start
                    ? popupData.intake_start
                    : "NA"
                }}</span>
              </div>
              <div>
                <span class="mr-1">Intake End:</span><span>{{
                  popupData && popupData.intake_end
                    ? popupData.intake_end
                    : "NA"
                }}</span>
              </div>
            </div>

            <div>
              <span class="mr-1">Intake Deadline 1:</span><span>{{
                popupData && popupData.intake_deadline_1
                  ? popupData.intake_deadline_1
                  : "NA"
              }}</span>
            </div>
            <div>
              <span class="mr-1">Intake Deadline 2:</span><span>{{
                popupData && popupData.intake_deadline_2
                  ? popupData.intake_deadline_2
                  : "NA"
              }}</span>
            </div>
            <div>
              <span class="mr-1">Intake Deadline 3:</span><span>{{
                popupData && popupData.intake_deadline_3
                  ? popupData.intake_deadline_3
                  : "NA"
              }}</span>
            </div>
            <div>
              <span class="mr-1">Intake Deadline 4:</span><span>{{
                popupData && popupData.intake_deadline_4
                  ? popupData.intake_deadline_4
                  : "NA"
              }}</span>
            </div>
            <div>
              <span class="mr-1">Intake Deadline 5:</span><span>{{
                popupData && popupData.intake_deadline_5
                  ? popupData.intake_deadline_5
                  : "NA"
              }}</span>
            </div>
            <div>
              <span class="mr-1">Intake Deadline 6:</span><span>{{
                popupData && popupData.intake_deadline_6
                  ? popupData.intake_deadline_6
                  : "NA"
              }}</span>
            </div>
          </div>
          <!-- INTERNATIONAL SCHOLARSHIP DESCRIPTION -->
          <div class="col-12 col-md-4">
            <div class="course-detail__sm-title mb-1">
              International Scholarship Description
            </div>
            <!-- <div><p>{{ internation_scholarship_description }}</p></div> -->
            <div>
              <p>
                {{
                  popupData && popupData.internation_scholarship_description
                    ? popupData.internation_scholarship_description
                    : "NA"
                }}
              </p>
            </div>

            <div class="fs-14">
              <span class="font-bold mr-1">Scholarship Page Link:</span><a href="">{{
                popupData && popupData.scholarship_page_link
                  ? popupData.scholarship_page_link
                  : "NA"
              }}</a>
            </div>
          </div>
          <!-- GRE -->
          <div class="mb-2 col-12 col-md-4">
            <div class="course-detail__sm-title mb-1">Other</div>

            <div>
              <span class="mr-1">GRE Requirement:</span><span>{{
                popupData && popupData.gre_requirement
                  ? popupData.gre_requirement
                  : "NA"
              }}</span>
            </div>
            <div>
              <span class="mr-1">GRE Score:</span><span>{{
                popupData && popupData.gre_score ? popupData.gre_score : "NA"
                }}</span>
            </div>
            <div>
              <span class="mr-1">SAT Requirement:</span><span>{{
                popupData && popupData.sat_requirement
                  ? popupData.sat_requirement
                  : "NA"
              }}</span>
            </div>
            <div>
              <span class="mr-1">SAT Score:</span><span>{{
                popupData && popupData.sat_score ? popupData.sat_score : "NA"
                }}</span>
            </div>
          </div>
          <!-- add -->
          <div class="col-12 col-md-4">
            <div class="course-detail__sm-title mb-1">
              Additional Information
            </div>
            <!-- <div><p>{{ internation_scholarship_description }}</p></div> -->
            <div class="fs-14">
              <span class="font-bold mr-1">Stem Course:</span><span>{{
                popupData && popupData.stem_course
                  ? popupData.stem_course
                  : "NA"
              }}</span>
            </div>
            <div class="fs-14">
              <span class="font-bold mr-1">Fifteen Years Education Acceptable:</span><span>{{
                popupData && popupData.fifteen_years_education_acceptable
                  ? popupData.fifteen_years_education_acceptable
                  : "NA"
              }}</span>
            </div>
            <div class="fs-14">
              <span class="font-bold mr-1">IELTS Waiver:</span><span>{{
                popupData && popupData.ielts_waiver
                  ? popupData.ielts_waiver
                  : "NA"
              }}</span>
            </div>
            <div class="fs-14">
              {{
                popupData && popupData.if_ielts_is_waived_conditions_for
                  ? popupData.if_ielts_is_waived_conditions_for
                  : "NA"
              }}
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- University Payment Modal -->
    <b-modal :no-close-on-backdrop="true" ref="universityPaymentModal" no-close-on-esc no-close-on-backdrop centered
      @hidden="onUniversityPaymentModalClose" hide-footer size="lg" title="Add University Payment">
      <b-row style="gap: 20px 0">

        <b-col cols="12">
          <label>Fee Type</label>
          <v-select v-model="university_payment_form.fee_type" :options="fee_type_options" label="label"
            :reduce="(item) => item.value" placeholder="Fee Type"></v-select>

        </b-col>

        <b-col cols="12">
          <label>Student Application</label>
          <v-select cols="4" v-model="university_payment_form.application_id" :options="studentApplications"
            label="country_name" :reduce="(item) => item.sa_mapper_id" placeholder="Student Application">

            <template #option="data">
              <div>
                # {{ data.sa_mapper_id }} - {{ data.university_name }} ({{ data.course_name }})
              </div>
            </template>

            <template #selected-option="data">
              <div>
                # {{ data.sa_mapper_id }} - {{ data.university_name }} ({{ data.course_name }})
              </div>
            </template>

          </v-select>
        </b-col>

        <b-col cols="12">
          <label>University</label>
          <v-select cols="4" v-model="university_payment_form.fw_university_id" :options="flyware_universities"
            label="name" :reduce="(item) => item.id" placeholder="University" @input="onUniversitySelect"></v-select>
        </b-col>

        <b-col cols="4">
          <label>Currency</label>
          <b-form-input disabled v-model="university_payment_form.currency" />
        </b-col>

        <b-col cols="8">
          <label>Amount</label>
          <b-form-input type="number" v-model="university_payment_form.amount" />
        </b-col>

        <b-col cols="12">
          <label>Remark</label>
          <b-form-input v-model="university_payment_form.label" />
        </b-col>

        <b-col cols="12" class="d-flex justify-content-end">
          <b-button variant="primary" @click="makeUniversityPayment">Create Payment</b-button>
        </b-col>


      </b-row>
    </b-modal>

    <div v-if="['agent', 'franchise', 'student_support'].includes(user_type)" class="mb-2">
      <b-link @click="$router.push('/universitysearch')">
        <b-button variant="outline-primary">Add Application</b-button>
      </b-link>
    </div>

    <b-card>
      <b-tabs card>
        <!-- Applications -->
        <b-tab title="Student Applications">
          <!-- <template #header>
        <h2>Student Applications</h2>
      </template> -->
          <b-table ref="refStudentApplicationsTable" class="position-relative" :items="studentApplications" responsive
            :fields="studentApplicationsColumns" primary-key="course_id">
            <template #cell(No)="data">
              <div style="text-align: center">
                <b> {{ data.index + 1 }}</b>
              </div>
            </template>
            <template #cell(university_name)="data">
              <div style="width: max-content">
                {{ data.value }}
              </div>
            </template>
            <template #cell(intake)="data">
              <div style="width: max-content">
                {{ formatCourseIntake(data.value) }}
              </div>
            </template>

            <template #cell(course_fee_pa)="data">
              <div style="width: max-content">
                {{ data.value.toLocaleString("en-IN") }}
                {{ data.item.currency }}
              </div>
            </template>
            <template #cell(course_year)="data">
              <div style="width: max-content">
                <div v-if="data.value">{{ data.value }} Year(s)</div>
                <div v-if="data.item.course_month" style="margin-top: 10px">
                  {{ data.item.course_month }} Month(s)
                </div>
              </div>
            </template>

            <template #cell(course_name)="data">
              <div style="width: max-content">
                {{ data.value }}
              </div>
              <div class="d-flex align-items-center">
                <!-- External Link Icon -->
                <a :href="data.item.additional_information_link" style="margin-right: 5px; margin-left: 10px"
                  target="_blank">
                  <b-avatar variant="light-danger" size="30">
                    <feather-icon icon="ExternalLinkIcon" size="15" class="text-danger" />
                  </b-avatar>
                </a>
                <div>
                  <a @click="() => openDetailModal(data.item)" style="margin-right: 3px">
                    <b-avatar variant="light-danger" size="30">
                      <feather-icon icon="InfoIcon" size="15" class="text-danger" /> </b-avatar></a>
                </div>
                <div>
                  <b-badge :variant="data.item.is_direct === 'Y' ? 'success' : 'danger'" class="ml-2">
                    {{ data.item.is_direct === 'Y' ? 'Direct' : 'Indirect' }}
                  </b-badge>

                </div>
              </div>
              <div class="">
                <div class="d-flex mt-1">
                  <span class="badge badge-secondary mr-1" v-if="data.item.gre_requirement === 'Y'" style="
                      color: #fff;
                      background-color: #00e87c;
                      border-radius: 9999px;
                    ">GRE Required</span>
                  <span class="badge badge-secondary mr-1" v-if="
                    data.item.gre_score !== null &&
                    !isNaN(data.item.gre_score)
                  " style="
                      color: #fff;
                      background-color: #00e87c;
                      border-radius: 9999px;
                    ">
                    {{ data.item.gre_score }}
                  </span>
                  <span class="badge badge-secondary mr-1" v-if="data.item.fifteen_years_education_acceptable === 'Y'"
                    style="
                      color: #fff;
                      background-color: #00e87c;
                      border-radius: 9999px;
                    ">15 years accepted</span>
                  <span class="badge badge-secondary mr-1" v-if="data.item.ielts_waiver === 'Y'" style="
                      color: #fff;
                      background-color: #00e87c;
                      border-radius: 9999px;
                    ">IELTS Waiver</span>
                  <span class="badge badge-secondary mr-1" v-if="data.item.stem_course === 'Y'" style="
                      color: #fff;
                      background-color: #00e87c;
                      border-radius: 9999px;
                    ">STEM</span>
                  <span class="badge badge-secondary mr-1" v-if="
                    data.item.sat_score !== null &&
                    !isNaN(data.item.sat_score)
                  " style="
                      color: #fff;
                      background-color: #00e87c;
                      border-radius: 9999px;
                    ">
                    {{ data.item.sat_score }}
                  </span>
                </div>
              </div>
            </template>

            <template #cell(application_chat)="data">
              <div>
                <feather-icon :badge="data.item.unread_chat_count" style="cursor: pointer" icon="MessageSquareIcon"
                  size="20" @click="showApplicationChatModal(data.item.sa_mapper_id)" />
              </div>
            </template>

            <template #cell(status_change_date)="data">
              <div style="width: max-content">
                {{
                  data.item.status_change_date
                    ? getDateTimeFormatted(data.item.status_change_date)
                    : ""
                }}
              </div>
            </template>

            <template #cell(commission)="data">
              <div v-if="user_type === 'counselor'">
                <b-form-select :value="data.item.commission" @input="onCommission($event, data.item.sa_mapper_id)">
                  <b-form-select-option value="25">25</b-form-select-option>
                  <b-form-select-option value="50">50</b-form-select-option>
                  <b-form-select-option value="75">75</b-form-select-option>
                </b-form-select>
              </div>
              <div v-else-if="user_type === 'agent'">
                {{ data.item.commission }}
              </div>
            </template>

            <template #cell(applied_on)="data">
              <div style="width: max-content">
                {{
                  data.item.applied_on
                    ? getDateTimeFormatted(data.item.applied_on)
                    : getDateTimeFormatted(data.item.sa_created_at)
                }}
              </div>
            </template>

            <!-- Hrishikesh copy this for pages/StudentApplications/StudentApplications -->
            <template #cell(application_status)="data">
              <div class="d-flex" style="gap: 10px">
                <div>
                  <b-form-select style="width: auto" v-if="['operations', 'visa'].includes(user_type)"
                    v-model="data.item.status_id" @change="() => { data.item.sub_status_id = null }">
                    <b-form-select-option v-for="(item, index) in application_statuses" :key="'statuses_ ' + index"
                      :value="item.status_id">
                      {{ item.status_name }}
                    </b-form-select-option>
                  </b-form-select>
                  <p v-else style="text-wrap: nowrap">
                    {{ data.item.status_name }}
                  </p>
                </div>
                <button class="btn btn-sm">
                  <feather-icon style="cursor: pointer" icon="InfoIcon" size="20"
                    @click="showApplicationStatusModal(data.item.status_id)" />
                </button>
              </div>
            </template>
            <template #cell(course_net_fees)="slotProps">
              <div style="width: max-content">
                <b-form-input  style="width: auto" :disabled="slotProps.item.status_id < 5"
                  v-model="slotProps.item.course_net_fees" />
              </div>
            </template>

            <template #cell(course_start_date)="slotProps">
              <div style="width: max-content">
                <span v-if="slotProps.item.course_start_date">{{ getDateTimeFormatted(slotProps.item.course_start_date)
                  }}</span>
                <flat-pickr v-else :disabled="slotProps.item.status_id < 5" style="width: auto"
                  v-model="slotProps.item.course_start_date" class="form-control" />
              </div>
            </template>

            <template #cell(scholarship_amount)="slotProps">
              <div style="width: max-content">
               <b-form-input  style="width: auto" :disabled="slotProps.item.status_id < 5"
               v-model="slotProps.item.scholarship_amount" />
              </div>
            </template>

            <template #cell(application_sub_status)="data">
              <div class="d-flex" style="gap: 10px">
                <div v-if="applicationHasSubStatus(data.item.status_id)">
                  <b-form-select style="width: 300px" v-if="['operations', 'visa'].includes(user_type)"
                    v-model="data.item.sub_status_id">
                    <b-form-select-option v-for="(item, index) in getFilteredApplicationSubStatuses(
                      data.item.status_id
                    )" :key="'statuses_ ' + index" :value="item.sub_status_id">
                      {{ item.sub_status_name }}
                    </b-form-select-option>
                  </b-form-select>
                  <p v-else style="text-wrap: nowrap">
                    {{ data.item.sub_status_name && "-" }}
                    {{ data.item.sub_status_name }}
                  </p>
                </div>
              </div>
            </template>
            <template #cell(comments)="data">
              <b-form-input style="width: auto" v-if="['operations', 'visa'].includes(user_type)"
                v-model="data.item.comments" />
              <p style="width: auto" v-else>{{ data.item.comments }}</p>
            </template>

            <template #cell(tags)="data">
              <b-form-input style="width: auto" v-if="['operations', 'visa'].includes(user_type)"
                v-model="data.item.tags" />
              <p style="width: auto" v-else>{{ data.item.tags }}</p>
            </template>

            <template v-if="['agent', 'franchise', 'student_support'].includes(user_type)
              " #cell(cancel)="data">
              <div role="button" class="text-center" @click="(e) => {
                cancelApplication(data.item);
              }
                ">
                <b-avatar variant="light-danger" size="30">
                  <feather-icon icon="TrashIcon" size="15" class="text-danger" />
                </b-avatar>
              </div>
            </template>

            <template #cell(save)="data">
              <div class="d-flex" style="gap: 10px">
                <button class="btn btn-sm btn-outline-success" v-if="['operations', 'visa'].includes(user_type)"
                  @click="() => saveStudentApplication(data.item)">
                  SAVE
                </button>
              </div>
            </template>
          </b-table>
        </b-tab>

        <!-- University Payments -->
        <b-tab title="University Payments">
          <div>
            <div class="d-flex justify-content-end mb-1 w-100" v-if="['operations', 'visa'].includes(user_type)">
              <b-button variant="primary" @click="showUniversityPaymentModal">Add
                Payment</b-button>
            </div>
            <b-table id="StudentsApplicationsTableId" ref="refStudentsApplicationsTable"
              class="position-relative border rounded-lg" :items="university_payments" responsive
              :fields="universityPaymentTableColumns" primary-key="sa_mapper_id" show-empty
              empty-text="No matching records found">

              <template #cell(No)="data">
                {{ university_payments.indexOf(data.item) + 1 }}
              </template>

              <template #cell(fee_type)="data">
                {{ fee_type_options.find(up => up.value === data.item.fee_type) && fee_type_options.find(up => up.value
                  === data.item.fee_type).label }}
              </template>

              <template #cell(label)="data">
                <div style="width: max-content">
                  <p :style="data.item.is_active === 'N' && 'text-decoration: line-through'">{{ data.item.label }}
                  </p>
                </div>
              </template>

              <template #cell(fw_university_name)="data">
                <div style="width: max-content">
                  <p :style="data.item.is_active === 'N' && 'text-decoration: line-through'">{{
                    data.item.fw_university_name }}
                  </p>
                </div>
              </template>

              <template #cell(currency)="data">
                <div style="width: max-content">
                  <p :style="data.item.is_active === 'N' && 'text-decoration: line-through'">{{
                    data.item.currency }}
                  </p>
                </div>
              </template>

              <template #cell(amount)="data">
                <div style="width: max-content">
                  <p :style="data.item.is_active === 'N' && 'text-decoration: line-through'">{{
                    data.item.amount }}
                  </p>
                </div>
              </template>

              <template #cell(payment_status)="data">
                <div style="width: max-content">
                  <b-badge pill :variant="payment_status_map[data.item.payment_status].variant"
                    v-if="data.item.is_active === 'Y'">
                    {{ payment_status_map[data.item.payment_status].label }}
                  </b-badge>
                </div>
              </template>

              <template #cell(actions)="data">
                <div style="width: max-content" v-if="['operations', 'visa'].includes(user_type)">
                  <p v-if="data.item.is_active === 'Y' && (data.item.payment_status === 'pending' || data.item.payment_status === 'failed')"
                    class="text-danger cursor-pointer" @click="() => cancelPayment(data.item.up_id)">
                    Cancel
                  </p>

                  <b-badge v-if="data.item.is_active === 'N'" pill variant="danger">Cancelled</b-badge>
                </div>
                <div v-else>
                  <b-badge pill variant="danger" v-if="data.item.is_active === 'N'">Cancelled</b-badge>
                </div>
              </template>

            </b-table>

          </div>
        </b-tab>

        <!-- Favourites -->
        <b-tab v-if="!['operations', 'visa'].includes(user_type)" title="Student Favourites">
          <b-table ref="refStudentFavouritesTable" class="position-relative" :items="studentFavourites" responsive
            :fields="studentFavouritesColumns" primary-key="course_id">
            <template #cell(university_name)="data">
              <div style="width: max-content">
                {{ data.value }}
              </div>
            </template>
            <template #cell(course_fee_pa)="data">
              <div style="width: max-content">
                {{ data.value.toLocaleString("en-IN") }}
                {{ data.item.currency }}
              </div>
            </template>
            <template #cell(course_name)="data">
              <div style="width: max-content">
                {{ data.value }}
              </div>
              <div class="d-flex align-items-center">
                <!-- {{ data.value }} -->
                <!-- External Link Icon -->
                <a :href="data.item.additional_information_link" style="margin-right: 5px; margin-left: 10px"
                  target="_blank">
                  <b-avatar variant="light-danger" size="30">
                    <feather-icon icon="ExternalLinkIcon" size="15" class="text-danger" />
                  </b-avatar>
                </a>
                <div>
                  <a @click="() => openDetailModal(data.item)" style="margin-right: 3px">
                    <b-avatar variant="light-danger" size="30">
                      <feather-icon icon="InfoIcon" size="15" class="text-danger" /> </b-avatar></a>
                </div>
              </div>
              <div class="">
                <div class="d-flex mt-1">
                  <span class="badge badge-secondary mr-1" v-if="data.item.gre_requirement === 'Y'" style="
                      color: #fff;
                      background-color: #00e87c;
                      border-radius: 9999px;
                    ">GRE Required</span>
                  <span class="badge badge-secondary mr-1" v-if="
                    data.item.gre_score !== null &&
                    !isNaN(data.item.gre_score)
                  " style="
                      color: #fff;
                      background-color: #00e87c;
                      border-radius: 9999px;
                    ">
                    {{ data.item.gre_score }}
                  </span>
                  <span class="badge badge-secondary mr-1" v-if="data.item.fifteen_years_education_acceptable === 'Y'"
                    style="
                      color: #fff;
                      background-color: #00e87c;
                      border-radius: 9999px;
                    ">15 yrs edu accepted</span>
                  <span class="badge badge-secondary mr-1" v-if="data.item.ielts_waiver === 'Y'" style="
                      color: #fff;
                      background-color: #00e87c;
                      border-radius: 9999px;
                    ">IELTS Waiver</span>
                  <span class="badge badge-secondary mr-1" v-if="data.item.stem_course === 'Y'" style="
                      color: #fff;
                      background-color: #00e87c;
                      border-radius: 9999px;
                    ">Stem Course</span>
                  <span class="badge badge-secondary mr-1" v-if="
                    data.item.sat_score !== null &&
                    !isNaN(data.item.sat_score)
                  " style="
                      color: #fff;
                      background-color: #00e87c;
                      border-radius: 9999px;
                    ">{{ data.item.sat_score }}</span>
                </div>
              </div>
            </template>
            <template #cell(course_year)="data">
              <div style="width: max-content">
                <div v-if="data.value">{{ data.value }} Year(s)</div>
                <div v-if="data.item.course_month" style="margin-top: 10px">
                  {{ data.value }} Month(s)
                </div>
              </div>
            </template>
            <template #cell(intake)="data">
              <div style="width: max-content">
                {{ formatCourseIntake(data.value) }}
              </div>
            </template>

            <template #cell(course_month)="data">
              <div style="width: max-content">
                <div>{{ data.value }}</div>
              </div>
            </template>
          </b-table>
        </b-tab>

        <!-- Recommendations -->
        <b-tab v-if="!['operations', 'visa'].includes(user_type)" title="Aliff Recommendations">
          <b-table ref="refSuggestedCoursesTable" class="position-relative" :items="suggestedCourses" responsive
            :fields="suggestedCoursesColumns" primary-key="course_id">
            <template #cell(university_name)="data">
              <div style="width: max-content">
                {{ data.value }}
              </div>
            </template>
            <template #cell(course_fee_pa)="data">
              <div style="width: max-content">
                {{ data.value.toLocaleString("en-IN") }}
                {{ data.item.currency }}
              </div>
            </template>
            <template #cell(course_name)="data">
              <div style="width: max-content">
                {{ data.value }}
              </div>
              <div class="d-flex align-items-center">
                <!-- {{ data.value }} -->
                <!-- External Link Icon -->
                <a :href="data.item.additional_information_link" style="margin-right: 5px; margin-left: 10px"
                  target="_blank">
                  <b-avatar variant="light-danger" size="30">
                    <feather-icon icon="ExternalLinkIcon" size="15" class="text-danger" />
                  </b-avatar>
                </a>
                <div>
                  <a @click="() => openDetailModal(data.item)" style="margin-right: 3px">
                    <b-avatar variant="light-danger" size="30">
                      <feather-icon icon="InfoIcon" size="15" class="text-danger" /> </b-avatar></a>
                </div>
              </div>
              <div class="">
                <div class="d-flex mt-1">
                  <span class="badge badge-secondary mr-1" v-if="data.item.gre_requirement === 'Y'" style="
                      color: #fff;
                      background-color: #00e87c;
                      border-radius: 9999px;
                    ">GRE Required</span>
                  <span class="badge badge-secondary mr-1" v-if="
                    data.item.gre_score !== null &&
                    !isNaN(data.item.gre_score)
                  " style="
                      color: #fff;
                      background-color: #00e87c;
                      border-radius: 9999px;
                    ">
                    {{ data.item.gre_score }}
                  </span>
                  <span class="badge badge-secondary mr-1" v-if="data.item.fifteen_years_education_acceptable === 'Y'"
                    style="
                      color: #fff;
                      background-color: #00e87c;
                      border-radius: 9999px;
                    ">15 years accepted</span>
                  <span class="badge badge-secondary mr-1" v-if="data.item.ielts_waiver === 'Y'" style="
                      color: #fff;
                      background-color: #00e87c;
                      border-radius: 9999px;
                    ">IELTS Waiver</span>
                  <span class="badge badge-secondary mr-1" v-if="data.item.stem_course === 'Y'" style="
                      color: #fff;
                      background-color: #00e87c;
                      border-radius: 9999px;
                    ">STEM</span>
                  <span class="badge badge-secondary mr-1" v-if="
                    data.item.sat_score !== null &&
                    !isNaN(data.item.sat_score)
                  " style="
                      color: #fff;
                      background-color: #00e87c;
                      border-radius: 9999px;
                    ">{{ data.item.sat_score }}</span>
                </div>
              </div>
            </template>
            <template #cell(course_year)="data">
              <div style="width: max-content">
                <div v-if="data.value">{{ data.value }} Year(s)</div>
                <div v-if="data.item.course_month" style="margin-top: 10px">
                  {{ data.item.course_month }} Month(s)
                </div>
              </div>
            </template>
            <template #cell(intake)="data">
              <div style="width: max-content">
                {{ formatCourseIntake(data.value) }}
              </div>
            </template>
            <template #cell(action)="data">
              <div class="text-center">
                <b-dropdown text="Actions" variant="secondary">
                  <b-dropdown-item v-if="
                    data.item.student_response === 'pending' &&
                    ['agent', 'franchise', 'student_support'].includes(
                      user_type
                    )
                  " @click="acceptSuggestionForStudent(data.item.course_id)">
                    Accept
                  </b-dropdown-item>
                  <b-dropdown-item v-if="
                    data.item.student_response === 'pending' &&
                    ['agent', 'franchise', 'student_support'].includes(
                      user_type
                    )
                  " @click="rejectSuggestionForStudent(data.item.course_id)">
                    Reject
                  </b-dropdown-item>
                  <b-dropdown-item @click="removeRecommendation(data.item.course_id)">
                    Remove
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </b-table>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormTextarea,
  BButton,
  BTable,
  BMedia,
  BModal,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BFormSelect,
  BFormSelectOption,
  BImg,
  BTabs,
  BTab,
} from "bootstrap-vue";
// import JsonExcel from "vue-json-excel";
import CommonServices from "@/apiServices/CommonServices";
import store from "@/store";
import OperationsServices from "@/apiServices/OperationsServices";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import AgentServices from "@/apiServices/AgentServices";
import helpers from "@/libs/helpers";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import CounselorServices from "@/apiServices/CounselorServices";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BFormInput,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BFormSelectOption,
    BImg,
    vSelect,
    // JsonExcel,
    FeatherIcon,
    BFormTextarea,
    BTabs,
    BTab,
    flatPickr
  },
  data() {
    return {
      popupData: null,
      student_user_id: null,
      modalVisible: false,
      application_limit: 0,

      application_statuses: [],
      application_sub_statuses: [],
      filtered_application_sub_statuses: [],
      university_payments: [],

      studentApplications: [],
      studentFavourites: [],
      suggestedCourses: [],

      sa_mapper_id: null,
      chatMessage: "",
      applicationChat: [],
      getApplicationChatInterval: null,
      currentApplicationStatus: null,

      // studentApplicationsExcelFeild: {
      //   Country: "country_name",
      //   University: "university_name",
      //   Course: "course_name",
      //   Level: "course_level_name",
      //   Category: "course_category_name",
      //   "Intake Month": "intake_month",
      //   "Intake Year": "intake_year",
      //   Status: "application_status",
      //   "Status Change Date": "status_change_date",
      //   "Applicaion Date": "applied_on",
      // },

      studentApplicationsColumns: [
        {
          key: "No",
          label: "Priority",
          stickyColumn: true,
          variant: "primary",
        },
        { key: "sa_mapper_id", label: "Application Id" },
        { key: "country_name", label: "Country" },
        { key: "university_name", label: "University" },
        { key: "course_name", label: "Course" },
        { key: "course_year", label: "Duration" },
        { key: "intake", label: "Intake Months" },
        { key: "course_fee_pa", label: "Course Fee (P.A.)" },

        { key: "sa_campus_locations", label: "Campus Locations" },

        // { key: "course_level_name", label: "Level" },
        // { key: "course_category_name", label: "Category" },
        { key: "intake_month", label: "Intake Month" },
        { key: "intake_year", label: "Intake Year" },
        { key: "application_chat", label: "Chat" },
        { key: "application_status", label: "Status" },
        { key: "application_sub_status", label: "Sub Status" },
        { key: "comments", label: "Comments" },
        { key: "tags", label: "Tags" },
        {
          key: "status_change_date",
          label: "Status Change Date",
          // formatter: helpers.getDateTimeFormatted,
        },
        {
          key: "applied_on",
          label: "Application Date",
          // formatter: helpers.getDateTimeFormatted,
        },

      ],

      universityPaymentTableColumns: [
        { key: "No", label: "#" },
        { key: "fee_type", label: "Fee Type" },
        { key: "application_id", label: "Application" },
        { key: "fw_university_name", label: "University" },
        { key: "currency", label: "Currency" },
        { key: "amount", label: "Amount" },
        { key: "label", label: "Remark" },
        { key: "payment_status", label: "Payment Status" },
        { key: "actions", label: "Actions" },
      ],

      studentFavouritesColumns: [
        // { key: "No", label: "#" },
        { key: "country_name", label: "Country" },
        { key: "university_name", label: "University" },
        { key: "course_name", label: "Course" },

        { key: "course_year", label: "Duration" },
        { key: "intake", label: "Intake Months" },
        // {key:"course_month", label:"Duration (Months)"},
        { key: "course_fee_pa", label: "Fee (P.A.)" },
        // { key: "course_level_name", label: "Level" },
        // { key: "course_category_name", label: "Category" },
      ],

      suggestedCoursesColumns: [
        // { key: "No", label: "#" },
        { key: "country_name", label: "Country" },
        { key: "university_name", label: "University" },
        { key: "course_name", label: "Course" },

        { key: "course_year", label: "Duration" },
        { key: "intake", label: "Intake Months" },
        // {key:"course_month", label:"Duration (Months)"},

        { key: "course_fee_pa", label: "Fee (P.A.)" },
        // { key: "course_level_name", label: "Level" },
        // { key: "course_category_name", label: "Category" },
        { key: "student_response", label: "Student Response" },
        // { key: "apply" },
        // { key: "reject" },
        // { key: "action", label: "Action" },
      ],

      on_commission: null,

      commentsDebounce: null,

      university_payment_form: {
        up_id: null,
        fw_university_id: "",
        fee_type: "",
        currency: "",
        label: "",
        amount: "",
        student_user_id: null,
        application_id: null
      },
      flyware_universities: [],
      flyware_currencies: [],

      fee_type_options: [
        {
          value: "deposit",
          label: "Deposit"
        },
        {
          value: "advance_fee",
          label: "Advance Fee"
        },
        {
          value: "application_fee",
          label: "Application Fee"
        },
        {
          value: "other",
          label: "Other"
        },
      ],

      payment_status_map: {
        pending: {
          variant: "primary",
          label: "Pending",
        },
        ongoing: {
          variant: "secondary",
          label: "Ongoing",
        },
        success: {
          variant: "success",
          label: "Success",
        },
        failed: {
          variant: "danger",
          label: "Failed",
        },
        guaranteed: {
          variant: "success",
          label: "Processing"
        }

      },
    };
  },

  computed: {
    user_type() {
      if (store.getters["user/getUserDetails"].user_type) {
        let type = store.getters["user/getUserDetails"].user_type;
        if (["agent", "franchise", "student_support"].includes(type)) {
          if (
            !this.studentApplicationsColumns.includes({
              key: "cancel",
              label: "Cancel",
            })
          ) {
            this.studentApplicationsColumns.push({
              key: "cancel",
              label: "Cancel",
            });
          }
        }
        if (['visa', 'operations'].includes(type)) {
          if (
            !this.studentApplicationsColumns.includes({
              key: "course_net_fees",
              label: "Course Fees (NET)",
            })
          ) {
            this.studentApplicationsColumns.push({
              key: "course_net_fees",
              label: "Course Fees (NET)",
            });
          }
          if (
            !this.studentApplicationsColumns.includes({
              key: "course_start_date",
              label: "Course Fees (NET)",
            })
          ) {
            this.studentApplicationsColumns.push({
              key: "course_start_date",
              label: "Course Start",
            });
          }
          if (
            !this.studentApplicationsColumns.includes({
              key: "scholarship_amount",
              label: "Scholarship Amount",
            })
          ) {
            this.studentApplicationsColumns.push({
              key: "scholarship_amount",
              label: "Scholarship Amount",
            });
          }
        }
        if (
          ["counselor", "agent", "franchise", "student_support"].includes(type)
        ) {
          if (
            !this.suggestedCoursesColumns.includes({
              key: "action",
              label: "Action",
            })
          ) {
            this.suggestedCoursesColumns.push({
              key: "action",
              label: "Action",
            });
          }
        }
        if (["counselor", "agent"].includes(type)) {
          if (
            !this.studentApplicationsColumns.includes({
              key: "commission",
              label: "Commission %",
            })
          ) {
            this.studentApplicationsColumns.push({
              key: "commission",
              label: "Commission %",
            });
          }
        }
        if (["operations", "visa", "operations_manager"].includes(type)) {
          if (
            !this.studentApplicationsColumns.includes({
              key: "save",
              label: "Save",
            })
          ) {
            this.studentApplicationsColumns.push({
              key: "save",
              label: "Save",
            });
          }
        }
        return type;
      } else {
        return null;
      }
    },
  },

  methods: {
    getFilteredApplicationSubStatuses(status_id) {
      return this.application_sub_statuses.filter(
        (sub_status) => sub_status.status_id === status_id
      );
    },

    applicationHasSubStatus(status_id) {
      return this.application_sub_statuses.find(
        (sub_status) => sub_status.status_id === status_id
      )
        ? true
        : false;
    },

    resetUniversityPaymentForm() {
      this.university_payment_form = {
        up_id: null,
        fw_university_id: "",
        fee_type: "",
        label: "",
        amount: "",
        student_user_id: null,
        application_id: null
      }
    },

    showUniversityPaymentModal() {
      this.university_payment_form.student_user_id = this.student_user_id;
      this.$refs["universityPaymentModal"].show();
    },

    async cancelPayment(up_id) {
      try {
        const res = await OperationsServices.cancelUniversityPayment(up_id)
        if (res.data.status) {

          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "University Payment Cancelled",
              icon: 'CheckIcon',
              variant: 'success',
            }
          });

          this.getAllUniversityPayments()

        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Failed To Cancel University Payment",
              icon: 'XIcon',
              variant: 'failure',
            }
          });
        }



      } catch (error) {
        console.error(error);
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Failed To Cancel University Payment",
            icon: 'XIcon',
            variant: 'failure',
          }
        });
      }

    },

    validateUniversityPayment() {

      const { fw_university_id, fee_type, amount, student_user_id, application_id } = this.university_payment_form

      console.log({ uf: this.university_payment_form });


      if (!fw_university_id || !fee_type || !amount || !student_user_id || !application_id) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Please Fill All Details",
            icon: 'XIcon',
            variant: 'failure',
          }
        });

        return false
      }

      return true
    },

    async makeUniversityPayment() {
      try {

        if (!this.validateUniversityPayment()) return

        const res = await OperationsServices.saveUniversityPayment(this.university_payment_form)

        if (res.data.status) {

          this.$refs["universityPaymentModal"].hide();

          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "University Payment Added",
              icon: 'CheckIcon',
              variant: 'success',
            }
          });

          this.getAllUniversityPayments()

        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Failed to Save University Payment",
              icon: 'XIcon',
              variant: 'failure',
            }
          });
        }

      } catch (error) {
        console.error(error);
      }
    },

    onUniversityPaymentModalClose() {
      // this.$refs["universityPaymentModal"].hide();
      this.resetUniversityPaymentForm()
    },

    async getAllUniversityPayments() {
      try {
        const res = await CommonServices.getAllUniversityPayments({
          student_user_id: this.student_user_id
        })
        if (res.data.status) {
          this.university_payments = res.data.data
        }

      } catch (error) {
        console.error(error);
      }
    },

    async getAllFlywireUniversities() {
      try {
        const res = await CommonServices.getAllFlywireUniversities()
        if (res.data.status) {
          // Change For Production
          this.flyware_universities = res.data.data.filter(u => u.id.startsWith("FLYWIRE:"))
        }
      } catch (error) {
        console.error(error);
      }
    },

    async getAllFlywireCurrencies() {
      try {
        const res = await CommonServices.getAllFlywireCurrencies()
        if (res.data.status) {
          this.flyware_currencies = res.data.data
        }
      } catch (error) {
        console.error(error);
      }
    },

    async onUniversitySelect() {
      // this.university_payment_form.currency = 
      const res = await CommonServices.getSingleFlywireUniversity(this.university_payment_form.fw_university_id)
      if (res.data.status) {
        this.university_payment_form.currency = res.data.data.currency
      }
    },


    // async changeApplicationStatus(status_id, application_id) {
    //   this.changeApplicationSubStatus(null, application_id);
    //   try {
    //     const response = await OperationsServices.setApplicationStatus({
    //       student_user_id: this.student_user_id,
    //       status_id,
    //       application_id,
    //     });
    //     if (response.data.status) {
    //       this.$toast({
    //         component: ToastificationContentVue,
    //         props: {
    //           title: "Status Updated",
    //           icon: "Check",
    //           variant: "success",
    //         },
    //       });

    //       this.getStudentApplications(this.student_user_id);
    //     } else {
    //       this.$toast({
    //         component: ToastificationContentVue,
    //         props: {
    //           title: response.data.message || "Status not updated",
    //           icon: "X",
    //           variant: "failure",
    //         },
    //       });
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },

    // async changeApplicationSubStatus(sub_status_id, application_id) {
    //   try {
    //     const response = await OperationsServices.setApplicationSubStatus({
    //       student_user_id: this.student_user_id,
    //       sub_status_id,
    //       application_id,
    //     });
    //     if (response.data.status) {
    //       this.$toast({
    //         component: ToastificationContentVue,
    //         props: {
    //           title: "Sub Status Updated",
    //           icon: "Check",
    //           variant: "success",
    //         },
    //       });

    //       this.getStudentApplications(this.student_user_id);
    //     } else {
    //       this.$toast({
    //         component: ToastificationContentVue,
    //         props: {
    //           title: response.data.message || "Sub Status not updated",
    //           icon: "X",
    //           variant: "failure",
    //         },
    //       });
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },

    // async setApplicationComment(i, sa_mapper_id) {
    //   if (this.commentsDebounce) clearTimeout(this.commentsDebounce);

    //   this.commentsDebounce = setTimeout(async () => {
    //     const response = await OperationsServices.setApplicationExtraInfo({
    //       student_user_id: this.student_user_id,
    //       comments: i,
    //       application_id: sa_mapper_id,
    //     });
    //     if (response.data.status) {
    //       this.$toast({
    //         component: ToastificationContentVue,
    //         props: {
    //           title: "Comment Updated",
    //           icon: "Check",
    //           variant: "success",
    //         },
    //       });
    //       this.getStudentApplications(this.student_user_id);
    //     } else {
    //       this.$toast({
    //         component: ToastificationContentVue,
    //         props: {
    //           title: response.data.message || "Comment not updated",
    //           icon: "X",
    //           variant: "primary",
    //         },
    //       });
    //     }
    //   }, 1000);
    // },

    async saveStudentApplication(data) {
      try {
        const response = await OperationsServices.updateApplication({
          status_id: data.status_id,
          sub_status_id: data.sub_status_id,
          comment: data.comments,
          tags: data.tags,
          student_user_id: this.student_user_id,
          application_id: data.sa_mapper_id,
          course_net_fees: parseInt(data.course_net_fees),
          course_start_date: data.course_start_date,
          scholarship_amount: data.scholarship_amount,
        });

        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Application Updated",
              icon: "Check",
              variant: "success",
            },
          });


          if(data.status_id >= 5 && data.course_net_fees && data.course_start_date && data.scholarship_amount){
            this.generateWorkingInvoice(data.sa_mapper_id, this.student_user_id)
          }

          this.getStudentApplications(this.student_user_id);
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Application not updated",
              icon: "X",
              variant: "failure",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    async generateWorkingInvoice(application_id , student_user_id) {
      try {
        const payload = {
          application_id,
          student_user_id
        }
        const res = await OperationsServices.generateWorkingInvoice(payload)
        if (res.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Invoice Generated",
              icon: 'CheckIcon',
              variant: 'success',
            }
          });
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Failed To Generate Invoice",
              icon: 'XIcon',
              variant: 'failure',
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    onCommission(commission, sa_mapper_id) {
      if (this.on_commission) clearTimeout(this.on_commission);
      this.on_commission = setTimeout(async () => {
        const response =
          await CounselorServices.saveAgentCommissionForApplication({
            sa_mapper_id: sa_mapper_id,
            commission: commission,
            student_user_id: this.student_user_id,
          });

        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Success",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Failed",
              icon: "EditIcon",
              variant: "primary",
            },
          });
        }
      }, 1000);
    },

    onDetailModalClose() {
      this.popupData = {};
    },

    openDetailModal(data) {
      // console.log("popupData => ",data);
      this.popupData = data;
      this.$refs.detailModal.show();
    },

    formatCourseIntake(intake) {
      return intake;
    },

    async loadApplicationChat() {
      try {
        const res = await CommonServices.getApplicationChat({
          sa_mapper_id: this.sa_mapper_id,
          student_user_id: this.student_user_id,
        });

        this.applicationChat = res.data.data;
      } catch (error) {
        console.error("Error in loadApplicationChat", error);
      }
    },

    async showApplicationChatModal(sa_mapper_id) {
      this.$refs["applicationChatModal"].show();

      this.sa_mapper_id = sa_mapper_id;

      await this.loadApplicationChat();

      this.getApplicationChatInterval = setInterval(
        this.loadApplicationChat,
        5000
      );
    },

    onApplicationModalClose() {
      clearInterval(this.getApplicationChatInterval);
      this.applicationChat = [];
      this.$refs["applicationChatModal"].hide();
    },

    async showApplicationStatusModal(status_id) {
      this.$refs["applicationStatusModal"].show();
      this.currentApplicationStatus = this.application_statuses.find(
        (status) => status.status_id === status_id
      );
    },

    onApplicationStatusModalClose() {
      this.$refs["applicationStatusModal"].hide();
    },

    async sendApplicationMessage() {
      await CommonServices.sendApplicationChat({
        sa_mapper_id: this.sa_mapper_id,
        student_user_id: this.student_user_id,
        message: this.chatMessage,
      });
      this.chatMessage = "";
      await this.loadApplicationChat();
    },

    // getExcelData() {
    //   return this.studentApplications.map((application) => {
    //     return {
    //       ...application,
    //       application_status: application.status_id
    //         ? this.application_statuses.find(
    //             (as) => as.status_id == application.status_id
    //           ).status_name
    //         : "",
    //       applied_on: application.applied_on
    //         ? this.getDateTimeFormatted(application.applied_on)
    //         : this.getDateTimeFormatted(application.sa_created_at),
    //     };
    //   });
    // },

    getDateTimeFormatted: helpers.getDateTimeFormatted,
    async cancelApplication(course) {
      if (course.status_id > 1) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Cannot Cancel this Application",
            icon: "X",
            variant: "failure",
          },
        });
        return;
      }

      try {
        const response = await AgentServices.cancelApplicationForStudent({
          student_user_id: this.student_user_id,
          course_id: course.course_id,
        });
        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Application Canceled",
              icon: "Check",
              variant: "succes",
            },
          });
          this.getStudentApplications(this.student_user_id);
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Application Not Canceled",
              icon: "X",
              variant: "failure",
            },
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Error in canceling application",
            icon: "X",
            variant: "failure",
          },
        });
        console.log("Error in getting  ", err);
      }
    },


    async removeRecommendation(course_id) {
      try {
        if (!this.student_user_id) return;

        const response = await CommonServices.removeRecommendation({
          student_user_id: this.student_user_id,
          course_id,
        });
        if (response.data.status) {
          this.getStudentApplications(this.student_user_id);
        }
      } catch (error) {
        console.error("Error in canceling Suggestion ", error);
      }
    },

    async getStudentApplications(student_user_id) {
      try {
        const response = await CommonServices.getStudentApplications({
          student_user_id,
        });
        if (response.data.status) {
          const { applications, favourites, suggestions } = response.data.data;

          this.studentApplications = applications;
          this.studentFavourites = favourites;
          this.suggestedCourses = suggestions;

          // this.checkCounselor();
        }
      } catch (error) {
        console.error("Error in getting student applications ", error);
      }
    },

    async getApplicationStatuses() {
      try {
        const response = await CommonServices.getAllApplicationStatuses();

        if (response.data.status) {
          this.application_statuses = response.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getApplicationSubStatuses() {
      try {
        const response = await CommonServices.getAllApplicationSubStatuses();

        if (response.data.status) {
          this.application_sub_statuses = response.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async acceptSuggestionForStudent(course_id) {
      AgentServices.acceptSuggestionForStudent({
        student_user_id: this.student_user_id,
        course_id,
      })
        .then((response) => {
          if (response.data.status) {
            this.getStudentApplications(this.student_user_id);
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: response.data.message || "Success",
                icon: "Check",
                variant: "Success",
              },
            });
          }
        })
        .catch((err) => {
          console.log("Error in accepting course suggestion ", err);
        });
    },

    async rejectSuggestionForStudent(course_id) {
      AgentServices.rejectSuggestionForStudent({
        student_user_id: this.student_user_id,
        course_id,
      })
        .then((response) => {
          if (response.data.status) {
            this.getStudentApplications(this.student_user_id);
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: response.data.message || "Success",
                icon: "Check",
                variant: "Success",
              },
            });
          }
        })
        .catch((err) => {
          console.log("Error in rejecting course suggestion ", err);
        });
    },
  },

  beforeMount() {
    const { student_user_id } = this.$route.params;
    if (!isNaN(student_user_id)) {
      this.getStudentApplications(student_user_id);
      this.student_user_id = student_user_id;
      this.getApplicationStatuses();
      this.getApplicationSubStatuses();
      this.getAllUniversityPayments()
      this.getAllFlywireUniversities()
    } else {
      console.log("Student User Id is is not a number ", student_user_id);
    }
  },
};
</script>

<style>
.course-detail__sm-title {
  font-weight: bold;
  /* text-decoration: underline; */
  text-transform: uppercase;
}

.no-style-button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.AliifChat-chatbox {
  width: 460px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  overflow-x: hidden;
}

.chat-header {
  background-color: #00e87c;
  color: #fff;
  text-align: center;
  padding: 14px 0;
  font-weight: bold;
  border-bottom: 2px solid #5367ff;
}

.chat-messages {
  max-height: 500px;
  overflow-y: auto;
  padding: 10px;
  overflow-x: hidden;
}

.message {
  margin-bottom: 10px;
}

.user-info {
  margin-right: 10px;
}

.user-name {
  font-weight: bold;
}

.user-type {
  font-size: 12px;
  color: #777;
}

.message-text {
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 8px 12px;
  word-break: break-word;
}

.message-content {
  word-wrap: break-word;
}

.message-timestamp {
  font-size: 7px;
  padding-left: 5px;
  color: #777;
}

.chat-input {
  padding-top: 10px;
  margin-left: 15px;
  margin-right: 15px;

  border-top: 1px solid #5367ff;
}

.send-button {
  background-color: #00e87c;
  color: #5367ff;
  font-weight: bold;
  border: 1px solid #5367ff;
  padding: 8px 12px;
  border-radius: 20px;
  cursor: pointer;
}

.send-button:hover {
  background-color: #5367ff;
  color: #00e87c;
}

/* .per-page-selector {
  width: 90px;
} */
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
