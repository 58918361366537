<template>
    <div>

        <b-modal :no-close-on-backdrop="true" v-model="showPaymentModal" id="payment-status" ref="ref-payment-status"
            centered title="Payment Status" ok-only @ok="reloadPageWithoutParam">
            <section>
                <p v-if="payment_status === 'pending'">pending</p>
                <p v-if="payment_status === 'success'">success</p>
                <p v-if="payment_status === 'failed'">failed</p>
            </section>
        </b-modal>

        <!-- Address Modal -->
        <b-modal :no-close-on-backdrop="true" v-model="addressModal" title="Add Address" @ok="saveAddress">
            <b-form @submit.stop.prevent="saveAddress">
                <b-form-group label="Address Line 1">
                    <b-form-input v-model="address.ua_address_line_1"></b-form-input>
                </b-form-group>
                <b-form-group label="Address Line 2">
                    <b-form-input v-model="address.ua_address_line_2"></b-form-input>
                </b-form-group>
                <b-form-group label="Landmark">
                    <b-form-input v-model="address.ua_landmark"></b-form-input>
                </b-form-group>
                <b-form-group label="Country">
                    <b-form-input v-model="address.ua_country"></b-form-input>
                </b-form-group>
                <b-form-group label="State">
                    <b-form-input v-model="address.ua_state"></b-form-input>
                </b-form-group>
                <b-form-group label="City">
                    <b-form-input v-model="address.ua_city"></b-form-input>
                </b-form-group>
                <b-form-group label="Pincode">
                    <b-form-input type="number" v-model="address.ua_pincode"></b-form-input>
                </b-form-group>
            </b-form>
        </b-modal>

        <!-- <b-modal :no-close-on-backdrop="true" id="payment-mode-modal" ref="payment-mode-modal" centered title="Select Payment Mode"
            no-close-on-backdrop hide-footer>
            <b-row>
                <b-col cols="12" class="d-flex justify-content-end mt-2">
                    <b-button @click="makeStripePayment" v-if="order_details.so_currency === 'INR'" class="mr-1"
                        variant="primary">Pay Via Stripe
                    </b-button>
                    <b-button v-if="order_details.so_currency === 'INR'" @click="makePayUPayment" variant="primary">Pay
                        Via
                        PayU
                    </b-button>
                </b-col>

                <b-col cols="12 mt-2" v-if="order_details.so_currency === 'INR'">
                    <p class="text-right font-weight-bold">
                        NOTE: For UPI Payments choose PayU
                    </p>
                </b-col>
            </b-row>
        </b-modal> -->

        <form :action="PAYU_PAYMENT_LINK" target="_blank" method="post" v-show="false" ref="payu-form">
            <input type="hidden" name="key" :value="payUObj.key" />
            <input type="hidden" name="txnid" :value="payUObj.txnid" />
            <input type="hidden" name="productinfo" :value="payUObj.productinfo" />
            <input type="hidden" name="amount" :value="payUObj.amount" />
            <input type="hidden" name="email" :value="payUObj.email" />
            <input type="hidden" name="firstname" :value="payUObj.firstname" />
            <input type="hidden" name="lastname" :value="payUObj.lastname" />
            <input type="hidden" name="surl" :value="payUObj.surl" />
            <input type="hidden" name="furl" :value="payUObj.furl" />
            <input type="hidden" name="phone" :value="payUObj.phone" />
            <input type="hidden" name="hash" :value="payUObj.hash" />
            <input type="submit" value="submit" />
        </form>

        <b-card style="min-height: 800px" class="pb-5">
            <div class="h-100 w-100 d-flex justify-content-center">
                <div class="w-50 mt-5">
                    <div>
                        <h1 class="text-center">Place Your Order</h1>
                    </div>
                    <div class="d-flex flex-column align-items-end">
                        <div class="w-100 mt-2 d-flex justify-content-between align-items-center" style="gap: 20px">
                            <h3 class="mb-1 mt-2">Select Your Address</h3>
                            <b-button class="" variant="primary" @click="openAddressModal" size="sm">Add New</b-button>
                        </div>
                        <div v-if="!addresses || addresses.length === 0" class="text-left w-100">
                            <p>Please add an address to continue.</p>
                        </div>
                        <div v-else>
                            <b-card class="address-card">
                                <h5 class="mb-1">Billing Address</h5>
                                <b-form-radio-group v-model="order_details.so_billing_address_id"
                                    class="d-flex flex-column custom-radio" style="gap: 8px">
                                    <b-form-radio v-for="address in addresses" :value="address.ua_id"
                                        :key="'address_' + address.ua_id" class=" radio-click">
                                        <div
                                            :class="`radio-div ${address.ua_id === order_details.so_billing_address_id && 'checked-div'}`">
                                            <div class="pl-1 address-box">{{ formatAddress(address) }} , <span
                                                    style="color: var(--primary-1);"
                                                    @click="openAddressModal(address)">Edit
                                                    Address</span> | <span style="color: var(--primary-1);"
                                                    @click="deleteAddress(address)">Delete Address</span></div>
                                        </div>
                                    </b-form-radio>
                                </b-form-radio-group>
                            </b-card>
                            <b-form-checkbox v-model="same_address" class="mb-1">
                                Shipping Address same as Billing Address
                            </b-form-checkbox>
                            <b-card class="address-card" v-if="!same_address">
                                <h5 class="mb-1">Shipping Address</h5>
                                <b-form-radio-group class="d-flex flex-column custom-radio"
                                    v-model="order_details.so_shipping_address_id" style="gap: 8px">
                                    <b-form-radio v-for="address in addresses" :value="address.ua_id"
                                        :key="'shipping_address_' + address.ua_id" class="">
                                        <div
                                            :class="`radio-div ${address.ua_id === order_details.so_shipping_address_id && 'checked-div'}`">
                                            <div class="pl-1 address-box">{{ formatAddress(address) }} , <span
                                                    style="color: var(--primary-1);"
                                                    @click="openAddressModal(address)">Edit
                                                    Address</span> | <span style="color: var(--primary-1);"
                                                    @click="deleteAddress(address)">Delete Address</span></div>
                                        </div>
                                    </b-form-radio>
                                </b-form-radio-group>
                            </b-card>
                        </div>
                    </div>
                    <div class="mt-3">
                        <h3>Biling Information</h3>
                    </div>
                    <div>
                        <div class="bg-white rounded-lg" v-for="cart in cartItems" :key="cart.sc_id">
                            <div class="d-flex border rounded-lg mb-1"
                                style="min-height: 50px; height: fit-content; padding: 5px; gap:2px">
                                <img style="height:50px; width: 50px; object-fit: cover;" class="rounded-lg"
                                    :src="FILESURL + cart.images[0].spi_image" />
                                <div class="d-flex flex-column justify-content-start w-100"
                                    style="padding-top: 5px; padding-left: 5px;;">
                                    <h6 class="mb-0 pb-0">{{ cart.sp_name }}</h6>

                                    <div style="overflow-y: auto; width: fit-content;" class="pr-1">
                                        <div class="d-flex align-items-center p-0" style="font-size: 0.8rem;"
                                            v-for="item in cart.cart" :key="'item_' + item.sc_id">
                                            <p style="color: #999" class="m-0 p-0">{{ item.spqa_quantity }} peices for
                                                Rs.{{
                                                    item.spqa_amount }}
                                            </p>
                                        </div>
                                    </div>

                                    <!-- <div v-html="cart.sp_description" class="pr-5"></div> -->

                                    <!-- <p>{{ cart.total_quantity }}</p> -->
                                </div>
                                <div class="d-flex align-items-center pr-1">
                                    <h6 class="text-nowrap m-0 p-0">Rs.{{ cart.total_price }} ({{ cart.total_quantity }}
                                        pieces)
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-2">
                        <h3>GST (Optional)</h3>
                        <div class="d-flex mb-2">
                            <b-form-input v-model="gst_number" placeholder="09AAACH7409R1ZZ"></b-form-input>
                            <b-button variant="primary" size="sm" class="ml-2" @click="saveGST">Save</b-button>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                        <h3 class="m-0 p-0">Total Rs.{{ cartTotal }}</h3>
                        <b-button variant="primary" size="sm" @click="showPaymentModeModal"> Pay Now</b-button>
                    </div>
                </div>

            </div>
        </b-card>
    </div>
</template>


<script>
import {
    BCard,
    BCol,
    BRow,
    BButton,
    BFormInput,
    BModal,
    BFormRadio,
    BFormRadioGroup,
    BFormTextarea,
    BFormCheckbox,
    BFormGroup,
    BCarousel,
    BCarouselSlide,
    BTable
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AgentServices from "@/apiServices/AgentServices";
import getSymbolFromCurrency from "currency-symbol-map";
import CommonServices from "@/apiServices/CommonServices";
import { BASEURL, FILESURL, PAYU_PAYMENT_LINK } from "@/config/index";

export default {
    components: {
        BCard,
        BCol,
        BRow,
        BButton,
        BFormInput,
        BModal,
        ToastificationContent,
        BFormRadio,
        BFormRadioGroup,
        BFormTextarea,
        BFormCheckbox,
        BFormGroup,
        BCarousel,
        BCarouselSlide,
        BTable
    },
    data() {
        return {
            products: [],
            cartItems: [],
            cartTotal: 0,
            BASEURL,
            FILESURL,
            PAYU_PAYMENT_LINK,
            sp_id: null,
            product: null,
            same_address: false,
            showPaymentModal: false,
            order_details: {
                sp_id: null,
                spqa_id: null,
                so_currency: "INR",
                so_billing_address_id: null,
                so_shipping_address_id: null,
                so_quantity_amount: 0,
                so_total_amount: 0
            },
            address: {
                ua_address_line_1: "",
                ua_address_line_2: "",
                ua_landmark: "",
                ua_country: "",
                ua_state: "",
                ua_city: "",
                ua_pincode: null,
            },
            gst_number:'',
            statusOff: 'N',
            addresses: [],
            addressModal: false,
            final_order: null,
            payUObj: {
                key: "",
                surl: "",
                furl: "",
                amount: null,
                productinfo: "",
                firstname: "",
                lastname: "",
                email: "",
                phone: null,
                txnid: "",
                hash: "",
            },
            payment_status: "pending",
            tableFields: [
                { key: 'checkbox', label: 'Select' },
                { key: 'quantity', label: 'Quantity' },
                { key: 'amount', label: 'Amount' }
            ],

        }
    },
    methods: {
        async getGST() {
            try {
                const res = await AgentServices.getGST();
                if (res.data.status) {
                    this.gst_number = res.data.data;
                }
            } catch (error) {
                console.error("Error in GetSingleProduct", error);
            }
        },
        async saveGST() {
            try {
                const res = await AgentServices.saveGST({gst_number : this.gst_number});
                if (res.data.status) {
                    this.getGST
                }
            } catch (error) {
                console.error("Error in GetSingleProduct", error);
            }
        },
        async getSingleProduct() {
            try {
                const res = await AgentServices.getSingleProduct(this.sp_id);
                if (res.data.status) {
                    this.product = res.data.data;
                }
            } catch (error) {
                console.error("Error in GetSingleProduct", error);
            }
        },

        async getAddresses() {
            try {
                const res = await CommonServices.getUserAddress();
                if (res.data.status) {
                    this.addresses = res.data.data;
                }
            } catch (error) {
                console.error("Error in GetAddresses", error);
            }
        },

        async getCartItems() {


            const res = await CommonServices.getCartItems()

            if (res.data.status) {
                let cartItems = res.data.data.data
                const uniqueCartItems = Array.from(new Set(cartItems.map(item => item.sc_sp_id)));
                let proObj = {}

                for (let id of uniqueCartItems) {
                    proObj[id] = cartItems.filter((elem) => elem.sc_sp_id === id)
                }

                let finalCartItems = []
                for (let key in proObj) {
                    let currentProductCartItems = proObj[key]
                    let product = this.products.find(p => p.sp_id === parseInt(key));

                    let finalCartItem = {
                        ...product,
                        total_quantity: currentProductCartItems.reduce((acc, c) => acc + c.spqa_quantity, 0),
                        total_price: currentProductCartItems.reduce((acc, c) => acc + c.spqa_amount, 0),
                        cart: currentProductCartItems,
                    }

                    finalCartItems.push(finalCartItem)
                }


                this.cartTotal = finalCartItems.reduce((acc, item) => acc + item.total_price, 0)
                this.cartItems = finalCartItems

            }
        },


        formatAddress(address) {
            return `${address.ua_address_line_1}, ${address.ua_address_line_2}, ${address.ua_landmark}, ${address.ua_city}, ${address.ua_state}, ${address.ua_country}, ${address.ua_pincode}`;
        },

        changeTotal() {
            let current_qap = this.product.quantity_amount_map.find(
                qa => qa.spqa_id === this.order_details.spqa_id
            );
            this.order_details.so_quantity_amount = current_qap.spqa_amount;
            let total = current_qap.spqa_amount;
            for (let c of this.product.additional_charges) {
                total += c.spac_amount;
            }
            this.order_details.so_total_amount = total;
        },

        async placeOrder() {
            // let obj = {
            //     sp_id: this.sp_id,
            //     spqa_id: this.order_details.spqa_id,
            //     billing_ua_id: this.order_details.so_billing_address_id,
            //     shipping_ua_id: this.same_address
            //         ? this.order_details.so_billing_address_id
            //         : this.order_details.so_shipping_address_id,
            //     so_currency: "INR",
            //     so_quantity: this.product.quantity_amount_map.find(
            //         qa => qa.spqa_id === this.order_details.spqa_id
            //     )?.spqa_quantity,
            //     so_quantity_amount: this.product.quantity_amount_map.find(
            //         qa => qa.spqa_id === this.order_details.spqa_id
            //     )?.spqa_amount,
            //     so_additional_charges: this.product.additional_charges
            //         .map(ac => ac.spac_amount)
            //         .reduce((acc, i) => acc + i, 0) || 0,
            //     so_total_amount: this.order_details.so_total_amount
            // };

            // if (!obj.billing_ua_id || !obj.shipping_ua_id) {
            //     this.$toast({
            //         component: ToastificationContent,
            //         props: {
            //             title: "Please Provide Address To Place Order",
            //             icon: "XIcon",
            //             variant: "danger"
            //         }
            //     });
            //     return;
            // }

            // const res = await AgentServices.placeShopOrder(obj);

            // if (res.data.status) {
            //     this.$toast({
            //         component: ToastificationContent,
            //         props: {
            //             title: "Order Placed Successfully",
            //             icon: "CheckIcon",
            //             variant: "success"
            //         }
            //     });
            //     this.$router.replace({ name: "Shop Orders" });
            // } else {
            //     this.$toast({
            //         component: ToastificationContent,
            //         props: {
            //             title: "Failed To Place Order",
            //             icon: "XIcon",
            //             variant: "failure"
            //         }
            //     });
            // }
        },

        openAddressModal(address) {
            if (address) {
                this.address = { ...address }
            }
            this.addressModal = true;
        },

        async saveAddress() {
            const {
                ua_address_line_1,
                ua_address_line_2,
                ua_landmark,
                ua_country,
                ua_state,
                ua_city,
                ua_pincode
            } = this.address;

            if (
                !ua_address_line_1 ||
                !ua_country ||
                !ua_state ||
                !ua_city ||
                !ua_pincode
            ) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Please Complete All Required Fields",
                        icon: "XIcon",
                        variant: "danger"
                    }
                });
                return;
            }

            const response = await CommonServices.saveUserAddress(this.address);
            if (response.data.status) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Address Saved",
                        icon: "EditIcon",
                        variant: "success",
                    },
                });
                this.getAddresses()
            }

        },

        async deleteAddress(address) {
            const response = await CommonServices.saveUserAddress({ ...address, is_active: 'N' });
            if (response.data.status) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Address Deleted",
                        icon: "EditIcon",
                        variant: "success",
                    },
                });
                this.getAddresses()
            }
        },

        showPaymentModeModal() {
            let obj = {
                billing_ua_id: this.order_details.so_billing_address_id,
                shipping_ua_id: this.same_address
                    ? this.order_details.so_billing_address_id
                    : this.order_details.so_shipping_address_id,
            };

            if (!obj.billing_ua_id || !obj.shipping_ua_id) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Please Provide Address To Place Order",
                        icon: "XIcon",
                        variant: "danger"
                    }
                });
                return;
            }

            this.final_order = obj

            this.makePayUPayment()

            // if (obj.so_currency === "INR") {
            //     this.makePayUPayment()
            // }
            // else {
            //     this.makeStripePayment()
            // }

            // this.$refs['payment-mode-modal'].show()
        },

        async makePayUPayment() {
            const response = await AgentServices.makeShopPayUPayment(this.final_order);

            if (response.data.status) {
                this.payUObj = response.data.data;

                this.$nextTick(() => {
                    this.$refs["payu-form"].submit();
                });

                this.verifyPayUPayment();
            }

        },

        verifyPayUPayment() {
            this.showPaymentModal = true;
            const interval = setInterval(async () => {
                try {
                    let res;

                    res = await AgentServices.verifyShopPayUPayment(this.payUObj.txnid);

                    if (res.data.status) {
                        this.payment_status =
                            res.data.data.payment_status === "failure"
                                ? "failed"
                                : res.data.data.payment_status;
                        if (this.payment_status != "pending") {
                            clearInterval(interval);
                        }


                    }
                } catch (error) {
                    console.error({ error });
                    clearInterval(interval);
                }
            }, 4000);
        },

        async makeStripePayment() {
            try {
                const url = window.location.href;

                const response = await AgentServices.makeShopStripePayment({
                    ...this.final_order,
                    url,
                });

                window.location.href = response.data.data.session.url;

            } catch (error) {
                console.error("Error in buyServicePlanStripe ", error);
            }
        },

        async verifyStripePayment(transaction_id) {
            this.showPaymentModal = true;

            console.log({ here_transaction_id: transaction_id });

            try {
                let response = await AgentServices.verifyShopStripePayment(transaction_id);

                if (response?.data?.status) {
                    this.payment_status = "success";
                } else {
                    this.payment_status = "failed";
                }

                this.reloadPageWithoutParam()

            } catch (error) {
                console.error("Error in verifing transaction ", error);
            }
        },

        reloadPageWithoutParam() {
            if (this.payment_status === 'success') {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "New Order Has Been Placed",
                        icon: 'CheckIcon',
                        variant: 'success',
                    }
                });
                this.$router.replace({ name: "Shop Orders" });
            } else {
                window.location.replace(
                    `${window.location.origin}${window.location.pathname}`
                );
            }

        },

        async getAllProducts() {
            try {
                const res = await AgentServices.getAllProducts(this.filters);
                if (res.data.status) {
                    this.products = res.data.data;
                } else {
                    console.error('Could Not Get All Products');
                }
            } catch (error) {
                console.error(error, 'Could Not Get All Products');
            }
        },

        getSymbolFromCurrency,
    },



    async beforeMount() {
        const { sp_id } = this.$route.params;
        if (sp_id) {
            this.sp_id = sp_id;
        }

        const { transaction_id } = this.$route.query
        if (transaction_id) {
            this.verifyStripePayment(transaction_id)
        }

        await this.getAllProducts();
        this.getSingleProduct();
        this.getAddresses();
        this.getCartItems()
        this.getGST()
    }
}
</script>
<style>
.checked-div {
    background-color: #0000ff21 !important;
    border-color: #0000ff2e;
    padding: 10px;
    border-radius: 10px;
}

.address-card {
    border: 1px solid rgba(128, 128, 128, 0.614)
}

.address-box span {
    cursor: pointer;
}

div#carousel-fade {
    height: 235px;
}
</style>