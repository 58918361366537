<template>
  <div class="">
    <div class="col-12 desktop">
      <div
        class="course-provider"
        :style="{ height: showAllCourses ? '600px' : 'auto' }"
        @click.prevent="
          () =>
            $router.push({
              path: `/university-detail/${universityDetails.university_id}`,
            })
        "
      >
        <!-- @click="() =>
        $router.push({
          path: `/university-detail/${universityDetails.university_id}`,
        })
        " -->
        <div class="row mb-1 align-items-center w-100">
          <div class="col-2">
            <figure>
              <img :src="university_logo" alt="" class="w-100" />
            </figure>
          </div>

          <div class="col-10">
            <div
              class="
                mb-1
                d-flex
                align-items-center
                justify-content-between
                flex-wrap
              "
            >
              <span class="course-provider__name">{{
                universityDetails.university_name
              }}</span>
              <div class="d-flex align-items-center">
                <b-button
                  variant="outline-primary"
                  @click="navigateToBrochure(universityDetails.brochure)"
                  class="d-flex align-items-center"
                  :disabled="!universityDetails.brochure"
                >
                  Brochure
                  <feather-icon
                    icon="ArrowUpRightIcon"
                    size="18"
                    class="cursor-pointer"
                  />
                </b-button>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <span class="course-provider__point">
                <img src="@/assets/images/svg/location.svg" alt="" />
                {{ universityDetails.country_name }},
                {{ universityDetails.state_name }}
              </span>
              <span class="course-provider__point">
                <img src="@/assets/images/svg/location.svg" alt="" />
                {{ universityDetails.city_name }}
              </span>
            </div>
          </div>
        </div>

        <div
          v-if="
            universityDetails.courses && universityDetails.courses.length > 0
          "
          class="overflow-y-scroll"
        >
          <div
            v-for="i in showAllCourses
              ? universityDetails.courses
              : universityDetails.courses.slice(0, 1)"
            :key="'course_id_' + i.course_id"
          >
            <Course
              :course="i"
              @apply-course-for-student="$emit('apply-course-for-student', i)"
              @recommend-course="$emit('recommend-course', i)"
              @apply-course="$emit('apply-course', i)"
              @reload-list="$emit('reload-list')"
            />
          </div>
        </div>
        <div
          v-if="showToggleButton"
          class="text-center course-provider__bottom"
          :style="{ bottom: showAllCourses ? '1.3rem' : 'auto' }"
        >
          <b-button
            @click.stop.prevent="toggleCourses"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            size="sm"
          >
            {{
              showAllCourses
                ? "SHOW LESS COURSES"
                : `VIEW ${remainingCoursesCount} MORE COURSES`
            }}
          </b-button>
        </div>
      </div>
    </div>

    <div class="mobile w-100">
      <div
        class="course-provider"
        :style="{ height: showAllCourses ? '600px' : 'auto' }"
        @click.prevent="
          () =>
            $router.push({
              path: `/university-detail/${universityDetails.university_id}`,
            })
        "
      >
        <!-- @click="() =>
        $router.push({
          path: `/university-detail/${universityDetails.university_id}`,
        })
        " -->
        <div class="row logo-div">
          <div class="col-12">
            <div class="row">
              <div class="col-4">
                <figure>
                  <img :src="university_logo" alt="" class="w-100" />
                </figure>
              </div>
              <div class="col-8">
                <span class="course-provider__name">{{
                  universityDetails.university_name
                }}</span>
              </div>
            </div>
          </div>

          <div class="col-12 location-div">
            <!-- location  -->
            <div class="location">
              <span class="course-provider__point">
                <img src="@/assets/images/svg/location.svg" alt="" />
                {{ universityDetails.country_name }},
                {{ universityDetails.state_name }}
              </span>
              <span class="course-provider__point">
                <img src="@/assets/images/svg/location.svg" alt="" />
                {{ universityDetails.city_name }}
              </span>
            </div>

            <div
              class="
                mb-1
                d-flex
                align-items-center
                justify-content-between
                flex-wrap
              "
            >
              <div class="d-flex align-items-center">
                <b-button
                  variant="outline-primary"
                  @click="navigateToBrochure(universityDetails.brochure)"
                  class="d-flex align-items-center"
                  :disabled="!universityDetails.brochure"
                >
                  Brochure
                  <feather-icon
                    icon="ArrowUpRightIcon"
                    size="15"
                    class="cursor-pointer"
                  />
                </b-button>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="
            universityDetails.courses && universityDetails.courses.length > 0
          "
          class="overflow-y-scroll"
        >
          <div
            v-for="i in showAllCourses
              ? universityDetails.courses
              : universityDetails.courses.slice(0, 1)"
            :key="'course_id_' + i.course_id"
          >
            <Course
              :course="i"
              @apply-course-for-student="$emit('apply-course-for-student', i)"
              @recommend-course="$emit('recommend-course', i)"
              @apply-course="$emit('apply-course', i)"
              @reload-list="$emit('reload-list')"
            />
          </div>
        </div>
        <div
          v-if="showToggleButton"
          class="text-center course-provider__bottom"
          :style="{ bottom: showAllCourses ? '1.3rem' : 'auto' }"
        >
          <b-button
            @click.stop.prevent="toggleCourses"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            size="sm"
          >
            {{
              showAllCourses
                ? "SHOW LESS COURSES"
                : `VIEW ${remainingCoursesCount} MORE COURSES`
            }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FILESURL } from "@/config";
import Course from "./Course";
import { BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import CommonServices from "@/apiServices/CommonServices";
import { useWindowSize } from "@vueuse/core";

const { width } = useWindowSize();

export default {
  name: "CourseProvider",
  components: {
    Course,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      FILESURL,
      showAllCourses: false,
      universityDetails: this.item,
    };
  },
  computed: {
    university_logo() {
      return this.item.university_logo
        ? FILESURL + this.item.university_logo
        : "@/assets/images/svg/stanford.svg";
    },
    remainingCoursesCount() {
      return this.universityDetails.course_ids
        ? this.universityDetails.course_ids.split(",").length - 1
        : 0;
    },
    showToggleButton() {
      return (
        this.universityDetails.course_ids &&
        this.universityDetails.course_ids.split(",").length > 1
      );
    },
  },
  methods: {
    navigateToBrochure(brochure) {
      window.location.href = `${FILESURL}${brochure}`;
    },
    toggleCourses() {
      this.showAllCourses = !this.showAllCourses;
      if (this.showAllCourses) {
        this.getAllCoursesByUniversityId();
      }
    },
    async getAllCoursesByUniversityId() {
      try {
        const res = await CommonServices.getCoursesByUniversityId(
          this.universityDetails.university_id,
          this.filters
        );
        if (res.data.status) {
          console.log(res);
          this.universityDetails.courses = res.data.data;
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    },
  },
};
</script>

<style>
.course-provider {
  overflow-y: auto;
}

.course-provider__name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 60%;
}

.course-provider__name:hover {
  white-space: normal;
  text-overflow: clip;
}

* {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.mobile {
  display: none;
}
@media screen and (max-width: 600px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
  .logo-div {
    /* background-color: aquamarine; */
  }
  .course-provider__name {
    text-wrap: wrap;
    text-align: center;
    font-size: small;
  }

  /* .course-provider__name:hover {
    white-space: normal;
    text-overflow: clip;
  } */

  .location-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  .location {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 5px;
  }
}
</style>
