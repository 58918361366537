<template>
  <div class="d-flex justify-content-end mb-2">
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-toggle.sidebar-right
      variant="outline-primary"
      v-if="user_type === 'counselor' && type === 'aliff'"
    >
      Add Training
    </b-button>
    <b-sidebar id="sidebar-right" bg-variant="white" right backdrop shadow>
      <sidebar-content ref="sidebarContent" @refresh="$emit('refresh')"/>
    </b-sidebar>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import {
  BButton,
  BSidebar,
  VBToggle,
  BCardText,
  BForm,
  BFormInput,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import SidebarContent from "./SidebarContent.vue";

export default {
  components: {
    BCardCode,
    BButton,
    BSidebar,
    SidebarContent,
    BCardText,
    BFormInput,
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  props:{
    type: String
  },
  data() {
    return {};
  },
  computed: {
    user_type() {
      return this.$store.getters["user/getUserDetails"].user_type;
    },
  },
  methods: {
    openSidebar(trainingData) {
      this.$refs.sidebarContent.updateForm(trainingData);
      this.$root.$emit("bv::toggle::collapse", "sidebar-right");
    },
  },
};
</script>
