import axiosInstance from "./Api";

export default {
  async getRevenueByServices(params) {
    return await axiosInstance().get(`/accounts/revenue-by-service`, {
      params,
    });
  },

  async getAllTransactions(params) {
    return await axiosInstance().get(`/accounts/transactions`, { params });
  },

  // Master
  async getAllServices(params) {
    return await axiosInstance().get(`/accounts/all-services`, { params });
  },

  async getAllAgents(params) {
    return await axiosInstance().get(`/accounts/all-agents`, { params });
  },

  async getAssignedStudents(payload) {
    return await axiosInstance().get(`/accounts/all-students`, {
      params: payload,
    });
  },

  async getAllUniversities(payload) {
    return await axiosInstance().get(`/accounts/all-universities`, {
      params: { country_id: payload.country_id },
    });
  },

  async saveShopOrderStatus(payload) {
    return await axiosInstance().post("/accounts/shop-order-status", payload);
  },

  async saveCommission(payload) {
    return await axiosInstance().post("/accounts/save-commission", payload);
  },

  async getUniversityCommissions(params) {
    return await axiosInstance().get(
      `/accounts/get-university-commissions/${params}`
    );
  },

  async saveCommissionInStudent(payload) {
    return await axiosInstance().post(
      "/accounts/save-student-commission",
      payload
    );
  },

  async getStudentTransactions(params) {
    return axiosInstance().get(`/accounts/student-transactions`, { params });
  },

  async saveStudentTransaction(payload) {
    return axiosInstance().post(`/accounts/student-transaction`, payload);
  },
  async getAllNewCommissions(payload) {
    return axiosInstance().get(`/accounts/all-new-commissions`, payload);
  },
  async saveNewCommission(payload) {
    return axiosInstance().post(`/accounts/new-commission`, payload);
  },
  async getNewCommissionById(id) {
    return axiosInstance().get(`/accounts/new-commission/${id}`);
  },

  async getWorkingInvoice(payload) {
    const { student_user_id } = payload;
    return axiosInstance().get(`/accounts/working-invoice/${student_user_id}`,{params:payload});
  },

  async getActiveInvoice(payload) {
    const { student_user_id } = payload;
    return axiosInstance().get(`/accounts/active-invoice/${student_user_id}`);
  },

  

  async getInvoiceStatuses() {
    return axiosInstance().get(`/accounts/invoice-statuses/`);
  },

  async updateWorkingInvoice(payload) {
    const { inv_id } = payload;
    return axiosInstance().post(`/accounts/save-invoice/${inv_id}`, payload);
  },

  async getBankAccounts() {
    return axiosInstance().get(`/accounts/banks/all`);
  },

  async getCommissionRates(payload) {
    // let { university_id } = payload;
    return axiosInstance().get(`/accounts/commission-rates/`);
  },

  async getSingleCommissionRate(cr_id) {
    return axiosInstance().get(`/accounts/commission-rates/single/${cr_id}`);
  },

  async saveCommissionRates(payload) {
    return axiosInstance().post(`/accounts/commission-rates`, payload);
  },
  

  async getAllTransactionStudents(payload) {
    return axiosInstance().get(`/accounts/transaction-students`, {
      params: payload,
    });
  },
};
