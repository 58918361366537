<template>
    <section>

        <!-- <b-card>
            <div>
                <b-row cols="12" class="mb-1">
                    <b-col cols="9">
                        <b-form-input placeholder="Search" v-model="filters.searchText"
                            @input="onSearch"></b-form-input>
                    </b-col>
                    <b-col cols="3">
                        <v-select cols="4" v-model="filters.selectedStatus" :options="application_statuses"
                            label="status_name" :reduce="(item) => item.status_id" placeholder="Status" class="mr-1"
                            @input="onSearch"></v-select>
                    </b-col>
                </b-row>

                <b-row cols="12" class="mb-1">
                    <b-col cols="5">
                        <b-row cols="12">
                            <b-col cols="6">
                                <v-select v-model="filters.intakeDate.month" :options="all_months"
                                    placeholder="Intake Month" @input="onSearch" />
                            </b-col>
                           
                        </b-row>
                    </b-col>
                    <b-col cols="7">
                        <b-row cols="12">
                            <b-col>
                                <flat-pickr v-model="filters.selectedApplicationDate"
                                    placeholder="Application Date Range" class="form-control"
                                    :config="{ mode: 'range' }" @input="onSearch" />
                            </b-col>
                            <b-col>
                                <v-select v-model="filters.selectedCountry" :reduce="(country) => country.country_id"
                                    label="country_name" :options="this.$store.state.home.Countries"
                                    placeholder="Country" @input="onSearch"></v-select>
                            </b-col>
                            <b-col>
                             
                                <flat-pickr id="assigned_on" placeholder="Select Date Range"
                                    v-model="filters.follow_up_date" class="form-control" @input="onSearch" :config="{
                                        dateFormat: 'Y-m-d',
                                        altInput: true,
                                        altFormat: 'd-m-Y',
                                        allowInput: true,
                                        mode: 'range',
                                    }" />
                            </b-col>
                            <b-col v-if="['visa', 'operations'].includes(user_type)">
                                <v-select v-model="filters.counselor_id" :options="counselor_options.map((counselor) => ({
                                    value: counselor.user_id,
                                    label: `${counselor.user_firstname} ${counselor.user_lastname}`,
                                }))
                                    " placeholder="Counselor" :clearable="true" item-text="label"
                                    :reduce="(item) => item.value" class="per-page-selector d-inline-block w-100"
                                    @input="onSearch" />
                            </b-col>
                            <b-col>
                                <b-row cols="12" class="pr-1">
                                   
                                    <b-col cols="12 ">
                                        <button class="btn btn-primary w-100" @click="resetFilters()">
                                            Reset
                                        </button>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </div>
        </b-card> -->

        <b-card>
            <b-table id="" ref="" class="position-relative" :items="invoices" responsive :fields="tableColumns"
                primary-key="" show-empty empty-text="No matching records found">
                <!-- hide show -->
                <template #cell(showdetails)="row">
                    <b-form-checkbox v-model="row.detailsShowing" @change="(e) => {
                        row.toggleDetails(e);
                        getStudents(row.item);
                    }
                        " />
                </template>

                <template #cell(ba_name)="row">
                    <span>{{ row.item.ba_name }}</span>
                </template>

                <!-- <template #cell(total_commission)="row">
                    <span>{{ row.item.total_commission }} {{ row.item.currency }}</span>
                </template> -->

                <template #cell(total_commission)="row">

                    <span v-if="row.item.show_inr">
                        {{ row.item.total_inr_commission }} INR
                    </span>
                    <span v-else>
                        {{ row.item.total_commission }} {{ row.item.currency }}
                    </span>
                    <feather-icon icon="RefreshCwIcon" @click="toggleCurrency(row.item)" size="16" class="ml-2"
                        style="cursor: pointer;" />
                </template>

                <template #cell(action)="row">
                    <feather-icon icon="FileIcon" @click="navigateToInvoice(row.item.students)" size="24" />
                </template>

                <template #row-details="row">
                    <b-card class="border p-2">
                        <b-table id="" ref="" class="position-relative" :items="studentList" responsive
                            :fields="innerTableColumns" primary-key="" show-empty
                            empty-text="No matching records found">

                            <template #cell(user_name)="row">
                                <span>{{ row.item.user_firstname }} {{ row.item.user_lastname }}</span>
                            </template>

                            <template #cell(course_fee_pa)="row">
                                <span>{{ row.item.course_fee_pa }} {{ row.item.currency }}</span>
                            </template>

                            <template #cell(scholarship_amount)="row">
                                <span>{{ row.item.scholarship_amount }} {{ row.item.currency }}</span>
                            </template>

                            <template #cell(commission)="row">
                                <span v-if="row.item.show_inr">
                                    {{ row.item.inr_commission }} INR
                                </span>
                                <span v-else>
                                    {{ row.item.commission }} {{ row.item.currency }}
                                </span>
                                <feather-icon icon="RefreshCwIcon" @click="toggleCurrency(row.item)" size="16"
                                    class="ml-2" style="cursor: pointer;" />
                            </template>

                            <template #cell(actions)="data">
                                <div>
                                    <b-button style="
                background-color: transparent !important;
                border: none !important;
              " title="Invoice" @click="showSingleInvoice(data.item.user_id)"><feather-icon icon="FileIcon"
                                            size="18" /></b-button>
                                </div>

                            </template>
                        </b-table>
                    </b-card>
                </template>

            </b-table>

            <!-- <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <span class="text-muted"> {{ totalApplications }} entries </span>
            </b-col>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination aria-controls="StudentsListTableId" v-model="pagination.currentPage"
                :total-rows="totalApplications" :per-page="pagination.perPage" @change="onChange" first-number last-number
                class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div> -->


        </b-card>
    </section>
</template>

<script>
import {
    BTable,
    BButton,
    BCard,
    BRow,
    BCol,
    BPagination,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormGroup,
    BFormCheckbox,
    BFormSelectOption,
    BAvatar,
    BTab,
    BTabs,
    BBadge,
} from "bootstrap-vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import CommonServices from "@/apiServices/CommonServices";
import OperationsServices from "@/apiServices/OperationsServices";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import store from "@/store";
import { all_months, getAllYears } from "@/assets/json_data/data";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import currency_codes from "currency-codes";
import router from "@/router";
import { BASEURL } from "@/config";
import moment from "moment";

export default {
    components: {
        BCard,
        BButton,
        BTable,
        BRow,
        BCol,
        BPagination,
        BFormInput,
        BFormSelect,
        vSelect,
        flatPickr,
        BFormGroup,
        BFormCheckbox,
        BFormSelectOption,
        BFormTextarea,
        BAvatar,
        FeatherIcon,
        BTab,
        BTabs,
        BBadge,
        // AppTimeline,
        // AppTimelineItem,
    },
    data() {
        return {
            studentList: [],
            tableColumns: [
                { key: "showdetails", label: "show" },
                { key: "ba_name", label: "Bank Acc Name" },
                { key: "ba_number", label: "Bank Acc Number" },
                { key: "ba_routing_number", label: "Routing Number" },
                { key: "university_name", label: "University" },
                { key: "intake_month", label: "Intake" },
                { key: "total_commission", label: "Total Commission" },
                { key: "action", label: "Actions" },
            ],

            innerTableColumns: [
                { key: "user_id", label: "user id" },
                { key: "user_name", label: "Student Name" },
                { key: "course_name", label: "Course Name" },
                { key: "course_fee_pa", label: "Course Fees" },
                { key: "scholarship_amount", label: "Scholarship Amount" },
                { key: "commission", label: "Commission" },
                { key: "actions", label: "Actions" },
            ],

            totalApplications: 0,
            pagination: {
                perPage: 10,
                currentPage: 1,
            },
            expandedRows: [], // Store the expanded state of each row
            expandedModal: null, // Used for modal expansion

            config: {
                dateFormat: "Y-m-d",
                altInput: true,
                altFormat: "d-m-Y",
                allowInput: true,
            },

            filters: {
                searchText: "",
                selectedStatus: null,
                selectedApplicationDate: null,
                selectedCountry: null,
                counselor_id: null,
                follow_up_date: null,
                intakeDate: {
                    month: "",
                    year: "",
                },
            },

            statusFilterOptions: [],
            counselor_options: [],
            all_months,

            applicationTimeline: {},
            application_statuses: [],
            application_sub_statuses: [],
            currentApplicationStatus: null,
            currency_codes: currency_codes.data,
            popupData: {},
            debounce: null,

            university_payment_form: {
                fee_type: "",
                up_id: null,
                fw_university_id: "",
                currency: "",
                label: "",
                amount: "",
                student_user_id: null,
                application_id: null,
            },
            flyware_universities: [],
            flyware_currencies: [],
            invoices: [],
            show_inr: false,

            fee_type_options: [
                {
                    value: "deposit",
                    label: "Deposit"
                },
                {
                    value: "advance_fee",
                    label: "Advance Fee"
                },
                {
                    value: "application_fee",
                    label: "Application Fee"
                },
                {
                    value: "other",
                    label: "Other"
                },
            ],

            payment_status_map: {
                pending: {
                    variant: "primary",
                    label: "Pending",
                },
                ongoing: {
                    variant: "secondary",
                    label: "Ongoing",
                },
                success: {
                    variant: "success",
                    label: "Success",
                },
                failed: {
                    label: "Failed",
                    variant: "danger",
                },
                guaranteed: {
                    variant: "success",
                    label: "Processing"
                }

            },
        };
    },

    computed: {
        user_type() {
            let user_type = store.getters["user/getUserDetails"].user_type;

            if (["visa", "operations", "operations_manager"].includes(user_type)) {
                this.getAllFlywireUniversities();
                this.getAllFlywireCurrencies();
                this.getAllCounselors();
            }

            return user_type;
        },
    },
    methods: {
        moment,
        toggleCurrency(row) {
            row.show_inr = !row.show_inr;
        },
        formatCurrency(value) {
            if (!value) return '';
            return value.toString().replace(/\B(?=(\d{2})+(?!\d))/g, ",");
        },
        navigateToStudentDocument(id) {
            window.open(`/student_details/${id}?to=staffDocuments`, "__blank");
            // this.$router.push({
            //   path: `/student_details/${id}?to=staffDocuments`,
            // });
        },
        onDetailModalClose() {
            this.popupData = {};
        },

        resetUniversityPaymentForm() {
            this.university_payment_form = {
                fee_type: "",
                up_id: null,
                fw_university_id: "",
                label: "",
                amount: "",
                student_user_id: null,
                application_id: null,
            };
        },

        getCorrectFlatpickrTime(datetime) {
            return this.moment(datetime).subtract(5, 'hours').subtract(30, 'minutes').toDate()
        },

        async getAllCounselors() {
            try {
                const response = await OperationsServices.getAllCounselors();

                if (response.data.status) {
                    this.counselor_options = response.data.data;
                }
            } catch (error) {
                console.error("Error getting getAllCounselors ", error);
            }
        },

        openDetailModal(data) {
            // console.log("popupData => ",data);
            this.popupData = data;
            this.$refs.detailModal.show();
        },

        async getStudents(row) {
            this.studentList = row.students.map(student => ({
                ...student,
                show_inr: false,
            }));;
        },
        toggleDetails(index) {
            if (this.expandedRows.includes(index)) {
                this.expandedRows = this.expandedRows.filter((i) => i !== index);
            } else {
                this.expandedRows.push(index);
            }
        },

        onChange() {
            this.$nextTick(() => {
            });
        },
        onSearch() {
            if (this.debounce) {
                this.debounce = null;
            }
            this.debounce = setTimeout(() => {
                this.onChange();
            }, 1000);
        },
        async getApplicationStatuses() {
            try {
                const response = await CommonServices.getAllApplicationStatuses();

                if (response.data.status) {
                    this.application_statuses = response.data.data;
                    this.statusFilterOptions = response.data.data;
                }
            } catch (error) {
                console.log(error);
            }
        },

        showSingleInvoice(student_user_id) {
            this.$router.push({
                name: "University Invoice",
                params: { student_user_id: student_user_id },
            });
        },


        resetFilters() {
            this.filters = {
                searchText: "",
                selectedStatus: null,
                selectedApplicationDate: null,
                selectedCountry: null,
                intakeDate: {
                    month: "",
                    year: "",
                },
            };

            this.getApplications();
        },


        async allClubbedInvoices() {
            try {
                const res = await CommonServices.getAllClubbedInvoices()
                if (res.data.status) {
                    this.invoices = res.data.data.map(invoice => ({
                        ...invoice,
                        show_inr: false,
                    }));
                }
            } catch (error) {
                console.log(error)
            }
        },
        navigateToInvoice(students) {
            const student_ids = students.map((e) => e.user_id)
            console.log({ student_ids })
            localStorage.setItem('student_ids', JSON.stringify(student_ids))

            this.$router.push({
                name: "Clubbed Invoice",
            });
        }
    },

    beforeMount() {

        this.allClubbedInvoices();

        // this.getAllCounselors();
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.horizontal-timeline {
    display: flex;
    flex-direction: row;
    /* Horizontal layout */
    align-items: center;
    /* Center events vertically */

    .timeline-event {
        margin: 0 10px;
        /* Adjust spacing between events */
    }
}
</style>