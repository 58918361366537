<template>
  <b-form-group label="Upload Image">
    <b-form-file
      accept="image/*"
      @change="(e) => setImage(e, 'image1')"
    ></b-form-file>

    <small class="form-text text-muted">
      Upload an image of {{ width }}(W) x {{ height }}(H) pixels.
    </small>

    <div v-show="imgSrc['image1']" class="border p-2">
      <vue-cropper
        ref="cropper1"
        :guides="true"
        :view-mode="1"
        drag-mode="crop"
        :min-container-width="250"
        :min-container-height="180"
        :background="true"
        :rotatable="false"
        :zoomOnWheel="false"
        :minCropBoxWidth="width"
        :minCropBoxHeight="height"
        :cropBoxResizable="false"
        :src="imgSrc['image1']"
        alt="Source Image"
        :img-style="{ width: width + 'px', height: height + 'px' }"
        :aspect-ratio="width / height"
      ></vue-cropper>
    </div>

    <b-button
      variant="primary"
      @click="
        (e) => {
          cropImage(e, 'image1');
        }
      "
      v-if="imgSrc['image1']"
      class="mt-2 mr-2"
    >
      Crop
    </b-button>

    <b-button
      variant="success"
      v-if="img_blob"
      @click="onUploadFile(img_blob, { path: 'image_url_1' })"
      class="mt-2 mr-2"
    >
      Upload
    </b-button>
  </b-form-group>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import { BASEURL, FILESURL } from "@/config";
import { TokenService } from "@/apiServices/storageService";
import axios from "axios";
import { BButton, BFormFile, BFormGroup } from "bootstrap-vue";

export default {
  name: "ImageUploadWithCropper",
  components: { VueCropper, BFormGroup, BButton, BFormFile },
  props: [
    "width",
    "height",
    "value",
    "imagetype",
    "validatortype",
    "baseurl",
    "label",
  ],

  data: function () {
    return {
      FILESURL,
      imgSrc: { image1: null },
      showUpload: { upload1: false, upload2: false },
      cropImg: { image1: null },
      img_blob: null,
      w: null,
      h: null,
    };
  },

  methods: {
    setImageURL(url) {
      this.$emit("input", url);
    },
    async setImage(e) {
      const file = e.target.files[0];
      if (!file.type.includes("image/")) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imgSrc.image1 = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper1.replace(event.target.result);
          // console.log("this.imgSrc :>> ", event.target);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader Api not supported");
      }
    },
    cropImage(e) {
      this.cropImg.image1 = this.$refs.cropper1
        .getCroppedCanvas({
          width: this.$refs.cropper1.getContainerData().width,
          height: this.$refs.cropper1.getContainerData().height,
        })
        .toDataURL();
      console.log(
        "this.$refs.cropper1.getContainerData() :>> ",
        this.$refs.cropper1.getCroppedCanvas({
          width: this.$refs.cropper1.getContainerData().width,
          height: this.$refs.cropper1.getContainerData().height,
        })
      );
      this.w = this.$refs.cropper1.getContainerData().width;
      this.h = this.$refs.cropper1.getContainerData().height;
      // console.log('type :>> ', this.cropImg.image1)
      var block = this.cropImg.image1.split(";");
      // console.log('block :>> ', block)
      // Get the content type of the image
      var contentType = block[0].split(":")[1]; // In this case "image/gif"
      // get the real base64 content of the file
      var realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
      // Convert it to a blob to upload
      var blob1 = this.b64toBlob(realData, contentType);

      console.log("blob1 :>> ", blob1);
      //this.$nextTick(() => this.onUploadFile(blob1, { path: "image_url_2" }));

      this.img_blob = blob1;
      console.log("image1", this.img_blob);
    },
    async onUploadFile(event) {
      const file = event;
      console.log(typeof file);
      this.showCrop = false;
      if (file) {
        const formData = new FormData();
        // formData.append("image_type", this.imagetype);
        formData.append("fe_thumbnail", file);
        if (!this.imagetype) {
          alert("Send imagetype first");
          return;
        }
        try {
          const AUTH_TOKEN = TokenService.getToken();
          const response = await axios.post(
            `${BASEURL}/common/event-image`,
            formData,
            {
              headers: {
                "content-type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*",
                authorization: AUTH_TOKEN,
              },
            }
          );

          if (response.data.status) {
            console.log("response--->", response.data.data);
            this.setImageURL(response.data.data.file_path);
            this.$message.success("Success! File Successfully Uploaded", 3);
            this.showUpload.upload1 = true;
          } else {
            this.$message.error(
              response.data.message || "Document Upload Failed",
              3
            );
          }
        } catch (err) {
          console.log(err);
          this.$message.error("Sorry! Something Went Wrong", 3);
        }
      }
    },
    // async uploadImage() {
    //   try {
    //     const AUTH_TOKEN = TokenService.getToken();

    //     const formData = new FormData();
    //     formData.append("fe_thumbnail", this.fe_thumbnail);

    //     const response = await axios.post(
    //       `${BASEURL}/common/event-image`,
    //       formData,
    //       {
    //         headers: {
    //           "content-type": "multipart/form-data",
    //           "Access-Control-Allow-Origin": "*",
    //           authorization: AUTH_TOKEN,
    //         },
    //       }
    //     );

    //     if (response.data.status) {
    //       this.$toast({
    //         component: ToastificationContentVue,
    //         props: {
    //           title: "Document Uploaded Successfully",
    //           icon: "EditIcon",
    //           variant: "success",
    //         },
    //       });
    //       this.form_data.fe_thumbnail = response.data.data.file_path;
    //       console.log(this.form_data);
    //     } else {
    //       this.$toast({
    //         component: ToastificationContentVue,
    //         props: {
    //           title: response.data.message || "Document Upload Failed",
    //           icon: "EditIcon",
    //           variant: "failure",
    //         },
    //       });
    //     }
    //   } catch (err) {
    //     console.log("Error uploading file ", err);
    //     this.$toast({
    //       component: ToastificationContentVue,
    //       props: {
    //         title: "Server Error",
    //         icon: "EditIcon",
    //         variant: "failure",
    //       },
    //     });
    //   }
    // },
    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || "";
      sliceSize = sliceSize || 512;
      var byteCharacters = atob(b64Data);
      var byteArrays = [];
      for (
        var offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      var blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    rotate() {
      this.$refs.cropper2.rotate(90);
    },
    // handleFileRemove(event) {
    //   axios
    //     .post(`${BASEURL}/common/remove-image`, {
    //       image_type: this.imagetype,
    //       filename: this.value,
    //     })
    //     .then((response) => {
    //       this.setImageURL(null);
    //       this.showUpload.upload1 = false;
    //     })
    //     .catch((err) => {
    //       this.$message.error("Sorry! Somthing Went Wrong", 3);
    //     });
    // },
  },
};
</script>
